const scheduleRus = {
	schedule: "График работы",
	month: "Месяц",
	week: "Неделя",
	day: "День",
	today: "Сегодня",
	previous: "Назад",
	next: "Вперёд",
	agenda: "Расписание",
	date: "Дата",
	time: "Время",
	event: "Пациент",
	address:"Адрес",
};

export default scheduleRus;