import React from 'react';
import Container from "@material-ui/core/Container";
//import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Box from "@material-ui/core/Box";
import Copyright from "../Copyright";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.grey,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	},
	containerStyle: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center",
		minHeight: "80vh",
	},
	containerRootSmallScreen: {
		padding: "0 !important",
	},
	containerRoot: {
		padding: "0 10px",
	}
}));

export default function LoginCard (  props ) {

	const isSmallScreen =  window.matchMedia("(min-width: 390px)").matches

	const {
		children,
		cardTitle,
		cardLabel,
		IconLogin
	} = props;

	const classes = useStyles();
	return (
				<Container className={classes.containerStyle}>
				<div className={classes.paper}>
					<Container
							component="main"
							maxWidth="xs"
							classes={{root: isSmallScreen ? classes.containerRoot : classes.containerRootSmallScreen}}
					>
							<Card profile>
								<CardHeader color="rose">
									<h4 className={classes.cardTitleWhite} >
										<IconLogin />
										<br/>
										{cardTitle}
									</h4>
								</CardHeader>
								<CardBody profile>
									{cardLabel && <p style={{marginTop: 0}}>{cardLabel}</p>}
									{children}
								</CardBody>
							</Card>
					</Container>
				</div>
				<Box mt={1}>
					<Copyright/>
				</Box>
			</Container>
	);
};

