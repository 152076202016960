const timesheetEng = {
	errMessage: "The timesheet has not been sent. Please try again later.",
	errMessage1: "The form has not been sent. Please try again later.",
	successMessage: "Form has been sent",
	errSignature: "Signature is a must",
	btnSend: "Send",
	btnNext: "Next",
	btnBack: "Back",
	btnFinish: "Finish",
	tab1: "Confirm",
	tab2: "Edit",
	noTS: "Timesheet is not required.",
	noEdit: "No visits for edition is available",
	tsHeader1: "Timesheet confirmation",
	tsHeader2: "Complete following steps",
	editHeader1: "Visit Editor",
	editHeader2: "Please select one option",
	editSend: "All steps accomplished - the request is ready for sending.",
	signature: "Signature",
	_from: "From",
	to: "To",
	more: "MORE",
	less: "LESS",
	faqHeader1: "FAQ",
	faqHeader2: "Timesheets",
	faqQuestion1: "Why I have to provide timesheet?",
	faqAnswer1: "In case if at least one of the calls (IN or OUT) didn't go through the system, a visit has to be confirmed by the timesheet.",
	faqQuestion2: "When I have to send a timesheet and what is the latest date to do it?",
	faqAnswer2: "Please, send timesheet as soon as you have time. The latest date for sending the timesheet is Tuesday.",
	faqQuestion3: "The patient doesn't wish to sign it, what to do in this case?",
	faqAnswer3: "Please contact the coordinator to get advice.",
	preSend: "All steps completed - the timesheet is ready to send.",
	taskError: "The start time of the visit may not differ by more than 15 minutes from the schedule. If you worked on this schedule, please contact your coordinator or fill out 'Edit Form'",
	editSteps1: "Main reason",
	editSteps2: "Explanation",
	mainEditReasons1: "Service was not been provided",
	mainEditReasons2: "Signature cannot be obtained",
	mainEditReasons3: "Worked with different schedule",
	exp1: "Sick leave",
	exp2: "Patient refused service",
	exp3: "Patient was hospitalized",
	exp4: "Vacation",
	exp5: "Personal",
	exp6: "Patient refused to sign",
	exp7: "I do not work on this case anymore",
	exp9: "As per the patient request",
	exp10: "As per agency request",
	message: "Message",
	patient: "Patient",
	date: "Visit date",
	pocInfo: " tasks have been selected",
	tsSteps0: "Signature",
	tsSteps1: "Visit time",
	tsSteps2: "Plan Of Care",
	tsSteps3: "'s signature",
	tsSteps4: "Your signature",
	tsSteps5: "Confirmation",
	patSign1: "With your signature you confirm, that ",
	patSign2: " hours ",
	patSign3: " and ",
	patSign4: " minutes ",
	patSign5: " of service are received and ",
	patSign6: " tasks were done.",
	carSign1: " of service have been provided and ",
	durInfo1: "The duration of the visit is ",
	durInfo2: " hours and ",
	durInfo3: " minutes",
	durInfo4: " hours",
	pocError1: "The number of task selected must not less than 7, but only ", //num of tasks
	pocError2: " are selected",
	pocError3: "At least one task has to be done from each category, but only ",
	messEdit1: ". The time when visit was provided is ",
	messEdit2: ". Because of ",
	rejectedReason: "Rejected reason: ",
	p911header1: "Important Notice",
	p911header2: "Notice",
	p911content1: "Call 911 immediately if the patient has trouble breathing, chest pain, new confusion, inability to stay awake, bluish lips, or face!",
	p911content2: "Call Agency if the patient has any changes in health and/or behavioral status!",
	p911content3: "Call 911 in case of a patient's fall and follow Agency's Post-fall Protocol!",
	emergencyOption: "Timesheet is an emergency option to document duties performed and may be used only in extraordinary situations, such as telephone malfunction. It is against the law to use a timesheet if the Electronic Visit Verification system (phone clock-in/out) is working normally",
	//emergencyOptionPart1: "Timesheet is an emergency option",
	//emergencyOptionPart2: " to document duties performed and may be used only in extraordinary situations, such as telephone malfunction. It is against the law to use a timesheet if the Electronic Visit Verification system (phone clock-in/out) is working normally",
};

export default timesheetEng;
