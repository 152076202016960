import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "../styles";

const RejectedTimesheetRow = (props) => {
  const { visit, visitDate, handleRowClick, reasonText } = props;

  return (
    <Grid
      container
      direction={`row`}
      style={styles.rejectedRow}
      onClick={() => handleRowClick(visit)}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container direction={`column`}>
          {visitDate}
        </Grid>
        <Grid container direction={`column`}>
          {reasonText}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RejectedTimesheetRow;
