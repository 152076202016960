import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const MondaySvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 32.75 32.75`} {...props}>
				<path d="M29.375,1.25h-1.124c0.028-0.093,0.058-0.186,0.058-0.289C28.311,0.431,27.879,0,27.348,0s-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.059,0.289h-3.68c0.029-0.093,0.058-0.186,0.058-0.289C22.823,0.43,22.393,0,21.861,0
			C21.331,0,20.9,0.431,20.9,0.961c0,0.103,0.029,0.196,0.059,0.289h-3.68c0.029-0.093,0.058-0.186,0.058-0.289
			C17.336,0.431,16.906,0,16.375,0c-0.531,0-0.961,0.431-0.961,0.961c0,0.103,0.029,0.196,0.058,0.289h-3.68
			c0.029-0.093,0.058-0.186,0.058-0.289C11.85,0.431,11.419,0,10.888,0c-0.531,0-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.058,0.289h-3.68c0.03-0.093,0.058-0.186,0.058-0.289C6.363,0.43,5.933,0,5.402,0
			C4.871,0,4.441,0.431,4.441,0.961c0,0.103,0.029,0.196,0.058,0.289H3.375c-1.517,0-2.75,1.233-2.75,2.75v26
			c0,1.518,1.233,2.75,2.75,2.75H26.27l5.855-5.855V4C32.125,2.483,30.893,1.25,29.375,1.25z M30.625,26.273l-0.311,0.311h-2.356
			c-1.101,0-2,0.9-2,2v2.355l-0.31,0.311H3.375c-0.689,0-1.25-0.561-1.25-1.25V9h28.5V26.273z"/>
				<polygon points="5.563,21.127 5.508,19.896 5.398,18.729 5.42,18.729 6.861,23.812 8.665,23.812 10.106,18.729 10.128,18.729
			9.997,20.16 9.963,21.393 9.963,23.812 12.296,23.812 12.296,15.891 9.16,15.891 7.763,20.831 6.366,15.891 3.23,15.891
			3.23,23.812 5.563,23.812 		"/>
				<path d="M16.98,24.021c2.344,0,4.16-1.519,4.16-4.213c0-2.091-1.332-4.126-4.16-4.126c-2.463,0-4.146,1.618-4.146,4.104
			C12.833,22.48,14.495,24.021,16.98,24.021z M16.959,17.618c1.145-0.033,1.584,1.11,1.584,2.188c0,1.31-0.439,2.277-1.562,2.277
			c-1.243,0-1.55-1.266-1.55-2.277C15.43,18.807,15.782,17.618,16.959,17.618z"/>
				<polygon points="24.264,21.305 24.164,19.5 26.991,23.812 29.422,23.812 29.422,15.891 26.936,15.891 26.936,18.41 27.035,20.214
			24.208,15.891 21.775,15.891 21.775,23.812 24.264,23.812 		"/>
			</SvgIcon>
</div>

	);
};

export default MondaySvg;
