import {
  warningColor,
  whiteColor,
  dangerColor,
} from "../../assets/jss/material-dashboard-pro-react";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const isSmallScreen = window.matchMedia("(min-width: 720px)").matches;

const styles = {
  tsRow: {
    lineHeight: "36px",
    fontSize: "16px",
    margin: "10px 0",
  },
  rejectedRow: {
    paddingLeft: "10px",
    color: dangerColor[6],
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(244, 67, 54,.4);",
    backgroundColor: dangerColor[3],
    borderRadius: "3px",
    cursor: "pointer",
  },
  newRow: {
    paddingLeft: "10px",
    cursor: "pointer",
    boxShadow:
      "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
    backgroundColor: "#eeeeee",
    borderRadius: "3px",
  },
  oldTSRow: {
    paddingLeft: "10px",
    cursor: "pointer",
    boxShadow:
      "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
    backgroundColor: dangerColor[3],
    borderRadius: "3px",
  },
  table: {
    minWidth: 650,
  },
  tableSmall: {
    width: "100%",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  formGroup: {
    paddingLeft: "30px",
    margin: "10px 0",
  },
  formControl: {
    marginTop: "20px",
  },
  formControlLabel: {
    fontWeight: 500,
    fontSize: "24px",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0 5px 0",
  },
  infoBlock: {
    margin: "15px 0 15px 0",
  },
  svgIcon: {
    paddingRight: "10px",
  },
  svgIconSmall: {
    paddingRight: "20px",
  },
  sliderClass: {
    padding: "0 10px",
  },
  timeCell: {
    fontWeight: "bold",
    textAlign: "center",
  },
  errMessageFragment: {
    textAlign: "center",
    margin: "15px 0 0 0",
  },
  alertContainer: {
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4)",
    backgroundColor: warningColor[3],
    borderRadius: "3px",
  },
  alertMessage: {
    padding: "20px 15px",
    fontWeight: 300,
    display: "flex",
  },
  alertInnerText: {
    marginLeft: "15px",
  },
  sigCanvas: {
    width: isSmallScreen ? "250" : "500",
    height: isSmallScreen ? "150" : "300",
  },
  tableRow: {
    cursor: "pointer",
  },
  tCell: {
    padding: "0 5px 0 5px",
  },
  stepperRoot: {
    padding: "16px 5px 16px 5px ",
  },
  icon: {
    "&$activeIcon": {
      color: primaryColor[0],
    },
    "&$completedIcon": {
      color: primaryColor[0],
    },
  },
  activeIcon: {},
  completedIcon: {},
  messageRowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  messageRowTitle: {
    flex: 1,
    margin: "10px",
    fontWeight: "bold",
  },
  messageRowData: {
    flex: 2,
    margin: "10px",
    fontStyle: "italic",
  },
  confirmButton: {
    marginTop: "20px",
  },
  emergencyOption: {
    fontSize: "11px",
    textAlign: "justify",
  },
};

export default styles;
