import moment from "moment";
//import { LONG_DATE_FORMAT } from "../constants";

export function adjustScheduleTime(schedule) {
  for (let i = 0; i < schedule.length; i++) {
    //schedule[i].start = new Date(moment(String(schedule[i].start)).utc().format(LONG_DATE_FORMAT));
    //schedule[i].end = new Date(moment(String(schedule[i].end)).utc().format(LONG_DATE_FORMAT));
    schedule[i].start = moment(String(schedule[i].start)).utc().toDate();
    schedule[i].end = moment(String(schedule[i].end)).utc().toDate();
  }
  return schedule;
}

export function adjustTimesheets(timesheets) {
  /*for (const pid of Object.keys(timesheets.visits)) {
    for (const vid of Object.keys(timesheets.visits[pid])) {
    
      timesheets.visits[pid][vid].ss = moment(timesheets.visits[pid][vid].ss);
      timesheets.visits[pid][vid].se = moment(timesheets.visits[pid][vid].se);
      if (timesheets.visits[pid][vid].ci) {
        timesheets.visits[pid][vid].ci = moment(timesheets.visits[pid][vid].ci);
      }
      if (timesheets.visits[pid][vid].co) {
        timesheets.visits[pid][vid].co = moment(timesheets.visits[pid][vid].co);
      }
    }
    
  }*/
  Object.entries(timesheets.visits).forEach(([pid, _]) => {
    Object.entries(timesheets.visits[pid]).forEach(([vid, _]) => {
      timesheets.visits[pid][vid].ss = moment(timesheets.visits[pid][vid].ss);
      timesheets.visits[pid][vid].se = moment(timesheets.visits[pid][vid].se);
      if (timesheets.visits[pid][vid].ci) {
        timesheets.visits[pid][vid].ci = moment(timesheets.visits[pid][vid].ci);
      }
      if (timesheets.visits[pid][vid].co) {
        timesheets.visits[pid][vid].co = moment(timesheets.visits[pid][vid].co);
      }
    });
  });
  return timesheets;
}

export function adjustUserData(data) {
  let firstName, lastName, fullName, language, username;
  let isLanguageSelected = false;
  let policyAccepted = false;
  if (data.hasOwnProperty("accepted")) {
    const policy = data.accepted;
    if (policy.hasOwnProperty("accepted")) {
      policyAccepted = policy.accepted;
    }
  }
  if (data.hasOwnProperty("user")) {
    const user = data.user;
    if (user.hasOwnProperty("username")) {
      username = user.username;
    }
    if (user.hasOwnProperty("first_name")) {
      firstName = user.first_name;
    }
    if (user.hasOwnProperty("last_name")) {
      lastName = user.last_name;
    }
  }
  if (data.hasOwnProperty("lang")) {
    const lang = data.lang;
    if (lang.hasOwnProperty("lang")) {
      language = lang.lang;
    }
    if (lang.hasOwnProperty("selected")) {
      isLanguageSelected = lang.selected;
    }
  }
  if (firstName !== undefined && lastName !== undefined) {
    fullName = `${firstName} ${lastName}`;
  }

  return {
    firstName,
    lastName,
    fullName,
    language,
    username,
    isLanguageSelected,
    policyAccepted,
  };
}
