import React from "react";
import {addPinDigit, ADD_PIN_MODE_CHECK, resetPIN} from "../../modules/auth";
//import ReactCodeInput from "react-code-input/dist/ReactCodeInput";
import {connect} from 'react-redux';
import LoginLoading from '../LoginLoading'
import Fab from '@material-ui/core/Fab';
import Link from "@material-ui/core/Link";
import styles from './styles';
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "../../components/DialogMigrate/DialogMigrate";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "../../components/CustomButtons/Button";
import BackspaceIcon from '@material-ui/icons/Backspace';

const useStyles = makeStyles(styles);

let PinForm = (props) => {

	const classes = useStyles();

	const {
	//	pin,
		isLoading,
		updatePin,
    //    pin_mode,
		//setupPhoneDispatch
	} = props;

	const [pin, setPin] = React.useState("");

	const [openResetPinDialog, setOpenResetPinDialog] = React.useState(false);

	function addToPIN(c){
    
		if (pin.length<4){
		  setPin(pin+c);
		}
		//setcbuttonlabelbypin();
	}

	React.useEffect(() => {
		//setupPhoneDispatch();
		if (pin.length===4){
			updatePin(pin, ADD_PIN_MODE_CHECK);
			setPin("");
		}
		//setcbuttonlabelbypin();
	  }, [pin,updatePin]);

	function resetPinAndReload(){
		props.resetPinDispatch();
		setOpenResetPinDialog(false);
		setTimeout(() => window.location.reload(), 2000);
	}

	const ResetPinDialog = () => {
		return (
			<Dialog
			  disableBackdropClick
			  disableEscapeKeyDown
			  open={openResetPinDialog}
			  //onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title" style={{ margin: "0 auto", padding: 0 }}>
			  <div style={{ margin: 'auto', textAlign: 'center'}}>
				  <h4 style={{ margin: 'auto', padding: '15px'}}><strong>{"Reset PIN?"}</strong></h4>
			  </div>
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description">
				  {"Are you sure? Please use a phone and code login procedure after reset"}
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
			  <div style={{ margin: "auto" }}>
				  <Button onClick={() => resetPinAndReload()} color="success" type={`button`}>
					{"Reset"}
				  </Button>
				  <Button onClick={() => setOpenResetPinDialog(false)} color="success" type={`button`}>
					{"Cancel"}
				  </Button>
			  </div>
			  </DialogActions>
			</Dialog>

		)
	}


	return (
			isLoading ? (
					<LoginLoading />
			) : (
					<div>
						<ResetPinDialog/>
						<style>{
							`
							.react-code-input input {
							-webkit-padding-start: 0px !important;
							-webkit-padding-end: 0px !important;
							text-align: center;
							}`
						}
						</style>
						<div className={classes.allcenter}>
							<div><h5 style={{ margin: 'auto'}}><strong>&nbsp;{pin}&nbsp;</strong></h5></div>
							<div>
								<div>
								<Fab className={classes.fab} onClick={() => addToPIN('1')}>
									<p className={classes.allblack}>1</p>
								</Fab>
								<Fab className={classes.fab} onClick={() => addToPIN('2')}>
								<p className={classes.allblack}>2</p>
								</Fab>
								<Fab className={classes.fab} onClick={() => addToPIN('3')}>
									<p className={classes.allblack}>3</p>
								</Fab>
								</div>
								<div>
								<Fab className={classes.fab} onClick={() => addToPIN('4')}>
									<p className={classes.allblack}>4</p>
								</Fab>
								<Fab className={classes.fab} onClick={() => addToPIN('5')}>
									<p className={classes.allblack}>5</p>
								</Fab>
								<Fab className={classes.fab} onClick={() => addToPIN('6')}>
									<p className={classes.allblack}>6</p>
								</Fab>
								</div>
								<div>
								<Fab className={classes.fab} onClick={() => addToPIN('7')}>
									<p className={classes.allblack}>7</p>
								</Fab>
								<Fab className={classes.fab} onClick={() => addToPIN('8')}>
									<p className={classes.allblack}>8</p>
								</Fab>
								<Fab className={classes.fab} onClick={() => addToPIN('9')}>
									<p className={classes.allblack}>9</p>
								</Fab>
								</div>
								<div>
								<Fab className={classes.fab} onClick={() => setPin((pin) => pin.slice(0, pin.length - 1))}>
									<BackspaceIcon style={{ color: 'black' }}/>
								</Fab>
								<Fab className={classes.fab} onClick={() => addToPIN('0')}>
									<p className={classes.allblack}>0</p>
								</Fab>
								<Fab className={classes.fab} onClick={() => setPin("")}>
									<p className={classes.allblack}>C</p>
								</Fab>
								</div>
							</div>
							<Link color="inherit" href="#" onClick={() => setOpenResetPinDialog(true)}>
								{"Reset PIN"}
							</Link>
						</div>
					</div>
			)
	);
};

const mapStoreToProps = (state) => {
	return {
		pin: state.auth.pin,
		isLoading: state.auth.isLoading,
		//token:state.auth.token
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updatePin: (pin, pin_mode) => dispatch(addPinDigit(pin, pin_mode)),
		//setupPhoneDispatch:(phone) => dispatch(setupPhone(phone))
		resetPinDispatch:() => dispatch(resetPIN()),
	}
};

export default connect(mapStoreToProps, mapDispatchToProps)(PinForm)


