const pocRus = {
	title: "Plan of Care",
	p911header1: "Важное уведомление",
	p911header2: "Связь",
	p911content1: "Немедленно позвоните в службу 911, если у пациента имеются проблемы с дыханием, присутствует боль в груди, путается речь, пациент неспособен бодрствовать, имеются посинения губ или лица!",
	p911content2: "Звоните в агентство, если у пациента есть изменения в состоянии здоровья и / или поведении!",
	p911content3: "Звоните 911 в случае падения пациента и следуйте протоколу Агентства после падения!",
	p911Confirmed: "Подтвержаю"
}
export default pocRus;
