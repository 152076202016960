import React from 'react';
// custom components
import Button from "../CustomButtons/Button";
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import GridItem from "../Grid/GridItem";
// style
import styles from './styles'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const CampaignCard = ({campaign}) => {

	const classes = useStyles();

	return (
			<GridItem xs={12} sm={12} md={4}>
				<Card className={classes.cardHover}>
					<CardHeader className={classes.cardHeaderHover}>
						<a href="# " onClick={e => e.preventDefault()}>
							<img
									src={campaign['img_src']}
									alt="..."
									style={styles.cardImg}
							/>
						</a>
					</CardHeader>
					<CardBody>
						<h4 className={classes.cardProductTitle}>
							<a href="# " onClick={e => e.preventDefault()}>
								{campaign['card_title']}
							</a>
						</h4>
						<div className={classes.cardProductDescription}>
							<div dangerouslySetInnerHTML={{__html: campaign['card_body']}}/>
						</div>

					</CardBody>
					<CardFooter>
						{campaign['button_name']
								?
								<Button color={campaign['button_color']}
												fullWidth={true}
												simple={true}
												size={`lg`}
												link={true}
												onClick={() => window.location.href = campaign['button_link']}
								>
									{campaign['button_name']}
								</Button>
								:
								<></>}

					</CardFooter>
				</Card>
			</GridItem>
	);
};

export default CampaignCard;
