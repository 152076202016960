import { FEATURE_ENABLE_A_SUMMARY } from "../constants";
import * as Api from "../api";
//import {
//  ERROR_NOTIFICATION_RAISE,
//  SUCCESS_NOTIFICATION_RAISE,
//  NOTIFICATION_CLOSE,
//} from "./notification";
import { LOGOUT } from "./auth";

/**
 * Constants
 */

export const AVAIL_LOAD_SUCCESS = "avail/AVAIL_LOAD_SUCCESS";
export const AVAIL_LOAD_ERROR = "avail/AVAIL_LOAD_SUCCESS";
export const SET_AVAIL_SWITCH = "avail/SET_AVAIL_SWITCH";
export const NEXT_AVAIL_STEP = "avail/NEXT_AVAIL_STEP";
export const RESET_STEP = "avail/RESET_STEP";
export const SET_STEP_TAB = "avail/SET_STEP_TAB";
export const RESET_DAY_TIME_RANGE = "avail/RESET_DAY_TIME_RANGE";
export const SET_DURATION = "avail/SET_DURATION";
export const SET_RANGE_DURATION_HOURS = "avail/SET_RANGE_DURATION_HOURS";
export const SET_RANGE_DURATION = "avail/SET_RANGE_DURATION";
export const SET_MESSAGE = "avail/SET_MESSAGE";
export const ERROR_GENDER = "avail/ERROR_GENDER";
export const ERROR_GENDER_SOLVED = "avail/ERROR_GENDER_SOLVED";
export const ERROR_CASE_TYPE = "avail/ERROR_CASE_TYPE";
export const ERROR_CASE_TYPE_SOLVED = "avail/ERROR_CASE_TYPE_SOLVED";
export const ERROR_DISTRICT = "avail/ERROR_DISTRICT";
export const ERROR_DISTRICT_SOLVED = "avail/ERROR_DISTRICT_SOLVED";
export const ERROR_SCHEDULE_DAYS = "avail/ERROR_SCHEDULE_DAYS";
export const ERROR_SCHEDULE_DAYS_SOLVED = "avail/ERROR_SCHEDULE_DAYS_SOLVED";
export const ERROR_SOLVED = "avail/ERROR_SOLVED";
export const SEND_STATUS = "avail/SEND_STATUS";

/**
 * Action Creators
 */

//export function handleNextStep(props, username, sucMes, errMes) {
export function handleNextStep(props, sendToBackend) {
    let isValid = true;
  return async (dispatch, getState) => {
    let currentStep = getState().availability.step;
    switch (currentStep) {
      case 1:
        if (!props.female && !props.male) {
          dispatch({ type: ERROR_GENDER });
          isValid = false;
        }
        if (!props.perm && !props.temp) {
          dispatch({ type: ERROR_CASE_TYPE });
          isValid = false;
        }
        break;
      case 2:
        if (!props.bk && !props.mh && !props.bx && !props.qn && !props.si) {
          dispatch({ type: ERROR_DISTRICT });
          isValid = false;
        }
        break;
      case 3:
        if (
          !props.mon &&
          !props.tue &&
          !props.wed &&
          !props.thu &&
          !props.fri &&
          !props.sat &&
          !props.sun
        ) {
          dispatch({ type: ERROR_SCHEDULE_DAYS });
          isValid = false;
        }
        break;
      default:
        console.log('currentStep',currentStep)
    }

    if (isValid === true) {
      dispatch({ type: ERROR_SOLVED });
      const availabbleOn = getState().availability.avail;
      currentStep = !availabbleOn ? 3 : currentStep;
      const currState = getState();

      switch (currentStep) {
        case 1:
        case 2:
          dispatch({ type: NEXT_AVAIL_STEP });
          break;
        case 3:
          
          
          try {
            if (sendToBackend||!FEATURE_ENABLE_A_SUMMARY){
              const token = getState().auth.token;
              const data = {
                c_code: currState.user.username,
                avail: currState.availability.avail,
                perm: currState.availability.perm,
                temp: currState.availability.temp,
                bk: currState.availability.bk,
                mh: currState.availability.mh,
                bx: currState.availability.bx,
                qn: currState.availability.qn,
                si: currState.availability.si,
                min_dur: currState.availability.minDur,
                min_dur_range: currState.availability.minRangeDur,
                pets_allergy: currState.availability.pets_allergy,
                smoke_allergy: currState.availability.smoke_allergy,
                male: currState.availability.male,
                female: currState.availability.female,
                comment: currState.availability.comment,
                mon: currState.availability.mon,
                tue: currState.availability.tue,
                wed: currState.availability.wed,
                thu: currState.availability.thu,
                fri: currState.availability.fri,
                sat: currState.availability.sat,
                sun: currState.availability.sun,
                mon_any: currState.availability.mon_any,
                tue_any: currState.availability.tue_any,
                wed_any: currState.availability.wed_any,
                thu_any: currState.availability.thu_any,
                fri_any: currState.availability.fri_any,
                sat_any: currState.availability.sat_any,
                sun_any: currState.availability.sun_any,
                mon_h_s: currState.availability.mon_h_s,
                mon_h_e: currState.availability.mon_h_e,
                tue_h_s: currState.availability.tue_h_s,
                tue_h_e: currState.availability.tue_h_e,
                wed_h_s: currState.availability.wed_h_s,
                wed_h_e: currState.availability.wed_h_e,
                thu_h_s: currState.availability.thu_h_s,
                thu_h_e: currState.availability.thu_h_e,
                fri_h_s: currState.availability.fri_h_s,
                fri_h_e: currState.availability.fri_h_e,
                sat_h_s: currState.availability.sat_h_s,
                sat_h_e: currState.availability.sat_h_e,
                sun_h_s: currState.availability.sun_h_s,
                sun_h_e: currState.availability.sun_h_e,
              };
              const payload = await Api.sendAvailability(token, data);
              if (payload.ok) {
                /** Add complite step */
                dispatch({ type: SEND_STATUS, payload: true });
              } else {
                if (payload.status === 401) {
                  dispatch({ type: LOGOUT });
                }
                dispatch({ type: SEND_STATUS, payload: false });
              }
            }

            availabbleOn && dispatch({ type: NEXT_AVAIL_STEP });
          } catch (error) {
            console.log("error", error);
          }
          break;
        case 4:
          
          
          try {
            if (sendToBackend&&FEATURE_ENABLE_A_SUMMARY){
              const token = getState().auth.token;
              const data = {
                c_code: currState.user.username,
                avail: currState.availability.avail,
                perm: currState.availability.perm,
                temp: currState.availability.temp,
                bk: currState.availability.bk,
                mh: currState.availability.mh,
                bx: currState.availability.bx,
                qn: currState.availability.qn,
                si: currState.availability.si,
                min_dur: currState.availability.minDur,
                min_dur_range: currState.availability.minRangeDur,
                pets_allergy: currState.availability.pets_allergy,
                smoke_allergy: currState.availability.smoke_allergy,
                male: currState.availability.male,
                female: currState.availability.female,
                comment: currState.availability.comment,
                mon: currState.availability.mon,
                tue: currState.availability.tue,
                wed: currState.availability.wed,
                thu: currState.availability.thu,
                fri: currState.availability.fri,
                sat: currState.availability.sat,
                sun: currState.availability.sun,
                mon_any: currState.availability.mon_any,
                tue_any: currState.availability.tue_any,
                wed_any: currState.availability.wed_any,
                thu_any: currState.availability.thu_any,
                fri_any: currState.availability.fri_any,
                sat_any: currState.availability.sat_any,
                sun_any: currState.availability.sun_any,
                mon_h_s: currState.availability.mon_h_s,
                mon_h_e: currState.availability.mon_h_e,
                tue_h_s: currState.availability.tue_h_s,
                tue_h_e: currState.availability.tue_h_e,
                wed_h_s: currState.availability.wed_h_s,
                wed_h_e: currState.availability.wed_h_e,
                thu_h_s: currState.availability.thu_h_s,
                thu_h_e: currState.availability.thu_h_e,
                fri_h_s: currState.availability.fri_h_s,
                fri_h_e: currState.availability.fri_h_e,
                sat_h_s: currState.availability.sat_h_s,
                sat_h_e: currState.availability.sat_h_e,
                sun_h_s: currState.availability.sun_h_s,
                sun_h_e: currState.availability.sun_h_e,
              };
              const payload = await Api.sendAvailability(token, data);
              if (payload.ok) {
                /** Add complite step */
                dispatch({ type: SEND_STATUS, payload: true });
              } else {
                if (payload.status === 401) {
                  dispatch({ type: LOGOUT });
                }
                dispatch({ type: SEND_STATUS, payload: false });
              }
            }

            //availabbleOn && dispatch({ type: NEXT_AVAIL_STEP });
            dispatch({ type: RESET_STEP });
          } catch (error) {
            console.log("error", error);
          }
          if (!FEATURE_ENABLE_A_SUMMARY){
            dispatch({ type: RESET_STEP });
            dispatch({ type: SEND_STATUS, payload: false });

          }
          break;
        default:
          console.log('currentStep',currentStep)
      }
    }
  };
}

export function setStepTab(tab) {
  return async (dispatch) => {
    dispatch({ type: SET_STEP_TAB, tab });
  };
}

export function handleAvailSwitcher(name, props) {
  return async (dispatch, getState) => {
    if (["perm", "temp"].includes(name) && props.errCaseType === true) {
      dispatch({ type: ERROR_CASE_TYPE_SOLVED });
    }

    if (["female", "male"].includes(name) && props.errGender === true) {
      dispatch({ type: ERROR_GENDER_SOLVED });
    }

    if (
      ["bk", "mh", "bx", "qn", "si"].includes(name) &&
      props.errDistrict === true
    ) {
      dispatch({ type: ERROR_DISTRICT_SOLVED });
    }

    if (
      ["mon", "tue", "wed", "thu", "fri", "sat", "sun"].includes(name) &&
      props.errScheduleDays === true
    ) {
      dispatch({ type: ERROR_SCHEDULE_DAYS_SOLVED });
    }

    if (
      [
        "mon_any",
        "tue_any",
        "wed_any",
        "thu_any",
        "fri_any",
        "sat_any",
        "sun_any",
      ].includes(name) &&
      props[name] === false
    ) {
      const d = name.substr(0, 3);
      const ds = d + "_h_s";
      const de = d + "_h_e";
      dispatch({ type: RESET_DAY_TIME_RANGE, start: ds, end: de });
    }

    dispatch({ type: SET_AVAIL_SWITCH, name });
    //validateAvailability();
    let isValid = true;
    let allprops = getState().availability;
    console.log('perm in props','perm' in allprops)
        if (!allprops.female && !allprops.male) {
          dispatch({ type: ERROR_GENDER });
          isValid = false;
        }
        if (!allprops.perm && !allprops.temp) {
          dispatch({ type: ERROR_CASE_TYPE });
          isValid = false;
        }
        if (!allprops.bk && !allprops.mh && !allprops.bx && !allprops.qn && !allprops.si) {
          dispatch({ type: ERROR_DISTRICT });
          isValid = false;
        }
        if (
          !allprops.mon &&
          !allprops.tue &&
          !allprops.wed &&
          !allprops.thu &&
          !allprops.fri &&
          !allprops.sat &&
          !allprops.sun
        ) {
          dispatch({ type: ERROR_SCHEDULE_DAYS });
          isValid = false;
        }
    if (isValid === true) {
      dispatch({ type: ERROR_SOLVED });
    }
  };
}

export function setDuration(dur) {
  return async (dispatch) => {
    dispatch({ type: SET_DURATION, dur });
  };
}

export function setRangeDuration(dur, names) {
  const diffHours = dur[1] - dur[0];
  return async (dispatch) => {
    dispatch({
      type: SET_RANGE_DURATION,
      start: names.s,
      end: names.e,
      s: dur[0],
      e: dur[1],
    });
    dispatch({
      type: SET_RANGE_DURATION_HOURS,
      num_hrs: names.num_hrs,
      diffHours,
    });
  };
}

export function setComment(message) {
  return async (dispatch) => {
    dispatch({ type: SET_MESSAGE, payload: message });
  };
}

/**
 * Reducer
 */

const initialState = {
  isAvailabilityLoaded: false,
  is_cdpap: false,
  step: 1,
  avail: true,
  perm: true,
  temp: true,
  bk: true,
  mh: true,
  bx: true,
  qn: true,
  si: true,
  minDur: 2,
  minRangeDur: 24,
  pets_allergy: false,
  smoke_allergy: false,
  male: true,
  female: true,
  comment: "",
  mon: true,
  tue: true,
  wed: true,
  thu: true,
  fri: true,
  sat: true,
  sun: true,
  mon_any: true,
  tue_any: true,
  wed_any: true,
  thu_any: true,
  fri_any: true,
  sat_any: true,
  sun_any: true,
  mon_h_s: 0,
  mon_h_e: 24,
  tue_h_s: 0,
  tue_h_e: 24,
  wed_h_s: 0,
  wed_h_e: 24,
  thu_h_s: 0,
  thu_h_e: 24,
  fri_h_s: 0,
  fri_h_e: 24,
  sat_h_s: 0,
  sat_h_e: 24,
  sun_h_s: 0,
  sun_h_e: 24,
  mon_num_hrs: 24,
  tue_num_hrs: 24,
  wed_num_hrs: 24,
  thu_num_hrs: 24,
  fri_num_hrs: 24,
  sat_num_hrs: 24,
  sun_num_hrs: 24,
  errGender: false,
  errCaseType: false,
  errDistrict: false,
  errScheduleDays: false,
  formSendSuccess: false,
};

export default function availability(state = initialState, action) {
  switch (action.type) {
    case AVAIL_LOAD_SUCCESS:
      return {
        ...state,
        isAvailabilityLoaded: true,
        is_cdpap: action.available.is_cdpap,
        avail: action.available.avail,
        perm: action.available.perm,
        temp: action.available.temp,
        bk: action.available.bk,
        mh: action.available.mh,
        bx: action.available.bx,
        qn: action.available.qn,
        si: action.available.si,
        minDur: action.available.min_dur,
        minRangeDur: action.available.min_dur_range,
        pets_allergy: action.available.pets_allergy,
        smoke_allergy: action.available.smoke_allergy,
        male: action.available.male,
        female: action.available.female,
        comment: action.available.comment,
        mon: action.available.mon,
        tue: action.available.tue,
        wed: action.available.wed,
        thu: action.available.thu,
        fri: action.available.fri,
        sat: action.available.sat,
        sun: action.available.sun,
        mon_any: action.available.mon_any,
        tue_any: action.available.tue_any,
        wed_any: action.available.wed_any,
        thu_any: action.available.thu_any,
        fri_any: action.available.fri_any,
        sat_any: action.available.sat_any,
        sun_any: action.available.sun_any,
        mon_h_s: action.available.mon_h_s,
        mon_h_e: action.available.mon_h_e,
        tue_h_s: action.available.tue_h_s,
        tue_h_e: action.available.tue_h_e,
        wed_h_s: action.available.wed_h_s,
        wed_h_e: action.available.wed_h_e,
        thu_h_s: action.available.thu_h_s,
        thu_h_e: action.available.thu_h_e,
        fri_h_s: action.available.fri_h_s,
        fri_h_e: action.available.fri_h_e,
        sat_h_s: action.available.sat_h_s,
        sat_h_e: action.available.sat_h_e,
        sun_h_s: action.available.sun_h_s,
        sun_h_e: action.available.sun_h_e,
        mon_num_hrs: action.available.mon_h_e - action.available.mon_h_s,
        tue_num_hrs: action.available.tue_h_e - action.available.tue_h_s,
        wed_num_hrs: action.available.wed_h_e - action.available.wed_h_s,
        thu_num_hrs: action.available.thu_h_e - action.available.thu_h_s,
        fri_num_hrs: action.available.fri_h_e - action.available.fri_h_s,
        sat_num_hrs: action.available.sat_h_e - action.available.sat_h_s,
        sun_num_hrs: action.available.sun_h_e - action.available.sun_h_s,
      };
    case SET_AVAIL_SWITCH:
      return {
        ...state,
        [action.name]: !state[action.name],
      };
    case NEXT_AVAIL_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case SET_STEP_TAB:
      return {
        ...state,
        step: action.tab,
      };
    case SET_DURATION:
      return {
        ...state,
        minDur: action.dur,
      };
    case SET_MESSAGE:
      return {
        ...state,
        comment: action.payload,
      };
    case SET_RANGE_DURATION_HOURS:
      return {
        ...state,
        [action.num_hrs]: action.diffHours,
      };
    case RESET_DAY_TIME_RANGE:
      return {
        ...state,
        [action.start]: 0,
        [action.end]: 24,
      };
    case SET_RANGE_DURATION:
      return {
        ...state,
        [action.start]: action.s,
        [action.end]: action.e,
      };
    case ERROR_GENDER:
      return {
        ...state,
        errGender: true,
      };
    case ERROR_GENDER_SOLVED:
      return {
        ...state,
        errGender: false,
      };
    case ERROR_CASE_TYPE:
      return {
        ...state,
        errCaseType: true,
      };
    case ERROR_CASE_TYPE_SOLVED:
      return {
        ...state,
        errCaseType: false,
      };
    case ERROR_DISTRICT:
      return {
        ...state,
        errDistrict: true,
      };
    case ERROR_DISTRICT_SOLVED:
      return {
        ...state,
        errDistrict: false,
      };
    case ERROR_SCHEDULE_DAYS:
      return {
        ...state,
        errScheduleDays: true,
      };
    case ERROR_SCHEDULE_DAYS_SOLVED:
      return {
        ...state,
        errScheduleDays: false,
      };
    case ERROR_SOLVED:
      return {
        ...state,
        errGender: false,
        errCaseType: false,
        errDistrict: false,
        errScheduleDays: false,
      };
    case RESET_STEP:
      return {
        ...state,
        step: 1,
      };
    case SEND_STATUS:
      return {
        ...state,
        formSendSuccess: action.payload,
      };
    case LOGOUT:
    case AVAIL_LOAD_ERROR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export function getAvailabilityChunk() {
	return async (dispatch, getState) => {
	  try {
		const token = getState().auth.token;
		const payload = await Api.getAvailability(token);
		if (payload.ok) {
		  payload.json().then((data) => {
			dispatch({
				type: AVAIL_LOAD_SUCCESS,
				available: data.available,
			  });
		  });
		}
	  } catch (error) {
		console.log("error", error);
	  }
	};
  }