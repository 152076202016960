import React from 'react';
// UI
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import GridContainer from "../../../components/Grid/GridContainer";
// redux
import {connect} from 'react-redux'
import {handleEditSend} from "../../../modules/editVisit";
// styles
import styles from '../styles'

const ConfirmEdit = props => {

	const {
		t,
		onEditSend,
		visitDate,
		patientName,
		reason,
		explanation,
	} = props


	const MessageRow = ({title, data}) => {
		return (
				<div style={styles.messageRowContainer}>
					<div style={styles.messageRowTitle}>{title}</div>
					<div style={styles.messageRowData}>{data}</div>
				</div>
		);
	};

	return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<h5><b>{t.editSend}</b></h5>
					<MessageRow title={t.date} data={visitDate}/>
					<MessageRow title={t.patient} data={patientName}/>
					<MessageRow title={t.editSteps1} data={reason}/>
					<MessageRow title={t.editSteps2} data={explanation}/>
					<Button
							style={styles.confirmButton}
							onClick={() => onEditSend(t.successMessage, t.errMessage1)}
							color={`success`}>
						{t.btnSend}
					</Button>
				</GridItem>
			</GridContainer>
	);
}

const mapStateToProps = state => {
	return {
		visitDate: state.editVisit.visitDate,
		patientName: state.editVisit.patientName,
		reason: state.editVisit.reason,
		explanation: state.editVisit.explanation,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onEditSend: (sucMes, errMes) => dispatch(handleEditSend(sucMes, errMes)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEdit);
