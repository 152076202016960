const qtnRus = {
	q0: "1. Вы работаете в каком-либо другом агентстве?",
	q1: "2. Заполняете ли вы онлайн-сервис?",
	q2: "3. Какой сайт для этого вы используете?",
	q3: "Спасибо, мы получили ваши ответы.",
	title: "Опрос",//"Хотите выиграть $25 подарочную карту Starbucks?",
	subtitle: "Пожалуйста, ответьте на эти 3 вопроса, и ваше имя будет участвовать в этой лотерее. Мы сообщим всем, кто стал победителем, на наших страницах в FB и Instagram.",
	btnYes: "Да",
    btnNo: "Нет",
    btnOK: "OK",
	btnBegin: "Начать",
    website: "сайт",
	bottomCardText: "Выиграйте подарочную карту Starbucks на 25$",

}
export default qtnRus;
