import React from 'react';
import Danger from "../Typography/Danger";
import {makeStyles} from "@material-ui/core/styles";
import {Cancel} from "@material-ui/icons";
//import {dangerColor} from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles({
	ldContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "left",
		textAlign: "center",
		minHeight: "100vh",
		padding: "0 30px"
	},

})


const dangerStyles = makeStyles({
	ldInner: {
		display: "flex",
		justifyContent: "center",
	},
	iconBlock: {
		display: "flex",
  	alignItems: "center"
	},
	messageBlock: {
		marginLeft: "10px"
	},
	messageText: {
		margin: "0"
	},
})

const ErrMessage = (props) => {
	const classes = dangerStyles();
	return (
			<div className={classes.ldInner}>
				<div className={classes.iconBlock}>
					<Cancel fontSize={`large`}/>
				</div>
				<div className={classes.messageBlock}>
					<h3 className={classes.messageText}>{props.errorMessage}</h3>
				</div>
			</div>
	)
}

function LocationDenied(props) {

	const {errorMessage} = props
	const classes = useStyles();

	return (
			<div className={classes.ldContainer}>
					{/*<div className={classes.ldInner}>*/}
					{/*	<Cancel htmlColor={dangerColor[0]}/>*/}
					{/*</div>*/}
					{/*<div className={classes.ldInner}>*/}
					{/*	<Danger children={<h3>{errorMessage}</h3>}/>*/}
					{/*</div>*/}
					<Danger children={<ErrMessage errorMessage={errorMessage}/>}/>
			</div>
	);
}

export default LocationDenied;
