import React from 'react';
import Button from "@material-ui/core/Button";

const LoginLoading = () => {
	return (
			<Button color={`secondary`}
							disabled={true}
							fullWidth={true}
							type={`button`}
			>
				Loading...
			</Button>
	);
}

export default LoginLoading;
