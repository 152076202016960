import React from 'react';
// UI
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import GridContainer from "../../../components/Grid/GridContainer";
import Danger from "../../../components/Typography/Danger";
import Success from "../../../components/Typography/Success";
import GridItem from "../../../components/Grid/GridItem";
import StepperButtons from "../StepperButtons";
// redux
import {connect} from "react-redux";
import {handleTaskClick, MIN_CATEGORIES, MIN_TASKS} from "../../../modules/timesheet";
// styles
import styles from "../styles";
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
	...styles,
	...checkboxAdnRadioStyle
})
const PlanOfCare = ({t, steps, ...rest}) => {
	const classesStepped = useStyles()
	const {
		pocs,
		poc,
		pid,
		ts,
		stepInfo,
		taskClick,
	} = rest

	const pocScroll = React.createRef();

	const tasksNotEnough = ts.sizeTaskSelected < MIN_TASKS
	const taskErrorMessage = t.pocError1 + ts.sizeTaskSelected + t.pocError2
	const categoriesNotEnough = ts.sizeTaskCategorySelected < MIN_CATEGORIES
	const categoriesErrorMessage = t.pocError3 + ts.sizeTaskCategorySelected + t.pocError2
	const infoMessage = t.pocInfo + ': ' + ts.taskSelected.size.toString()

	const StateInfoBlock = () => {
		return (
				<div style={styles.infoBlock}>
					{tasksNotEnough && <Danger>{taskErrorMessage}</Danger>}
					{categoriesNotEnough && <Danger>{categoriesErrorMessage}</Danger>}
					{stepInfo && <Success>{infoMessage}</Success>}
				</div>
		)
	}

	function handleTaskClick(isRequiredTask, singleTask) {
		if (!isRequiredTask) {
			taskClick(singleTask)
		}
	}

	return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<StepperButtons steps={steps} t={t} {...rest} />
					<StateInfoBlock/>
					<Paper style={styles.root} ref={pocScroll}>
						<Table style={styles.table}>
							<TableBody>
								{Object.entries(poc).map((singleTask, key) => {

									const taskNumber = singleTask[0].replace('g', '')
									const isRequiredTask = pocs[pid][singleTask[0]][1]
									const taskName = singleTask[1][0]
									const category = singleTask[1][2]
									const isChecked = singleTask[1][1]
									return (
											<TableRow key={key}
																className={classesStepped.tableRow}
																onClick={() => handleTaskClick(isRequiredTask, singleTask)}
											>
												<TableCell classes={{root: classesStepped.tCell}}>
													<Checkbox
															value={isChecked}
															checked={isChecked}
															disabled={isRequiredTask}
															checkedIcon={<Check className={classesStepped.checkedIcon}/>}
															icon={<Check className={classesStepped.uncheckedIcon}/>}
															href={``}
															classes={{
																checked: classesStepped.checked,
																root: classesStepped.root,
															}}
													 />
												</TableCell>
												<TableCell classes={{root: classesStepped.tCell}}><b>{taskNumber}</b></TableCell>
												<TableCell classes={{root: classesStepped.tCell}}>{taskName}</TableCell>
												<TableCell classes={{root: classesStepped.tCell}}>{category}</TableCell>
											</TableRow>)
								})}
							</TableBody>
						</Table>
					</Paper>
					<StateInfoBlock/>
				</GridItem>
			</GridContainer>
	);
}
const mapStateToProps = state => {
	return {
		pocs: state.timesheet.pocs,
		pid: state.timesheet.ts.pid,
		ts: state.timesheet.ts,
		poc: state.timesheet.poc,
		stepInfo: state.timesheet.ts.stepInfo,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		taskClick: (singleTask) => dispatch(handleTaskClick(singleTask)),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PlanOfCare);
