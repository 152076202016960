import * as Api from "../api";
import moment from "moment";
import {
  ERROR_NOTIFICATION_RAISE,
  NOTIFICATION_CLOSE,
  SUCCESS_NOTIFICATION_RAISE,
} from "./notification";
import { DATE_STRING_FORMAT } from "../constants";
import { UPDATE_VISIT_AFTER_EDIT } from "./timesheet";
import { LOGOUT } from "./auth";

/**
 * Constants
 */

export const CHANGE_EDIT_STEP = "edit/CHANGE_EDIT_STEP";
export const DISCARD_EDIT = "edit/DISCARD_EDIT";
export const EDIT_SELECTED = "edit/EDIT_SELECTED";
export const EDIT_DISABLE_NEXT = "edit/EDIT_DISABLE_NEXT";
export const SET_EDIT_REASON = "edit/SET_EDIT_REASON";
export const SET_EXP = "edit/SET_EXP";

/**
 * Action Creators
 */

export function handleMainReasonChange(reason, reasonId) {
  return async (dispatch) => {
    dispatch({ type: SET_EDIT_REASON, payload: { reason, reasonId } });
  };
}

export function handleEditSend(sucMes, errMes) {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const explanation = getState().editVisit.explanation;
    const reason = getState().editVisit.reason;
    const pid = getState().editVisit.pid;
    const vid = getState().editVisit.vid;
    const scheduleStartInt = getState().editVisit.scheduleStartInt;
    const message = "Reason: " + reason + ". Explanation: " + explanation;

    const data = {
      visit_id: vid,
      message,
    };

    dispatch({ type: DISCARD_EDIT });
    dispatch({
      type: UPDATE_VISIT_AFTER_EDIT,
      payload: {
        pid,
        vid,
        ss_int: scheduleStartInt,
        is_ed: true,
      },
    });
    try {
      const payload = await Api.sendEdited(token, data);
      if (payload.ok) {
        dispatch({ type: SUCCESS_NOTIFICATION_RAISE, message: sucMes });
      } else {
        dispatch({ type: ERROR_NOTIFICATION_RAISE, message: errMes });

        if (payload.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: UPDATE_VISIT_AFTER_EDIT,
            payload: {
              pid: pid,
              vid: vid,
              ss_int: scheduleStartInt,
              is_ed: false,
            },
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    setTimeout(() => {
      dispatch({ type: NOTIFICATION_CLOSE });
    }, 3000);
  };
}

export function selectEdit(visit) {
  return async (dispatch, getState) => {
    const visitStart = visit.ci ? visit.ci : visit.ss;
    const visitEnd = visit.co ? visit.co : visit.se;
    const visitDate = moment(visit.ss_int).format(DATE_STRING_FORMAT);
    const patientName = getState().timesheet.pn[visit.pid];
    dispatch({
      type: EDIT_SELECTED,
      payload: {
        pid: visit.pid,
        vid: visit.vid,
        scheduleStartInt: visit.ss_int,
        visitStart,
        visitEnd,
        visitDate,
        patientName,
      },
    });
  };
}

export function handleExplanationChange(explanation) {
  return async (dispatch) => {
    dispatch({ type: SET_EXP, payload: { explanation } });
  };
}

export function handleEditStep(isNext, activeStep) {
  let newStep = isNext ? activeStep + 1 : activeStep - 1;
  return async (dispatch) => {
    dispatch({ type: CHANGE_EDIT_STEP, newStep });
    switch (newStep) {
      case -1:
        dispatch({ type: DISCARD_EDIT });
        break;
      case 0:
      case 1:
        dispatch({ type: EDIT_DISABLE_NEXT });
        break;
      default:
        console.log('newStep',newStep)
    }
  };
}

export function handleEditBack() {
  return async (dispatch) => {
    dispatch({ type: DISCARD_EDIT });
  };
}

/**
 * Reducer
 * **/

const initState = {
  pid: null,
  vid: null,
  scheduleStartInt: null,
  visitStart: null,
  visitEnd: null,
  visitDate: null,
  patientName: null,
  reason: "",
  reasonId: null,
  explanation: "",

  activeStep: 0,
  isNextDisabled: true,
};

export default function editVisit(state = initState, action) {
  switch (action.type) {
    case DISCARD_EDIT:
      return {
        ...initState,
      };
    case EDIT_SELECTED: {
      return {
        ...state,
        pid: action.payload.pid,
        vid: action.payload.vid,
        scheduleStartInt: action.payload.scheduleStartInt,
        visitDate: action.payload.visitDate,
        patientName: action.payload.patientName,
        visitStart: action.payload.visitStart,
        visitEnd: action.payload.visitEnd,
      };
    }
    case SET_EDIT_REASON:
      return {
        ...state,
        reason: action.payload.reason,
        reasonId: action.payload.reasonId,
        isNextDisabled: false,
      };
    case SET_EXP:
      return {
        ...state,
        explanation: action.payload.explanation,
        isNextDisabled: false,
      };
    case EDIT_DISABLE_NEXT:
      return {
        ...state,
        isNextDisabled: true,
      };
    case CHANGE_EDIT_STEP:
      return {
        ...state,
        activeStep: action.newStep,
      };
    case LOGOUT:
      return {
        ...initState,
      };
    default:
      return state;
  }
}
