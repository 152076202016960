import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const QueensSvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 511.999 511.999`} {...props}>
				<path d="M270.907,100.508V60.055h14.996c8.289,0,14.996-6.707,14.996-14.996s-6.707-14.996-14.996-14.996h-14.996V15.068
			c0-8.289-6.707-14.996-14.996-14.996c-8.289,0-14.996,6.707-14.996,14.996v14.996H225.92c-8.289,0-14.996,6.707-14.996,14.996
			s6.707,14.996,14.996,14.996h14.996v40.453l-33.278,50.079c16.663,0.663,33.051,4.159,48.229,11.144
			c15.232-7.04,31.635-10.586,48.267-11.218L270.907,100.508z"/>
			<path d="M511.238,298.692c0-29.992-30.994-78.36-79.961-86.951c-5.056-0.894-10.092-1.36-15.084-1.503
			c11.2,16.353,18.12,38.783,19.009,54.149c3.383,30.299-7.542,66.361-29.406,93.438l-3.332,4.145h-38.478l18.467-22.976
			c17.412-21.571,25.686-49.857,22.815-72.292c0-29.992-28.458-77.218-87.997-86.328c-21.976-1.953-43.197,3.734-61.372,15.516
			c-18.212-11.767-39.587-17.471-62.166-15.457c-30.457,4.867-54.622,16.896-72.24,44.621c-8.365,13.152-13.519,28.435-14.82,39.818
			c-5.028,40.833,17.05,67.098,41.163,97.099h-38.472c-23.889-29.713-36.948-64.274-32.463-100.76
			c1.763-15.089,8.135-34.097,18.637-50.982c-4.967,0.15-9.962,0.621-14.961,1.5c-43.773,7.695-75.674,48.274-79.873,85.135
			c-5.269,42.789,19.9,68.54,47.665,95.762c-1.545,4.551-2.398,9.356-2.398,14.333v14.996h59.983
			c8.289,0,14.996,6.707,14.996,14.996c0,8.289-6.707,14.996-14.996,14.996H45.971v14.996c0,24.807,20.18,44.987,44.987,44.987
			h164.953h0h164.953c24.807,0,44.987-20.18,44.987-44.987v-14.996h-59.983c-8.289,0-14.996-6.707-14.996-14.996
			c0-8.289,6.707-14.996,14.996-14.996h59.983v-14.996c0-4.994-0.861-9.808-2.392-14.34
			C490.388,366.217,516.858,342.628,511.238,298.692z M165.936,451.944c-8.282,0-14.996-6.714-14.996-14.996
			c0-8.282,6.715-14.996,14.996-14.996c8.282,0,14.996,6.714,14.996,14.996C180.932,445.229,174.218,451.944,165.936,451.944z
			 M255.911,511.926c-25.642,0-44.987-32.232-44.987-74.979c0-33.869,14.995-74.979,44.987-74.979
			c30.003,0,44.987,41.113,44.987,74.979C300.898,479.695,281.552,511.926,255.911,511.926z M345.885,451.944
			c-8.282,0-14.996-6.714-14.996-14.996c0-8.282,6.715-14.996,14.996-14.996c8.282,0,14.996,6.714,14.996,14.996
			C360.881,445.229,354.167,451.944,345.885,451.944z"/>
			</SvgIcon>
</div>
	);
};

export default QueensSvg;
