import React from 'react';
import Quote from "../Typography/Quote";
import styles from './styles'

const FaqBlock = ({question, answer}) => {
	return (
			<div>
				<h4><b>{question}</b></h4>
				<div className={styles.typo}>
					<Quote
							text={answer}
					/>
				</div>
			</div>
	);
}

export default FaqBlock;
