import React from "react";
import { connect } from "react-redux";
// core components
//import GridContainer from "../../components/Grid/GridContainer";
//import GridItem from "../../components/Grid/GridItem";
// reducer functions
import { onSaveQTN } from "../../modules/qtn";
// language
//import PinFormDialog from "../../components/PinFormDialog/PinFormDialog";
//import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import { Divider, TextField, Typography } from "@material-ui/core";
//import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import qtnEng from "../../assets/languages/eng/qtnEng";
import qtnEsp from "../../assets/languages/esp/qtnEsp";
import qtnRus from "../../assets/languages/rus/qtnRus";
import sb25сo from "../../sb25сo.jpg";
import white from "../../white.png";

const QTN = (props) => {
  const { language, onSaveQTNText } = props;

  let t = [null, qtnEng, qtnEsp, qtnRus][language];

  const questions = [t.subtitle, t.q0, t.q1, t.q2, t.q3];

  const [currentQuestion, setCurrentQuestion] = React.useState(0);

  const [website, setWebsite] = React.useState("");
  const handleChangeWebsite = (event) => {
    setWebsite(event.target.value);
  };
  const saveQTNFeedback = () => {
    onSaveQTNText("step: " + currentQuestion + ", web: " + website);
  };

  //const classes = useStyles();
  /*
  function QTNText() {
    return (
      <Card profile>
        <CardHeader color="success">
          <Typography variant="h6">{t.title}</Typography>
        </CardHeader>
        <CardBody profile>
          {currentQuestion === 0 ? (
            <Typography variant="subtitle1">{t.subtitle}</Typography>
          ) : (
            ""
          )}
          <Typography variant="subtitle1">
            {questions[currentQuestion]}
          </Typography>
          {currentQuestion < 2 ? (
            <div style={{ margin: "auto" }}>
              <Button
                onClick={() => {
                  setCurrentQuestion(currentQuestion + 1);
                }}
                color="success"
                type={`button`}
              >
                {t.btnYes}
              </Button>
              <Button
                onClick={() => {
                  setCurrentQuestion(3);
                  saveQTNFeedback();
                }}
                color="white"
                type={`button`}
              >
                {t.btnNo}
              </Button>
            </div>
          ) : (
            ""
          )}
          {currentQuestion === 2 ? (
            <>
              <TextField
                id="outlined-multiline-static"
                label={t.website}
                multiline
                rows={4}
                value={website}
                onChange={handleChangeWebsite}
                variant="outlined"
              />
              <Divider />
              <Button
                onClick={() => {
                  setCurrentQuestion(currentQuestion + 1);
                  saveQTNFeedback();
                }}
                color="success"
                type={`button`}
              >
                {t.btnOK}
              </Button>
            </>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    );
  }*/
  function QTNTextBackGround() {
    return (
      <div
        style={
          currentQuestion !== 0
            ? {
                backgroundImage: `url(${sb25сo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center top",
                flex: "1 1 auto",
                backgroundSize: "contain",
                textAlign: "center",
                /* margin-top: auto; */
              }
            : {
                backgroundImage: `url(${white})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center top",
                flex: "1 1 auto",
                backgroundSize: "contain",
                textAlign: "center",
                /* margin-top: auto; */
              }
        }
      >
        <CardHeader color="success">
          <Typography variant="h6">{t.title}</Typography>
        </CardHeader>
        {/**currentQuestion === 0 ? (
            <Typography variant="subtitle1">{t.subtitle}</Typography>
          ) : (
            ""
          )**/}
        {/**<Typography variant="subtitle1">
            {'\u00A0'}
        </Typography>**/}
        <Typography
          variant="body1"
          style={
            /**currentQuestion === 0
              ? { textAlign: "justify", padding: "20px" }
              : **/
              { padding: "20px" }
          }
        >
          {questions[currentQuestion]}
        </Typography>
        {/**<Typography variant="subtitle1">
            {'\u00A0'}
        </Typography>**/}
        {currentQuestion === 0 ? (
          <div style={{ margin: "auto" }}>
            <Button
              onClick={() => {
                setCurrentQuestion(currentQuestion + 1);
              }}
              color="success"
              type={`button`}
            >
              {t.btnBegin}
            </Button>
          </div>
        ) : (
          ""
        )}
        {currentQuestion < 3 && currentQuestion > 0 ? (
          <div style={{ margin: "auto" }}>
            <Button
              onClick={() => {
                setCurrentQuestion(currentQuestion + 1);
              }}
              color="success"
              type={`button`}
            >
              {t.btnYes}
            </Button>
            <Button
              onClick={() => {
                setCurrentQuestion(Array(questions).length - 1);
                saveQTNFeedback();
              }}
              color="white"
              type={`button`}
            >
              {t.btnNo}
            </Button>
          </div>
        ) : (
          ""
        )}
        {currentQuestion === 3 ? (
          <>
            <TextField
              id="outlined-multiline-static"
              label={t.website}
              multiline
              rows={4}
              value={website}
              onChange={handleChangeWebsite}
              variant="outlined"
            />
            <Divider />
            <Button
              onClick={() => {
                setCurrentQuestion(currentQuestion + 1);
                saveQTNFeedback();
              }}
              color="success"
              type={`button`}
            >
              {t.btnOK}
            </Button>
          </>
        ) : (
          ""
        )}
          <div style={{ margin: "auto", paddingTop: "100px" }}>
            <Typography
          variant="body1"
          style={
            /**currentQuestion === 0
              ? { textAlign: "justify", padding: "20px" }
              : **/
              { padding: "20px" }
          }
        >
          {t.bottomCardText}
        </Typography>
          </div>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {QTNTextBackGround()}
      {/**<div>
      <PinFormDialog />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <div>{QTNText()}</div>
        </GridItem>
      </GridContainer>
    </div>*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { language } = state.user;
  return { language };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveQTNText: (props) => dispatch(onSaveQTN(props)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QTN);
