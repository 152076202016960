const qtnEsp = {
	q0: "1. ¿Trabaja para alguna otra agencia?",
	q1: "2. ¿Completa su In-Service en línea?",
	q2: "3. ¿Qué pagina web o plataforma usas?",
	q3: "Gracias, hemos recibido sus respuestas.",
	title: "Encuesta",//"¿Quieres ganar una Gift Card de Starbucks de $25?",
	//subtitle: "Responda las siguientes preguntas:",
	subtitle: "Responda estas 3 preguntas y su nombre entrará en esta lotería. Informaremos a todos quién es el ganador en nuestras páginas de FB e Instagram.",
	btnYes: "Si",
    btnNo: "NO",
    btnOK: "OK",
	btnBegin: "Comenzar",
    website: "pagina web",
	bottomCardText: "Gane una gift card de $25 de Starbucks",

}
export default qtnEsp;
