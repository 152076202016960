import { LOGOUT } from "./auth";
import { getPOC } from "../api";
/**
 * Constants
 */
export const POC_LOADED = "poc/POC_LOADED";

/**
 * Action Creators
 */

/**
 * Reducer
 */

const initState = {
  pocs: [],
  isPOCLoaded: false,
};

export default function poc(state = initState, action) {
  //console.log(' nevvon action');
  //console.log(action);
  switch (action.type) {
    case POC_LOADED: {
      //console.log('action.pocs')
      //console.log(action.pocs)
      return {
        ...state,
        pocs: action.pocs,
        isPOCLoaded: true,
      };
    }
    case LOGOUT: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export function onGetPOC() {
  return async (dispatch, getState) => {
    //const phone = getState().auth.phone

    //const data = {
    //	phone: phone,
    //}
    try {
      const token = getState().auth.token;
      //const payload = await getPOC(token, data);
      const payload = await getPOC(token);
      if (payload.ok) {
        payload.json().then((data) => {
          dispatch({ type: POC_LOADED, pocs: data.poc });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}
