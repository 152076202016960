import React from 'react';
import {connect} from "react-redux";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
// core components
import AvailFormControlLabel from "../../components/AvailFormControlLabel/AvailFormControlLabel";
import MondaySvg from "../../components/Svg/Weekdays/MondaySvg";
import TuesdaySvg from "../../components/Svg/Weekdays/TuesdaySvg";
import WednesdaySvg from "../../components/Svg/Weekdays/WednesdaySvg";
import ThursdaySvg from "../../components/Svg/Weekdays/ThursdaySvg";
import FridaySvg from "../../components/Svg/Weekdays/FridaySvg";
import SaturdaySvg from "../../components/Svg/Weekdays/SaturdaySvg";
import SundaySvg from "../../components/Svg/Weekdays/SundaySvg";
import TFHours from "../../components/Svg/twentyFourHours/TFHours";
import DestinationSlider from "../../components/Sliders/DestinationSlider";
import Danger from "../../components/Typography/Danger";
import Button from "../../components/CustomButtons/Button";
// moment
import moment from "moment";
import "moment/locale/es";
import "moment/locale/ru";
// language
import availEng  from "../../assets/languages/eng/availEng";
import availEsp from "../../assets/languages/esp/availEsp";
import availRus from "../../assets/languages/rus/availRus";
// style
import styles from "./styles"
// reducer functions
import {
	handleAvailSwitcher,
	handleNextStep,
	setDuration,
	setRangeDuration
} from "../../modules/availability";
import {getMomentLocale} from "../../utils/languageUtils";
import {getIconPrimaryDisabledColor} from "../../utils/styleUtils";
import { FEATURE_ENABLE_A_SUMMARY } from '../../constants';

const useStyles = makeStyles(styles)

const ScheduleOfAvail = (props) => {

	const {
		nextStep,
		setSwitch,
		setDur,
		setRangeDur,
		errScheduleDays,
		//username,
		language
	} = props

	let t = [null, availEng, availEsp, availRus][language];

	const momentLocale = getMomentLocale(language);
	moment.locale(momentLocale)

	const sch = [
		{
			isActiveDay: props.mon,
			dayName: "mon",
			isAny: props.mon_any,
			isAnyName: "mon_any",
			svgComp: <MondaySvg htmlColor={getIconPrimaryDisabledColor(props.mon)}/>,
			start: props.mon_h_s,
			end: props.mon_h_e,
		},
		{
			isActiveDay: props.tue,
			dayName: "tue",
			isAny: props.tue_any,
			isAnyName: "tue_any",
			svgComp: <TuesdaySvg htmlColor={getIconPrimaryDisabledColor(props.tue)}/>,
			start: props.tue_h_s,
			end: props.tue_h_e,
		},
		{
			isActiveDay: props.wed,
			dayName: "wed",
			isAny: props.wed_any,
			isAnyName: "wed_any",
			svgComp: <WednesdaySvg htmlColor={getIconPrimaryDisabledColor(props.wed)}/>,
			start: props.wed_h_s,
			end: props.wed_h_e,
		},
		{
			isActiveDay: props.thu,
			dayName: "thu",
			isAny: props.thu_any,
			isAnyName: "thu_any",
			svgComp: <ThursdaySvg htmlColor={getIconPrimaryDisabledColor(props.thu)}/>,
			start: props.thu_h_s,
			end: props.thu_h_e,
		},
		{
			isActiveDay: props.fri,
			dayName: "fri",
			isAny: props.fri_any,
			isAnyName: "fri_any",
			svgComp: <FridaySvg htmlColor={getIconPrimaryDisabledColor(props.fri)}/>,
			start: props.fri_h_s,
			end: props.fri_h_e,
		},
		{
			isActiveDay: props.sat,
			dayName: "sat",
			isAny: props.sat_any,
			isAnyName: "sat_any",
			svgComp: <SaturdaySvg htmlColor={getIconPrimaryDisabledColor(props.sat)}/>,
			start: props.sat_h_s,
			end: props.sat_h_e,
		},
		{
			isActiveDay: props.sun,
			dayName: "sun",
			isAny: props.sun_any,
			isAnyName: "sun_any",
			svgComp: <SundaySvg htmlColor={getIconPrimaryDisabledColor(props.sun)}/>,
			start: props.sun_h_s,
			end: props.sun_h_e,
		},
	]

	const handleRangeSlider = (newValues, dayName) => {
		if (newValues[0] === newValues[1]) {
			return false
		}
		const names = {
			s: dayName + '_h_s',
			e: dayName + '_h_e',
			num_hrs: dayName + '_num_hrs',
		}
		setRangeDur(newValues, names)
	}

	const handleDayDuration = (event, value) => {
		let minDaysDur = Math.min(
				props.mon_num_hrs,
				props.tue_num_hrs,
				props.wed_num_hrs,
				props.thu_num_hrs,
				props.fri_num_hrs,
				props.sat_num_hrs,
				props.sun_num_hrs
		)
		if (value <= minDaysDur) {
			setDur(value)
		}
	}

	function getTimeString(num) {
		if (num === 0 || num === 24) {
			return '12 AM'
		} else if (num < 12) {
			return num.toString() + ' AM'
		} else if (num === 12) {
			return '12 PM'
		} else {
			num -= 12
			return num.toString() + ' PM'
		}
	}

	function setTimeRangeString(start, end) {
		return getTimeString(start) + ' - ' + getTimeString(end)

	}

	function getMinDur() {
		return Math.min(
				props.minDur,
				props.mon_num_hrs,
				props.tue_num_hrs,
				props.wed_num_hrs,
				props.thu_num_hrs,
				props.fri_num_hrs,
				props.sat_num_hrs,
				props.sun_num_hrs
		)
	}

	const classes = useStyles();

	return (
			<>
				<FormControl component="fieldset" fullWidth={true} className={classes.formControlClass}>
				{!props.as_dialog &&
					<FormLabel component="legend" className={classes.formControlClass}>{t.question_7}</FormLabel>
					}
					
					{
						sch.map((day, index) => {
							return (
									<div key={index}>
										<FormGroup className={classes.formGroupClass}>
											<Grid spacing={1} container key={index}>
											{(!props.as_dialog||day.isActiveDay) &&
												<Grid item xs={12} sm={12} md={3}
															onClick={() => setSwitch(day.dayName, props)}
												>
													<AvailFormControlLabel
															control={day.svgComp}
															isChecked={day.isActiveDay}
															controlLabel={moment().weekday(language === 1 ? index + 1 : index).format('dddd')}
													/>
												</Grid>}
												{(!props.as_dialog||day.isAny) &&
												<Grid item xs={12} sm={12} md={2}
															hidden={!day.isActiveDay}
															onClick={() => setSwitch(day.isAnyName, props)}
												>
													<AvailFormControlLabel
															control={<TFHours htmlColor={getIconPrimaryDisabledColor(day.isAny)}/>}
															isChecked={day.isAny}
															controlLabel={t.answer_7_1}
													/>
												</Grid>}
												<Grid item xs={12} sm={12} md={3}
															hidden={!day.isActiveDay || day.isAny}
															className={classes.timeCell}
												>
													{setTimeRangeString(day.start, day.end)}
												</Grid>

												<Grid item xs={12} sm={12} md={4}
															hidden={!day.isActiveDay || day.isAny}
												>
													<div className={classes.sliderClass}>
														<DestinationSlider
																aria-label="maxDist"
																max={24}
																min={0}
																name={day.dayName}
																value={[day.start, day.end]}
																defaultValue={[day.start, day.end]}
																onChange={(e, v) => handleRangeSlider(v, day.dayName)}
														/>
													</div>
												</Grid>

											</Grid>
										</FormGroup>
										<Divider/>
									</div>
							)
						})
					}
					
					<FormLabel component="legend" className={classes.formControlClass}>{t.question_8_1}<b>{getMinDur()}</b>{t.question_8_2}</FormLabel>
					<div id='tour_wizard_step_8'>
					<FormGroup>
						<div className={classes.sliderClass}>
							<DestinationSlider
									valueLabelDisplay="auto"
									aria-label="maxDist"
									max={12}
									min={1}
									value={getMinDur()}
									defaultValue={props.minDur}
									onChange={(e, v) => handleDayDuration(e, v)}
							/>
						</div>
					</FormGroup>
					</div>
				</FormControl>
				<div className={classes.errMessageFragment}>
					{errScheduleDays && <Danger>{t.error_5}</Danger>}
				</div>
				{!props.as_dialog &&
				<div className={classes.btnGroup}   id='schedule_next_button_div'>
					<Button
							color="success"
							type={`button`}
							onClick={() => nextStep(props, false)}
							disabled={errScheduleDays}
					>
						{FEATURE_ENABLE_A_SUMMARY?t.btn_verify:t.btn_4}
					</Button>
				</div>
				}
			</>
	);
};

const mapStateToProps = (state) => {
	const {language,
		// username
		} = state.user;
	const {
		step, errScheduleDays, avail,
		mon, tue, wed, thu, fri, sat, sun, mon_any, tue_any, wed_any, thu_any, fri_any, sat_any, sun_any,
		mon_h_s, mon_h_e, tue_h_s, tue_h_e, wed_h_s, wed_h_e, thu_h_s, thu_h_e, fri_h_s, fri_h_e, sat_h_s, sat_h_e, sun_h_s, sun_h_e,
		minDur,  mon_num_hrs, tue_num_hrs, wed_num_hrs, thu_num_hrs, fri_num_hrs, sat_num_hrs, sun_num_hrs
	} = state.availability;
	return {
		avail,
		mon,
		tue,
		wed,
		thu,
		fri,
		sat,
		sun,
		mon_any,
		tue_any,
		wed_any,
		thu_any,
		fri_any,
		sat_any,
		sun_any,
		mon_h_s,
		mon_h_e,
		tue_h_s,
		tue_h_e,
		wed_h_s,
		wed_h_e,
		thu_h_s,
		thu_h_e,
		fri_h_s,
		fri_h_e,
		sat_h_s,
		sat_h_e,
		sun_h_s,
		sun_h_e,
		minDur,
		step,
		errScheduleDays,
		mon_num_hrs,
		tue_num_hrs,
		wed_num_hrs,
		thu_num_hrs,
		fri_num_hrs,
		sat_num_hrs,
		sun_num_hrs,
		language,
		//username
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSwitch: (name, props) => dispatch(handleAvailSwitcher(name, props)),
		setDur: (dur) => dispatch(setDuration(dur)),
		setRangeDur: (dur, names) => dispatch(setRangeDuration(dur, names)),
		nextStep: (props, sendToBackend) => dispatch(handleNextStep(props, sendToBackend))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleOfAvail);
