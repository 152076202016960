import * as Api from "../api";
import moment from "moment";
import {
  ERROR_NOTIFICATION_RAISE,
  SUCCESS_NOTIFICATION_RAISE,
  NOTIFICATION_CLOSE,
} from "./notification";
import { LOGOUT } from "./auth";
import { DISCARD_EDIT, EDIT_SELECTED } from "./editVisit";
import { adjustTimesheets } from "../utils/uploadUtils";

/**
 * Constants
 */
export const TIMESHEET_LOADED = "ts/TIMESHEET_LOADED";
export const TIMESHEET_ERROR = "ts/GET_TIMESHEET_ERROR";
export const TIMESHEET_SELECTED = "ts/TIMESHEET_SELECTED";
export const DISCARD_TIMESHEET = "ts/DISCARD_TIMESHEET";
export const CHANGE_STEP = "ts/CHANGE_STEP";
export const DISABLE_NEXT = "ts/DISABLE_NEXT";
export const ENABLE_NEXT = "ts/ENABLE_NEXT";
export const TASK_ERROR = "ts/TASK_ERROR";
export const UPDATE_TASK_LIST = "ts/UPDATE_TASK_LIST";
export const SET_PAT_SIGN = "ts/SET_PAT_SIGN";
export const SET_CAR_SIGN = "ts/SET_CAR_SIGN";
export const UPDATE_VISIT = "ts/UPDATE_VISIT";
export const UPDATE_VISIT_AFTER_EDIT = "ts/UPDATE_VISIT_AFTER_EDIT";
export const SET_VISIT_START = "ts/SET_VISIT_START";
export const SET_VISIT_END = "ts/SET_VISIT_END";
export const SET_VISIT_END_ERROR = "ts/SET_VISIT_END_ERROR";
export const SET_VISIT_START_ERROR = "ts/SET_VISIT_START_ERROR";
export const SET_REASON = "ts/SET_REASON";

export const MIN_TASKS = 7;
export const MIN_CATEGORIES = 5;

/**
 * Action Creators
 */

export function handleTimesheetStep(isNext, activeStep) {
  if (!Number.isInteger(activeStep)){return async (dispatch, getState) => {}}
  //console.log('handleTimesheetStep activeStep',activeStep)
  let newStep = isNext ? activeStep + 1 : activeStep - 1;
  // const
  return async (dispatch, getState) => {
    const sizeTaskSelected = getState().timesheet.ts.taskSelected.size;
    const sizeTaskCategorySelected = getState().timesheet.ts
      .taskCategorySelected.size;
    dispatch({ type: CHANGE_STEP, payload: { step: newStep } });
    switch (newStep) {
      case -1:
        dispatch({ type: DISCARD_TIMESHEET });
        break;
      case 0:
        dispatch({ type: ENABLE_NEXT });
        break;
      case 1:
        if (
          sizeTaskSelected < MIN_TASKS ||
          sizeTaskCategorySelected !== MIN_CATEGORIES
        ) {
          dispatch({ type: DISABLE_NEXT });
          dispatch({ type: TASK_ERROR });
        } else {
          dispatch({ type: ENABLE_NEXT });
        }
        break;
      case 2:
        dispatch({ type: DISABLE_NEXT });
        break;
      case 3:
        dispatch({ type: DISABLE_NEXT });
        break;
      default:
        console.log('handleTimesheetStep newStep',newStep)
    }
  };
}

export function selectTimesheet(visit) {
  const vs = !!visit.ci ? visit.ci : visit.ss;
  const ve = !!visit.co ? visit.co : visit.se;
  const h = Math.floor(moment.duration(moment(ve).diff(moment(vs))).asHours());
  const m =
    Math.floor(moment.duration(moment(ve).diff(moment(vs))).asMinutes()) -
    60 * h;

  return async (dispatch, getState) => {
    const visitStatus = visit.st_vis;
    let poc = getState().timesheet.pocs[visit.pid];
    let taskSelected = new Set();
    let taskCategorySelected = new Set();
    Object.entries(poc).forEach((singleTask) => {
      if (singleTask[1][1]) {
        taskSelected.add(singleTask[0]);
        taskCategorySelected.add(singleTask[1][2]);
      }
    });

    dispatch({
      type: TIMESHEET_SELECTED,
      payload: {
        ss_int: visit.ss_int,
        pid: visit.pid,
        vid: visit.vid,
        vs,
        ve,
        h,
        m,
        poc,
        taskSelected,
        taskCategorySelected,
        visitStatus,
      },
    });
  };
}

export function handleTaskClick(singleTask) {
  return async (dispatch, getState) => {
    const taskSelected = getState().timesheet.ts.taskSelected;
    const taskCategorySelected = getState().timesheet.ts.taskCategorySelected;
    const taskNumber = singleTask[0];
    const category = singleTask[1][2];

    if (taskSelected.has(taskNumber)) {
      taskSelected.delete(taskNumber);
      taskCategorySelected.delete(category);
    } else {
      taskSelected.add(taskNumber);
      taskCategorySelected.add(category);
    }

    dispatch({
      type: UPDATE_TASK_LIST,
      payload: { taskSelected, taskCategorySelected, taskNumber },
    });
    if (
      taskSelected.size >= MIN_TASKS &&
      taskCategorySelected.size === MIN_CATEGORIES
    ) {
      dispatch({ type: ENABLE_NEXT });
    } else {
      dispatch({ type: DISABLE_NEXT });
    }
  };
}

export function setVisitStart(newTime) {
  return async (dispatch, getState) => {
    const ts = getState().timesheet.ts;
    const visits = getState().timesheet.visits;
    const visitEnd = ts.ve;
    const pid = ts.pid;
    const scheduleStart = visits[pid][ts.ss_int].ss;

    const diff = Math.abs(newTime - scheduleStart);
    const h = Math.floor(moment.duration(visitEnd.diff(newTime)).asHours());
    const m =
      Math.floor(moment.duration(visitEnd.diff(newTime)).asMinutes()) - 60 * h;

    if (diff > 900000) {
      dispatch({ type: SET_VISIT_START_ERROR, payload: newTime });
      dispatch({ type: DISABLE_NEXT });
    } else {
      dispatch({ type: SET_VISIT_START, payload: { newTime, h, m } });
      dispatch({ type: ENABLE_NEXT });
    }
  };
}

export function setVisitEnd(newTime) {
  return async (dispatch, getState) => {
    const ts = getState().timesheet.ts;
    const visits = getState().timesheet.visits;
    const visitStart = ts.vs;
    const pid = ts.pid;
    const scheduleEnd = visits[pid][ts.ss_int].se;

    const diff = Math.abs(newTime - scheduleEnd);
    const h = Math.floor(moment.duration(newTime.diff(visitStart)).asHours());
    const m =
      Math.floor(moment.duration(newTime.diff(visitStart)).asMinutes()) -
      60 * h;

    if (diff > 900000) {
      dispatch({ type: SET_VISIT_END_ERROR, payload: newTime });
      dispatch({ type: DISABLE_NEXT });
    } else {
      dispatch({ type: SET_VISIT_END, payload: { newTime, h, m } });
      dispatch({ type: ENABLE_NEXT });
    }
  };
}

/*export function handlerSignatures(sign, activeStep) {
  return async (dispatch) => {
    switch (activeStep) {
      case 2:
        dispatch({ type: SET_PAT_SIGN, payload: sign });
        break;
      case 3:
        dispatch({ type: SET_CAR_SIGN, payload: sign });
        break;
      default:
        console.log('handlerSignatures activeStep',activeStep)  
    }
  };
}*/

export function handlerPatientSignatures(sign) {
  return async (dispatch) => {
        dispatch({ type: SET_PAT_SIGN, payload: sign });
  };
}

export function handlerCaregiverSignatures(sign) {
  return async (dispatch) => {
        dispatch({ type: SET_CAR_SIGN, payload: sign });
  };
}

export function onSendTimesheet(sucMes, errMes) {
  return async (dispatch, getState) => {
    const ts = getState().timesheet.ts;
    // const visits = getState().timesheet.visits

    let tasks = "";
    ts.taskSelected.forEach((t) => {
      tasks += t.toString() + "|";
    });

    const data = {
      visit_id: ts.vid,
      visit_start: ts.vs,
      visit_end: ts.ve,
      tasks: tasks,
      patient_signature: ts.patSign,
      caregiver_signature: ts.carSign,
      comment: "",
      status: "Pending",
      updated: ts.st_vis !== 3,
    };
    dispatch({ type: DISCARD_TIMESHEET });
    dispatch({
      type: UPDATE_VISIT,
      payload: {
        pid: ts.pid,
        ss_int: ts.ss_int,
        st_vis: 1,
        st_conf: true,
      },
    });
    try {
      const token = getState().auth.token;
      const payload = await Api.sendTimesheet(token, data);
      if (payload.ok) {
        dispatch({ type: SUCCESS_NOTIFICATION_RAISE, message: sucMes });
      } else {
        dispatch({ type: ERROR_NOTIFICATION_RAISE, message: errMes });
        if (payload.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: UPDATE_VISIT,
            payload: {
              pid: ts.pid,
              ss_int: ts.ss_int,
              st_vis: ts.st_vis,
              st_conf: false,
            },
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    setTimeout(() => {
      dispatch({ type: NOTIFICATION_CLOSE });
    }, 5000);
  };
}

export function tabChangeHandler() {
  return async (dispatch) => {
    dispatch({ type: DISCARD_EDIT });
    dispatch({ type: DISCARD_TIMESHEET });
  };
}

export function getTimesheetChunk() {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth.token;
      const payload = await Api.getTimesheet(token);
      if (payload.ok) {
        payload.json().then((data) => {
          const timesheets = adjustTimesheets(data.timesheets);
          dispatch({ type: TIMESHEET_LOADED, payload: timesheets });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}

/**
 * Reducer
 * **/

const initState = {
  isTimesheetLoaded: false,
  isTsSelected: false,
  isEdSelected: false,
  visits: {},
  pocs: [],
  pn: [],
  poc: null,
  ts: null,
  ed: {},
  activeStep: 0,
  isNextDisabled: false,
};

export default function timesheet(state = initState, action) {
  switch (action.type) {
    case DISABLE_NEXT:
      return {
        ...state,
        isNextDisabled: true,
      };
    case ENABLE_NEXT:
      return {
        ...state,
        isNextDisabled: false,
      };
    case CHANGE_STEP:
      return {
        ...state,
        activeStep: action.payload.step,
        ts: {
          ...state.ts,
          stepError: false,
        },
      };
    case TIMESHEET_LOADED: {
      return {
        ...state,
        visits: action.payload.visits,
        pocs: action.payload.pocs,
        pn: action.payload.pn,
        isTimesheetLoaded: true,
      };
    }
    case DISCARD_TIMESHEET:
      return {
        ...state,
        isTsSelected: false,
        poc: null,
        ts: null,
      };
    case DISCARD_EDIT:
      return {
        ...state,
        isEdSelected: false,
      };
    case TIMESHEET_SELECTED: {
      return {
        ...state,
        isTsSelected: true,
        activeStep: 0,
        poc: action.payload.poc,
        ts: {
          ...state.visits[action.payload.pid][action.payload.vid],
          ss_int: action.payload.ss_int,
          pid: action.payload.pid,
          vid: action.payload.vid,
          patSign: "",
          carSign: "",
          vs: action.payload.vs,
          ve: action.payload.ve,
          h: action.payload.h,
          m: action.payload.m,
          taskSelected: action.payload.taskSelected,
          sizeTaskSelected: action.payload.taskSelected.size,
          taskCategorySelected: action.payload.taskCategorySelected,
          sizeTaskCategorySelected: action.payload.taskCategorySelected.size,
          stepError: false,
          stepInfo: true,
        },
      };
    }
    case UPDATE_VISIT_AFTER_EDIT:
      return {
        ...state,
        visits: {
          ...state.visits,
          [action.payload.pid]: {
            ...state.visits[action.payload.pid],
            [action.payload.ss_int]: {
              ...state.visits[action.payload.pid][action.payload.ss_int],
              // st_vis: action.payload.st_vis,
              is_ed: action.payload.is_ed,
            },
          },
        },
      };
    case UPDATE_VISIT:
      return {
        ...state,
        visits: {
          ...state.visits,
          [action.payload.pid]: {
            ...state.visits[action.payload.pid],
            [action.payload.ss_int]: {
              ...state.visits[action.payload.pid][action.payload.ss_int],
              st_vis: action.payload.st_vis,
              st_conf: action.payload.st_conf,
            },
          },
        },
      };
    case UPDATE_TASK_LIST:
      return {
        ...state,
        poc: {
          ...state.poc,
          [action.payload.taskNumber]: [
            state.poc[action.payload.taskNumber][0],
            !state.poc[action.payload.taskNumber][1],
            state.poc[action.payload.taskNumber][2],
          ],
        },
        ts: {
          ...state.ts,
          taskSelected: action.payload.taskSelected,
          sizeTaskSelected: action.payload.taskSelected.size,
          taskCategorySelected: action.payload.taskCategorySelected,
          sizeTaskCategorySelected: action.payload.taskCategorySelected.size,
        },
      };
    case SET_PAT_SIGN:
      return {
        ...state,
        isNextDisabled: false,
        ts: {
          ...state.ts,
          patSign: action.payload,
        },
      };
    case SET_CAR_SIGN:
      return {
        ...state,
        isNextDisabled: false,
        ts: {
          ...state.ts,
          carSign: action.payload,
        },
      };
    case SET_VISIT_START: {
      return {
        ...state,
        isNextDisabled: false,
        ts: {
          ...state.ts,
          vs: action.payload.newTime,
          h: action.payload.h,
          m: action.payload.m,
          stepError: false,
          stepInfo: true,
        },
      };
    }
    case SET_VISIT_END_ERROR:
      return {
        ...state,
        ts: {
          ...state.ts,
          stepError: true,
          stepInfo: false,
          ve: action.payload,
        },
      };
    case SET_VISIT_START_ERROR:
      return {
        ...state,
        ts: {
          ...state.ts,
          stepError: true,
          stepInfo: false,
          vs: action.payload,
        },
      };
    case TASK_ERROR:
      return {
        ...state,
        ts: {
          ...state.ts,
          stepError: true,
        },
      };
    case SET_VISIT_END: {
      return {
        ...state,
        isNextDisabled: false,
        ts: {
          ...state.ts,
          ve: action.payload.newTime,
          h: action.payload.h,
          m: action.payload.m,
          stepError: false,
          stepInfo: true,
        },
      };
    }
    case EDIT_SELECTED: {
      return {
        ...state,
        isEdSelected: true,
      };
    }
    case TIMESHEET_ERROR:
    case LOGOUT: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}
