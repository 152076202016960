import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import useStyles from './styles'
import {makeStyles} from "@material-ui/core/styles";
import taskStyles from "../../assets/jss/material-dashboard-react/components/tasksStyle";
import Link from "@material-ui/core/Link";
// redux
import {connect} from 'react-redux';
import {acceptPolicy} from "../../modules/user";
import LoginLoading from "../LoginLoading";

const useTaskStyles = makeStyles(taskStyles);

const PolicyForm = (props) => {
	const {setPolicyAccepted, isLoading} = props

	const classes = useStyles();

	const taskClasses = useTaskStyles();

	return (
			<div className={classes.container}>
				{isLoading ? (
						<LoginLoading/>
				) : (
						<div className={classes.policyItemContainer}>
							<div className={classes.checkBoxContainer} onClick={setPolicyAccepted}>
								<Checkbox
										checkedIcon={<Check className={taskClasses.checkedIcon}/>}
										icon={<Check className={taskClasses.uncheckedIcon}/>}
										classes={{
											checked: taskClasses.checked,
											root: taskClasses.root,
										}}
								/>
							</div>
							<div className={classes.textContainer}>
								<div>
									<h6 className={classes.policyText}>By continuing usage of this application you're accepting our </h6>
									<h6 className={classes.policyText} style={{textDecoration: "underline"}}>
										<Link color="inherit" href="https://www.allheartcare.com/terms-and-conditions/">
											terms and conditions
										</Link>
									</h6>
								</div>
							</div>
						</div>
				)}
			</div>
	);
}

const mapStoreToProps = (state) => {
	return {
		isLoading: state.auth.isLoading
	}
};

const mapDispatchToProps = dispatch => {
	return {
		setPolicyAccepted: () => dispatch(acceptPolicy())
	}
}

export default connect(mapStoreToProps, mapDispatchToProps)(PolicyForm);
