import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const FridaySvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 32.75 32.75`} {...props}>
				<path d="M29.375,1.25h-1.123c0.029-0.093,0.059-0.186,0.059-0.289c0-0.53-0.432-0.961-0.963-0.961s-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.059,0.289h-3.68c0.029-0.093,0.059-0.186,0.059-0.289C22.823,0.431,22.393,0,21.861,0
			C21.331,0,20.9,0.431,20.9,0.961c0,0.103,0.029,0.196,0.059,0.289h-3.682c0.029-0.093,0.059-0.186,0.059-0.289
			c0-0.53-0.43-0.961-0.961-0.961c-0.531,0-0.961,0.431-0.961,0.961c0,0.103,0.028,0.196,0.058,0.289h-3.681
			c0.029-0.093,0.059-0.186,0.059-0.289C11.85,0.431,11.419,0,10.889,0c-0.531,0-0.962,0.431-0.962,0.961
			c0,0.103,0.028,0.196,0.058,0.289h-3.68c0.03-0.093,0.059-0.186,0.059-0.289C6.364,0.43,5.934,0,5.403,0
			C4.872,0,4.441,0.431,4.441,0.961c0,0.103,0.028,0.196,0.058,0.289H3.375c-1.518,0-2.75,1.233-2.75,2.75v26
			c0,1.518,1.232,2.75,2.75,2.75H26.27l5.855-5.855V4C32.125,2.483,30.893,1.25,29.375,1.25z M30.625,26.273l-0.311,0.311h-2.355
			c-1.102,0-2,0.9-2,2v2.355l-0.311,0.311H3.375c-0.689,0-1.25-0.561-1.25-1.25V9h28.5V26.273z"/>
		<polygon points="10.248,21.029 13.087,21.029 13.087,19.18 10.248,19.18 10.248,17.739 13.813,17.739 13.813,15.891 7.652,15.891
			7.652,23.812 10.248,23.812 		"/>
		<path d="M16.893,21.051h0.793c1.209,0,1.385,0.342,1.385,1.375c0,0.704,0.066,1.21,0.134,1.387h2.629V23.57
			c-0.31,0-0.274-0.353-0.274-1.387c0-1.595-0.507-1.793-1.343-2.101c0.925-0.253,1.332-1.022,1.332-1.937
			c0-1.541-0.868-2.255-3.51-2.255h-3.741v7.921h2.598v-2.762H16.893z M16.893,17.739h1.211c0.604,0,1.045,0.264,1.045,0.825
			c0,0.55-0.43,0.748-1.231,0.748h-1.022v-1.573H16.893z"/>
		<rect x="22.469" y="15.891" width="2.598" height="7.921"/>
			</SvgIcon>
</div>
	);
};

export default FridaySvg;
