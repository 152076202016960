import Button from "../../components/CustomButtons/Button";
import React from "react";
import { connect } from "react-redux";
import { handleEditStep } from "../../modules/editVisit";

const EditStepperButtons = (props) => {
  const { changeEditStep, activeStep, t, steps, isNextDisabled } = props;

  return (
    <>
      <Button onClick={() => changeEditStep(false, activeStep)}>
        {t.btnBack}
      </Button>
      <Button
        disabled={isNextDisabled}
        variant="contained"
        color="primary"
        onClick={() => changeEditStep(true, activeStep)}
      >
        {activeStep === steps.length - 1 ? t.btnFinish : t.btnNext}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isNextDisabled: state.editVisit.isNextDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEditStep: (isNext, activeStep) =>
      dispatch(handleEditStep(isNext, activeStep)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStepperButtons);
