const pocEsp = {
	title: "Plan of Care",
	p911header1: "Noticia importante",
	p911header2: "Noticia",
	p911content1: "Llame al 911 de inmediato si el paciente tiene problemas para respirar, dolor en el pecho, nueva confusión, incapacidad para mantenerse despierto, labios o cara azulados.",
	p911content2: "¡Llame a la Agencia si el paciente tiene algún cambio en su estado de salud y / o comportamiento!",
	p911content3: "¡Llame al 911 en caso de caídas del paciente y siga el Protocolo posterior a la caída de la Agencia!",
	p911Confirmed: "Confirmo"
}
export default pocEsp;
