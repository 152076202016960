import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "../styles";

const NewTimesheetRow = (props) => {
  const { visit, visitDate, handleRowClick, oldVisit, reasonText } = props;

  return (
    <Grid
      container
      direction={`row`}
      style={oldVisit ? styles.oldTSRow : styles.newRow}
      onClick={() => handleRowClick(visit)}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container direction={`column`}>
          {visitDate}
        </Grid>
        {reasonText ? (
          <Grid container direction={`column`}>
            {reasonText}
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default NewTimesheetRow;
