import React, {useState} from "react";
//import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
//import MenuItem from "@material-ui/core/MenuItem";
//import MenuList from "@material-ui/core/MenuList";
//import Grow from "@material-ui/core/Grow";
//import Paper from "@material-ui/core/Paper";
//import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
//import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Language from "@material-ui/icons/Language";
// core components
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import Power from "@material-ui/icons/PowerSettingsNew";
import {selectLanguageAction} from "../../modules/user";
import {connect} from "react-redux";
import {logoutAction} from "../../modules/auth";
import Dialog from "../../components/DialogMigrate/DialogMigrate";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LanguageIcon from '@material-ui/icons/Language';
import { openAppFeedbackDialog } from "modules/appModule.js";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = ( props ) => {
	const {language, setLang, logout,
		// openFeedbackDialog
		} = props;

	const classes = useStyles();

	const languages = ['English', 'Español', 'Русский'];

	const [openProfile, setOpenProfile] = useState(false);

	const [iwidth, setiWidth] = React.useState(window.innerWidth);

function handleWindowSizeChange() {
    setiWidth(window.innerWidth);
}
React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

let isMobile = (iwidth <= 959);

	const handleClickProfile = event => {
		//console.log('handleClickProfile')
		//console.log('openProfile',openProfile)
		setOpenProfile(!openProfile);
		//if (openProfile && openProfile.contains(event.target)) {
		//	setOpenProfile(false);
		//} else {
		//	setOpenProfile(event.currentTarget);
		//}
	};
	const handleSelectLanguage = key => {
		if (language !== key) {
			setLang(key);
		}
		setOpenProfile(false);
	};
	const handleLogout = () => {
		logout()
	};

	const handleClose = () => {
		setOpenProfile(false);
	};
	return (
			<div className={classes.manager}>

				<Button
						color={!isMobile ? "transparent" : "white"}
						justIcon={!isMobile}
						simple={isMobile}
						aria-label="Power"
						className={classes.buttonLink}
						onClick={handleLogout}
				>
					<Power className={classes.icons}/>
					<Hidden mdUp implementation="css"><p className={classes.linkText}>Logout</p>
					</Hidden>
				</Button>

				<Button
						color={!isMobile ? "transparent" : "white"}
						justIcon={!isMobile}
						simple={isMobile}
						aria-owns={openProfile ? "profile-menu-list-grow" : null}
						aria-haspopup="true"
						onClick={handleClickProfile}
						className={classes.buttonLink}
				>
					<Language className={classes.icons}/>
					<Hidden mdUp implementation="css">
						<p className={classes.linkText}>Language</p>
					</Hidden>
				</Button>
				<Dialog
						open={Boolean(openProfile)}
						//anchorEl={openProfile}
						transition
						//disablePortal
				>
					<DialogTitle id="alert-dialog-title" style={{ margin: "0 auto", padding: 0 }}>
						<div style={{ margin: 'auto', textAlign: 'center'}}>
							<LanguageIcon style={{ fontSize: 40 }}/>
							<h4 style={{ margin: 'auto', padding: '15px'}}><strong>{"Select language"}</strong></h4>
						</div>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<div className={classes.container}>
							{languages.map((lang, key) => {
								return (
										<div key={key}>
											<Button
													color="info"
													className={classes.buttonFullWidth}
													onClick={() => {handleSelectLanguage(key + 1)}}
											>{lang}
											</Button>
											{key < 2 ? <Divider light/> : <></>}
										</div>
								)
							})}
							</div>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<div style={{ margin: "auto" }}>
							<Button onClick={handleClose} color="success" type={`button`}>
								{"Close"}
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			</div>
	);
}

const mapStoreToProps = state => {
	const {language} = state.user;
	return {
		language
	}
};

const mapDispatchToProps = dispatch => {
	return {
		setLang: language => dispatch(selectLanguageAction(language)),
		logout: () => dispatch(logoutAction()),
		openFeedbackDialog: () => dispatch(openAppFeedbackDialog()),
	}
};

export default connect(mapStoreToProps, mapDispatchToProps)(AdminNavbarLinks);
