import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const BronxSvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 50 50`} {...props}>
				<path d="M 42.6875 14.914063 L 38.753906 11.378906 L 38.429688 11.859375 C 35.113281 16.800781 31.8125 23.09375 34.167969 35.445313 C 33.945313 35.214844 33.699219 34.964844 33.441406 34.695313 C 31.433594 32.621094 28.722656 29.820313 27.5 28.804688 C 27.5 28.132813 27.542969 27.148438 27.65625 26.785156 C 29.285156 22.457031 33.433594 14.019531 36.484375 10.105469 L 36.757813 9.757813 L 36.445313 9.445313 C 34.886719 7.886719 30.734375 4.074219 30.734375 4.074219 C 30.734375 4.074219 27.230469 16.144531 25.023438 20.914063 C 23.527344 17.453125 18.652344 5.226563 18.457031 4.792969 L 18.152344 4.121094 L 13.109375 9.304688 L 13.363281 9.648438 C 15.058594 11.933594 18.96875 18.898438 20.65625 22.597656 C 17.167969 19.117188 12.746094 13.648438 11.410156 11.714844 L 11.074219 11.226563 L 7.351563 14.945313 L 7.746094 15.503906 C 10.289063 18.933594 11.550781 23.136719 11.5 27.996094 C 11.457031 32.289063 8.839844 37.757813 7.578125 39.730469 L 7.363281 40.070313 L 12.101563 44.808594 L 12.433594 44.253906 C 16.734375 36.996094 16.773438 29.171875 16.402344 25.097656 C 16.746094 25.414063 21.59375 29.917969 22.503906 30.78125 L 22.503906 40 C 22.503906 41.460938 22.292969 43.457031 21.300781 44.6875 L 20.640625 45.5 L 29.546875 45.5 L 28.777344 44.660156 C 27.632813 43.417969 27.5 41.40625 27.5 40 L 27.5 35.753906 C 29.773438 37.917969 38.015625 45.691406 38.015625 45.691406 L 41.644531 42.0625 L 41.414063 41.71875 C 39.746094 39.261719 38.5 31.921875 38.5 27.011719 C 38.617188 21.730469 41.007813 17.164063 42.402344 15.300781 Z"/>
			</SvgIcon>
</div>
	);
};

export default BronxSvg;
