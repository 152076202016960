import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Grid from "@material-ui/core/Grid";
// custom components
//import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import GridItem from "../Grid/GridItem";
// style
//import styles from './styles'
import { makeStyles } from "@material-ui/core/styles";
import common from "../../assets/styles/common";
// utils
import { getMomentLocale } from "../../utils/languageUtils";
import { DATE_STRING_FORMAT } from "../../constants";
import moment from "moment";
import { NOTIFICATION_STATUS_SENT, setNotificationRead } from "../../modules/appnotifications";

const NotificationCard = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
    },
    smallDate: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightRegular,
    },
    statusIcon: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: "auto",
    },
    nopadding: {
      padding: 0,
    },
    borderOutlined:{
      borderRadius: 20,
      borderColor: "#4169E1",
      padding: 5,
    }
  }));

  const classes = useStyles();

  const { notification, language, setNotificationReadDispatch } = props;
  const momentLocale = getMomentLocale(language);
  moment.locale(momentLocale);
  function handleNotificationClick() {
		if (notification.status === NOTIFICATION_STATUS_SENT) {
      setNotificationReadDispatch(notification.id)
		}
	}

  return (
    <GridItem xs={12} sm={12} md={4}>
      <Card profile onClick={() => handleNotificationClick()}>
        <CardHeader color="success">
          <Grid container direction={`row`}>
            <Typography style={common.cardTitleWhiteFlex}>
              {notification.title}
            </Typography>
            {notification.pinned ? (
              <BookmarkIcon style={{ marginLeft: "auto" }} />
            ) : (
              ""
            )}
          </Grid>
        </CardHeader>
        <CardBody profile>
          <Typography component={'span'} variant={'body2'}>
            <div dangerouslySetInnerHTML={{ __html: notification.message }} />
          </Typography>
        </CardBody>
        <CardFooter style={common.container}>
          <Grid container direction={`row`}>
            <Typography className={classes.smallDate}>
              {moment(notification.post_time).format(DATE_STRING_FORMAT)}
            </Typography>
            <Typography className={classes.statusIcon}>
            {notification.status !== NOTIFICATION_STATUS_SENT ? (
                ""
              ) : (
                "Mark as Read"
              )}
              &nbsp;
              {notification.status !== NOTIFICATION_STATUS_SENT ? (
                <DoneAllIcon/>
              ) : (
                <DoneIcon/>
              )}
            </Typography>
          </Grid>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

const mapStoreToProps = (state) => {
  const { language } = state.user;
  const { appnotifications } = state.appnotifications;
  return { language,appnotifications };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationReadDispatch: (props) => dispatch(setNotificationRead(props)),
  };
};

//export default NotificationCard;
export default connect(mapStoreToProps,mapDispatchToProps)(NotificationCard);
