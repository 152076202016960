const availEsp = {
	title: "Disponibilidad",
	title_2_1: "Esta función de disponibilidad le permitira mantenernos actualizados con su información de disponibilidad.",
	title_2_2: "Para comenzar, seleccione:",
	title_2_3: "* ¿Qué tipo de caso le interesa?",
	title_2_4: "* ¿Género con el que puedes trabajar?",
	title_2_5: "* ¿Alguna alergia?",
	title_2_6: "* Comentarios adicionales relacionados con la disponibilidad, luego haga click en siguiente",
	tab_1: "General",
	tab_2: "Sitio",
	tab_3: "Horario",
	faq_question_1: "Como puedo obtener un caso adicional?",
	faq_answer_1: "Si tiene menos de 40 horas por la semana, se pueden ofrecer horas de trabajo caso a disponibilidad. ",
	faq_question_2: "Soy asistente personal. Es posible obtener horas extra?",
	faq_answer_2_1: "Muchos de nuestros empleados tienen ingresos adicionales que proveen servicios a otros pacientes, pero para esto necesite una licencia. El proceso de obtencion no es complicado y no lleva mucho tiempo. Por favor llame a representante de RR.HH o visita nuestro ",
	faq_answer_2_2: "sitio web",
	faq_answer_2_3: "para mas informacion.",
	faq_question_3: "Recibiré un caso inmediatamente despues de someter mi disponibilidad?",
	faq_answer_3: "La información enviada es solo para ayudarnos a encontrarle un caso para usted, este proceso puede tomar tiempo. Cuando llegue el momento, la coordinadora se comunicarán con usted.",
	faq_question_4: "Si he agregado limitaciones a mi disponiblidad, quiere decir que recivire un caso con exactament la informacion sometida?",
	faq_answer_4: "La información enviada puede estar sujeta a cambios dependiendo de los requisitos adicionales necesarios cuando su coordinadora lo contacte.",
	btn_1: "Mas",
	btn_2: "Menos",
	successMessage: "Tu solicitud ha sido enviada. Le contactaremos tan pronto tengamos un caso disponible con la informacion sometida. Gracias!",
	errorMessage: "Peticion de disponibilidad  NO ha sido enviado, por favor prueba una vez mas tarde",
	warning_1: "Las horas extra  solo son posibles para los empleados, que trabajan como Asistentes Sanitario A Domicilio  o Asistente de cuidado perdonal.",
	question_1: "Disponibilidad",
	answer_1_1: "Me gustaria obtener horas adicional",
	question_2: "Tipo de caso que le interesa:",
	answer_2_1: "Permanente",
	answer_2_2: "Temporal",
	question_3: "Genero de los pacientes con los que puede trabajar:",
	answer_3_1: "Feminino",
	answer_3_2: "Masculino",
	question_4: "Alguna alergia?",
	answer_4_1: "NO Animal domestico",
	answer_4_2: "NO Fumar",
	addMessage: "Mensaje",
	mesPlaceholder: "Si tiene alguna pregunta, deje su mensaje ...",
	error_1: "NO recibiras notificaciones de ofertas de trabajo!",
	error_2: "Por favor elige un tipo de caso(s)",
	error_3: "Por favor, especifique genero del paciente que le gustaria ofrecen servicios",
	btn_3: "Siguiente",
	btn_4: "Guardar",
	btn_5: "Hecho",
	btn_6: "Atrás",
	question_5: "Donde puede trabajar - Municipios? (Si no puede trabajar en alguno de los distritos, haga clic en el).",
	answer_5_1: "Brooklyn",
	answer_5_2: "Manhattan",
	answer_5_3: "Bronx",
	answer_5_4: "Queens",
	answer_5_5: "Staten Island",
	question_6_1: "La distancia maxima al lugar del paciente es  ", // numero
	question_6_2: " milla",
	error_4: "Por favor, elige por lo menos uno distrito",
	question_7: "Cuando estas disponible? Por favor ingrese las horas",
	answer_7_1: "En culquier tiempo",
	question_8_1: "Cuantas horas como minimo aceptara? ", // numero
	question_8_2: " horas",
	error_5: "Por favor, elige por lo menos un dia",
	btn_7: "Pasar",
	summary_title : "Por favor confirme que toda la información esté correcta antes de guardar",
	summary_ok : "Confirmar",
	btn_verify : "Verificar",
}
export default availEsp;
