
import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const WednesdaySvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 32.75 32.75`} {...props}>
				<path d="M29.375,1.25h-1.123c0.029-0.093,0.059-0.186,0.059-0.289c0-0.53-0.432-0.961-0.963-0.961s-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.059,0.289h-3.68c0.029-0.093,0.059-0.186,0.059-0.289C22.823,0.431,22.393,0,21.861,0
			C21.331,0,20.9,0.431,20.9,0.961c0,0.103,0.029,0.196,0.059,0.289h-3.682c0.029-0.093,0.059-0.186,0.059-0.289
			c0-0.53-0.43-0.961-0.961-0.961c-0.531,0-0.961,0.431-0.961,0.961c0,0.103,0.028,0.196,0.058,0.289h-3.681
			c0.029-0.093,0.059-0.186,0.059-0.289C11.85,0.431,11.419,0,10.889,0c-0.531,0-0.962,0.431-0.962,0.961
			c0,0.103,0.028,0.196,0.058,0.289h-3.68c0.03-0.093,0.059-0.186,0.059-0.289C6.364,0.43,5.934,0,5.403,0
			C4.872,0,4.441,0.431,4.441,0.961c0,0.103,0.028,0.196,0.058,0.289H3.375c-1.518,0-2.75,1.233-2.75,2.75v26
			c0,1.518,1.232,2.75,2.75,2.75H26.27l5.855-5.855V4C32.125,2.483,30.893,1.25,29.375,1.25z M30.625,26.273l-0.311,0.311h-2.355
			c-1.102,0-2,0.9-2,2v2.355l-0.311,0.311H3.375c-0.689,0-1.25-0.561-1.25-1.25V9h28.5V26.273z"/>
		<polygon points="7.752,23.812 8.654,19.213 8.676,19.213 9.59,23.812 12.142,23.812 14.111,15.891 11.603,15.891 10.711,20.787
			10.689,20.787 9.743,15.891 7.598,15.891 6.652,20.787 6.63,20.787 5.738,15.891 3.23,15.891 5.199,23.812 		"/>
		<polygon points="21.074,21.964 17.059,21.964 17.059,20.721 20.326,20.721 20.326,18.872 17.059,18.872 17.059,17.739
			20.92,17.739 20.92,15.891 14.461,15.891 14.461,23.812 21.074,23.812 		"/>
		<path d="M29.346,19.688c0-2.607-1.584-3.795-4.137-3.795h-3.477v7.92h3.41C27.684,23.812,29.346,22.668,29.346,19.688z
			 M24.328,21.964v-4.225h0.496c1.386,0,1.925,0.44,1.925,2.09c0,1.496-0.66,2.134-1.87,2.134L24.328,21.964L24.328,21.964z"/>
			</SvgIcon>
</div>
	);
};

export default WednesdaySvg;
