import { LOGOUT } from "./auth";
import { getNevvon } from "../api";
/**
 * Constants
 */
export const NEVVON_LOADED = "wizard/NEVVON_LOADED";
//export const AVAILABILITY_WIZARD_COUNT = 'wizard/AVAILABILITY_WIZARD_COUNT';

/**
 * Action Creators
 */

/**
 * Reducer
 */

const initState = {
  nevvon: {
    template: "",
    nevvon_login: "",
    nevvon_pass: "",
    isNevvonLoaded: false,
  },
};

export default function nevvon(state = initState, action) {
  //console.log(' nevvon action');
  //console.log(action);
  switch (action.type) {
    case NEVVON_LOADED: {
      return {
        ...state,
        nevvon: {
          template: action.template,
          nevvon_login: action.nevvon_login,
          nevvon_pass: action.nevvon_pass,
          isNevvonLoaded: true,
        },
      };
    }
    case LOGOUT: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export function onGetNevvon() {
  return async (dispatch, getState) => {
    const phone = getState().auth.phone;

    const data = {
      phone: phone,
    };
    try {
      const token = getState().auth.token;
      const payload = await getNevvon(token, data);
      if (payload.ok) {
        payload.json().then((data) => {
          dispatch({
            type: NEVVON_LOADED,
            template: data.template,
            nevvon_login: data.nevvon_login,
            nevvon_pass: data.nevvon_pass,
          });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}
