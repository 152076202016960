import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Female = (props) => {
	return (
			<div style={{paddingRight: "10px"}}>
				<SvgIcon fontSize={`large`} viewBox={`0 0 488.286 488.286`} {...props}>
					<path d="M244.151,93.521c25.809,0,46.744-20.936,46.744-46.761C290.896,20.935,269.96,0,244.151,0
			c-25.826,0-46.761,20.935-46.761,46.76C197.391,72.585,218.325,93.521,244.151,93.521z"/>
			<path d="M375.613,239.431l-74.616-115.109c-5.117-7.897-13.672-12.895-23.133-13.054c-0.251-0.005-67.191-0.002-67.443,0
			c-9.344,0.062-18.014,5.156-23.133,13.054l-74.615,115.109c-4.236,6.535-4.559,14.865-0.844,21.707
			c3.725,6.842,10.89,11.102,18.676,11.102c7.205,0,13.912-3.646,17.834-9.692l26.416-40.744l-18.32,96.158
			c-0.664,3.48,0.259,7.069,2.518,9.795c2.253,2.732,5.607,4.307,9.149,4.307h13.708v130.895c0,13.991,11.338,25.329,25.328,25.329
			c13.984,0,25.321-11.338,25.321-25.321V332.063h23.376v130.895c0,13.983,11.338,25.322,25.32,25.322
			c13.984,0,25.321-11.339,25.321-25.322V332.063h13.708c3.544,0,6.898-1.575,9.148-4.307c2.262-2.725,3.182-6.314,2.521-9.795
			l-18.323-96.166l26.415,40.752c3.922,6.047,10.631,9.692,17.834,9.692c7.787,0,14.951-4.26,18.677-11.102
			C380.172,254.296,379.85,245.966,375.613,239.431z"/>
				</SvgIcon>
			</div>
	);
};

export default Female;
