import React from 'react';
import {connect} from "react-redux";
//import Button from '@material-ui/core/Button';
import Button from "../../components/CustomButtons/Button";
import Dialog from "../../components/DialogMigrate/DialogMigrate";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import pocEng from "../../assets/languages/eng/pocEng";
import pocEsp from "../../assets/languages/esp/pocEsp";
import pocRus from "../../assets/languages/rus/pocRus";
import styles from './styles';
import {makeStyles} from "@material-ui/core/styles";
import ImportantIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { orange } from '@material-ui/core/colors';
import {onCloseMessage,getImportantMessageOpened} from '../../modules/importantmessage';

const useStyles = makeStyles(styles);

const ImportantMessage = (props) => {

    const classes = useStyles();
    //console.log('tada')
    const {
        language,
        imessage,
        form_name,
        isLanguageSelected,
        policyAccepted
    } = props

    if (!(form_name in imessage)){
      props.getOpened(form_name);
    }
    //const [imessage[form_name], setOpen] = React.useState(false);

    let t = [null, pocEng, pocEsp, pocRus][language];

    const handleClose = () => {
        props.setCloseMessage(form_name);
        //setOpen(false);
      };

  return (
    <div className={classes.container}>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={imessage[form_name] && policyAccepted && isLanguageSelected}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ margin: "0 auto", padding: 0 }}>
        <div style={{ margin: 'auto', textAlign: 'center'}}>
            <ImportantIcon style={{ color: orange[500], fontSize: 40 }}/>
            <h4 style={{ margin: 'auto'}}><strong>{t.p911header1}</strong></h4>
        </div>
        </DialogTitle>
        <DialogContent>
          
          <DialogContentText id="alert-dialog-description">
                <p>{t.p911content1}</p>
                <p>{t.p911content2}</p>
                <p>{t.p911content3}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <div style={{ margin: "auto" }}>
            <Button onClick={handleClose} color="success" type={`button`}>
                {t.p911Confirmed}
            </Button>
        </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
	const imessage = state.importantmessage;
  const {language, isLanguageSelected, policyAccepted} = state.user;
	return {imessage, language, isLanguageSelected, policyAccepted}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setCloseMessage: (props) => dispatch(onCloseMessage(props)),
    getOpened: (props) => dispatch(getImportantMessageOpened(props))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportantMessage);