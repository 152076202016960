import { LOGOUT } from "./auth";
import { getCampaigns, getCampainTexts } from "../api";
/**
 * Constants
 */
export const CAMPAIGNS_LOADED = "campaign/CAMPAIGNS_LOADED";
export const CAMPAIGNS_ERROR = "campaign/CAMPAIGNS_ERROR";
export const CAMPAIGNS_TEXTS = "campaign/CAMPAIGNS_TEXTS";

/**
 * Action Creators
 */

/**
 * Reducer
 */

const initState = {
  isCampaignsLoaded: false,
  isCampaignsTextsLoaded: false,
  campaigns: [],
  campaign_texts: [],
};

export default function campaign(state = initState, action) {
  switch (action.type) {
    case CAMPAIGNS_TEXTS: {
      return {
        ...state,
        campaign_texts: action.campaign_texts,
        isCampaignsTextsLoaded: true,
      };
    }
    case CAMPAIGNS_LOADED: {
      return {
        ...state,
        campaigns: action.campaigns,
        isCampaignsLoaded: true,
      };
    }
    case LOGOUT:
    case CAMPAIGNS_ERROR: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export function onCampaignsTexts() {
  return async (dispatch, getState) => {
    const phone = getState().auth.phone;
    const campaign_message = getState().auth.campaign_message;

    const data = {
      phone: phone,
      campaign_name: campaign_message,
    };
    try {
      const token = getState().auth.token;
      const payload = await getCampainTexts(token, data);
      if (payload.ok) {
        payload.json().then((data) => {
          dispatch({
            type: CAMPAIGNS_TEXTS,
            campaign_texts: data.campaign_texts,
          });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getCampaignsChunk() {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth.token;
      const payload = await getCampaigns(token);
      if (payload.ok) {
        payload.json().then((data) => {
          dispatch({
            type: CAMPAIGNS_LOADED,
            campaigns: data.campaigns,
          });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}
