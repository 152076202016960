const availEng = {
	title: "Availability",
	title_2_1: "This Availability function allows you to keep us updated with your availability information.",
	title_2_2: "To begin, please select:",
	title_2_3: "* What type of case you're interested in?",
	title_2_4: "* Gender that you can work with?",
	title_2_5: "* Any allergy's?",
	title_2_6: "* Additional comments related to availability, then click next.",
	tab_1: "General",
	tab_2: "Location",
	tab_3: "Schedule",
	faq_question_1: "How can I get additional case?",
	faq_answer_1: "If you have less than 40 hours per week, additional working hours may be offered subject to availability.",
	faq_question_2: "I'm Personal Assistant. Is it possible to obtain extra hours?",
	faq_answer_2_1: "Many of our employees have additional income, providing services to other patient, but for this you need to have a license. The process of obtaining it is not complicated and does not take much time. Please contact the HR representative or visit our",
	faq_answer_2_2: "website ",
	faq_answer_2_3: "for more information.",
	faq_question_3: "Will I get a case as soon as I submit my availability?",
	faq_answer_3: "The information submitted is only to help us find the right case for you, this process can take time. You will be contacted by coordinators department if any case is available from the information submitted.",
	faq_question_4: "If I've set limitations to my Availability, does it mean I will receive a case with the exact details I've submitted?",
	faq_answer_4: "Submitted information may be subject to change depending on additional requirements needed when contacted by our coordinators.",
	btn_1: "More",
	btn_2: "Less",
	successMessage: "Your request was submitted.\nWe will contact you as soon as we have an available case from the availability information received.",
	errorMessage: "Availability request has NOT been sent, please try again later",
	warning_1: "Extra hours are only possible for employees,  which working as a Home Health Aides or Personal Care Aide.",
	question_1: "Availability",
	answer_1_1: "I would like to get additional hours",
	question_2: "Type of case that you are interested in:",
	answer_2_1: "Permanent",
	answer_2_2: "Temporary",
	question_3: "Patients' Gender that you can work with:",
	answer_3_1: "Female",
	answer_3_2: "Male",
	question_4: "Any allergy?",
	answer_4_1: "NO Pets",
	answer_4_2: "NO Smoke",
	addMessage: "Message",
	mesPlaceholder: "If you have any questions, please leave your message...",
	error_1: "You will NOT receive notifications of job offers!",
	error_2: "Please select case type(s)",
	error_3: "Please specify patient's gender you would like to provide service",
	btn_3: "Next",
	btn_4: "Save",
	btn_5: "Done",
	btn_6: "Back",
	question_5: "Where can you work - Boroughs? (If you can't work in any of the boroughs, please click on it).",
	answer_5_1: "Brooklyn",
	answer_5_2: "Manhattan",
	answer_5_3: "Bronx",
	answer_5_4: "Queens",
	answer_5_5: "Staten Island",
	question_6_1: "Maximum distance to patient's place is ", // number
	question_6_2: " miles",
	error_4: "Please select at least one district",
	question_7: "When are you available? Please put in the hours",
	answer_7_1: "Anytime",
	question_8_1: "How many hours at minimum will you accept? ", // number
	question_8_2: " hours",
	error_5: "Please select at least one day",
	btn_7: "Skip",
	summary_title : "Please confirm all information is correct before submitting",
	summary_ok : "Submit",
	btn_verify : "Verify",
}
export default availEng;
