import React from "react";
// code input
import ReactCodeInput from "react-code-input";
// UI
import LoginLoading from "../LoginLoading";
// redux
import {connect} from 'react-redux'
import {addPhoneDigit} from "../../modules/auth";

const PhoneForm = (props) => {
	const isWideScreen =  window.matchMedia("(min-width: 390px)").matches

	const phoneStyle = {
		fontFamily: "monospace",
		MozAppearance: "textfield",
		WebkitAppearance: "textfield",
		borderRadius: "5px",
		border: "1px solid",
		boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
		margin: "2px",
		paddingLeft: !isWideScreen ? "3px" : "6px",
		width: !isWideScreen ? "18px" : "24px",
		height: !isWideScreen ? "24px" : "30px",
		fontSize: !isWideScreen ? "14px" : "20px",
		boxSizing: "border-box",
		color: "black",
		backgroundColor: "white",
		borderColor: "lightgrey"
	}
	const firstDashStyle = {
		position: "absolute",
		height: 0,
		width: !isWideScreen ? "141px" : "177px",
		marginTop: !isWideScreen ? "3px" : "5px"
	}

	const secondDashStyle = {
		position: "absolute",
		height: 0,
		width: !isWideScreen ? "288px" : "360px",
		marginTop: !isWideScreen ? "3px" : "5px"
	}
	const {phone, isLoading, updatePhone} = props;
	return (
			isLoading ? (
					<LoginLoading />
			) : (
					<div>
						<style>{
							`
							.react-code-input input {
							-webkit-padding-start: 0px !important;
							-webkit-padding-end: 0px !important;
							text-align: center;
							}
							.react-code-input input:nth-child(3),
								.react-code-input input:nth-child(6)
							 {
									margin-right: 10px !important;
							 }`
						}
						</style>
						<span style={firstDashStyle}>&mdash;</span>
						<span style={secondDashStyle}>&mdash;</span>
						<ReactCodeInput
								name={`phone`}
								type={`number`}
								fields={10}
								inputStyle={phoneStyle}
								value={phone}
								onChange={value => updatePhone(value)}
								{...props}
						/>
					</div>
			)
	)
};

const mapStoreToProps = (state) => {
	return {
		phone: state.auth.phone,
		isLoading: state.auth.isLoading,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updatePhone: (value) => dispatch(addPhoneDigit(value))
	}
};

export default connect(mapStoreToProps, mapDispatchToProps)(PhoneForm)

