import React from "react";
// components
//import GridContainer from "../../components/Grid/GridContainer.js";
//import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import Button from "@material-ui/core/Button";
import Dialog from "../../components/DialogMigrate/DialogMigrate";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Dashboard from "./Dashboard";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { connect } from "react-redux";
import routesEng from "../../assets/languages/eng/routesEng";
import routesEsp from "../../assets/languages/esp/routesEsp";
import routesRus from "../../assets/languages/rus/routesRus";
import { NOTIFICATION_STATUS_SENT } from "modules/appnotifications";
import { Badge } from "@material-ui/core";

// redux

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  flex: {
    flex: 1,
    display: "contents",
  },
  iosnotch:{
    padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const NotificationsDialog = (props) => {
  const { language, appnotifications } = props;
  const sent_notifications = appnotifications.filter(function(e) {
    return e.status === NOTIFICATION_STATUS_SENT;
  });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  let t = [null, routesEng, routesEsp, routesRus][language];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.flex}>
      <IconButton onClick={handleClickOpen} id="NotificationDialogButton">
        {sent_notifications.length > 0 ? (
          <Badge
            badgeContent={sent_notifications.length}
            color="primary"
            overlap="circular"
            max={9}
          >
            <NotificationsActiveIcon />
          </Badge>
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.iosnotch}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t.campaign}
            </Typography>
          </Toolbar>
        </AppBar>
        <Dashboard />
      </Dialog>
    </div>
  );
};

const mapStoreToProps = (state) => {
  const { language } = state.user;
  const { appnotifications } = state.appnotifications;
  return { language, appnotifications };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(NotificationsDialog);
