import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const useStyles = makeStyles({
  editedRow: {
    paddingLeft: "10px",
    cursor: "pointer",
    boxShadow:
      "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
    backgroundColor: "#eeeeee",
    borderRadius: "3px",
  },
});

const EditedRow = (props) => {
  const { visit, handleRowClick } = props;
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        direction={`row`}
        className={classes.editedRow}
        onClick={() => handleRowClick(visit)}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {moment(visit.vd).format("D MMMM, dddd")}
        </Grid>
      </Grid>
    </div>
  );
};

export default EditedRow;
