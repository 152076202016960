import { sendAppFeedback } from "api";

export function sendAppFeedbackTrunk(feedback_string) {
    return async (dispatch, getState) => {
      try {
        const data = {
            feedback: feedback_string,
        };
        const token = getState().auth.token;
        const payload = await sendAppFeedback(token, data);
        if (payload.ok) {
          payload.json().then((data) => {
            //console.log(data)
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    };
  }