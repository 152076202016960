import * as Api from "../api";
import { LOGOUT, POLICY_ACCEPTED } from "./auth";

/**
 * Constants
 */

export const SETUP_USER = "user/SETUP_USER";
export const SELECT_LANGUAGE_START = "user/SELECT_LANGUAGE_START";
export const SELECT_LANGUAGE_END = "user/SELECT_LANGUAGE_END";
export const SELECT_LANGUAGE_OK = "user/SELECT_LANGUAGE_OK";
export const SELECT_LANGUAGE_ERROR = "user/SELECT_LANGUAGE_ERROR";
export const SET_POLICY_START = "user/SET_POLICY_START";
export const SET_POLICY_END = "user/SET_POLICY_END";
export const SET_POLICY_OK = "user/SET_POLICY_OK";
export const SET_POLICY_ERROR = "user/SET_POLICY_ERROR";

/**
 * Action Creators
 */

export function selectLanguageAction(language) {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth.token;
      dispatch({ type: SELECT_LANGUAGE_START });
      const payload = await Api.setLanguage(token, language);
      if (payload.ok) {
        dispatch({ type: SELECT_LANGUAGE_OK, language });
      } else {
        dispatch({ type: SELECT_LANGUAGE_ERROR });
      }
      dispatch({ type: SELECT_LANGUAGE_END });
    } catch (error) {
      console.log(error);
    }
  };
}

export function acceptPolicy() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_POLICY_START });
      const token = getState().auth.token;
      const payload = await Api.acceptCompanyPolicy(token);
      if (payload.ok) {
        dispatch({ type: POLICY_ACCEPTED });
      } else {
        dispatch({ type: SET_POLICY_ERROR });
      }
      dispatch({ type: SET_POLICY_END });
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Reducer
 */

const initialState = {
  username: null,
  firstName: null,
  lastName: null,
  fullName: null,
  isLanguageSelected: false,
  language: 1,
  policyAccepted: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SELECT_LANGUAGE_OK:
      return {
        ...state,
        language: action.language,
        isLanguageSelected: true,
      };

    case SELECT_LANGUAGE_ERROR:
      return state;

    case SETUP_USER:
      return {
        ...action.userData,
      };
    case POLICY_ACCEPTED:
      return {
        ...state,
        policyAccepted: true,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
