const availRus = {
	title: "Возможность дополнительного дохода",
	title_2_1: "Эта функция позволяет Вам обновлять информацию о занятости.",
	title_2_2: "Для того чтобы начать, пожалуйста, выберете:",
	title_2_3: "* Какой тип визита Вас интересует?",
	title_2_4: "* С пациентом какого пола готовы работать?",
	title_2_5: "* Есть ли у Вас аллергия?",
	title_2_6: "* Дополнительная информация, касающаяся занятости. Затем нажмите 'Дальше'.",
	tab_1: "Главное",
	tab_2: "Расположение",
	tab_3: "График",
	faq_question_1: "Могу ли я получить дополнительные часы?",
	faq_answer_1: "Если Вы работаете менее 40 часов в неделю, то дополнительные часы могут быть предложенны, в зависимости от наличия.",
	faq_question_2: "Я персональный помошник. Возможно ли получить работу у других пациентов?",
	faq_answer_2_1: "Многие из наших сотрудников имеют дополнительный доход, предоставляя сервис по другим программам, но для этого необходима лицензия. Процесс её получения не сложный и не отнитмет много времени. Пожалуйста, свяжитесь с HR специалистом или посетите наш ",
	faq_answer_2_2: "вебсайт ",
	faq_answer_2_3: "для получения более детальной информации.",
	faq_question_3: "Сразу ли мне предложат работу после отправки формы?",
	faq_answer_3: "Информация, отправленная вами поможет найти для вас подходящую работу, этот процесс может занять некоторое время. С вами свяжется координатор, если будет работа, соответствующая информации, предоставленной в форме.",
	faq_question_4: "Если я указываю на ограничения в настройках визита, означает ли это, что все они будут учтены?",
	faq_answer_4: "Представленная информация может быть изменена в зависимости от дополнительных требований, необходимых при обращении к нашим координаторам.",
	btn_1: "Ещё",
	btn_2: "Свернуть",
	successMessage: "Ваша запрос был отпрален.\nМы свяжемся с вами, как только будет работа, соответствующая запросу.",
	errorMessage: "Запрос не был отправлен, пожалуйста повторите попытку позже",
	warning_1: "Дополгительные часы могут быть предоставленны только Home Health Aides или Personal Care Aide.",
	question_1: "Возможность",
	answer_1_1: "Я хотел(а) бы получить дополнительные часы.",
	question_2: "Вид занятости, который вас интересует:",
	answer_2_1: "Постоянная",
	answer_2_2: "Временная (замены)",
	question_3: "Пол пациентов, с которыми вы можете работать:",
	answer_3_1: "Женщина",
	answer_3_2: "Мужчина",
	question_4: "Ограничения?",
	answer_4_1: "БЕЗ домашних животных",
	answer_4_2: "НЕ курит",
	addMessage: "Сообщение",
	mesPlaceholder: "Если у Вас есть вопросы, пожалуйста, оставте сообщение...",
	error_1: "Вы НЕ будите получать новых предложений работы!",
	error_2: "Пожалуйста, выберите тип занятости",
	error_3: "Пожалуйста, укажите возможный пол пациента",
	btn_3: "Дальше",
	btn_4: "Сохранить",
	btn_5: "Готово",
	btn_6: "Вернуться",
	question_5: "Где вы можете работать - Районы? (Если вы не можете работать в одном из районов, нажмите на него).",
	answer_5_1: "Бруклин",
	answer_5_2: "Манхеттан",
	answer_5_3: "Бронкс",
	answer_5_4: "Квинс",
	answer_5_5: "Стейтен Айленд",
	question_6_1: "Максимольное расстояние до работы ", // number
	question_6_2: " миль",
	error_4: "Пожалуйста, выберите как минимум один район",
	question_7: "В какое время Вы могли бы работать? Пожалуйста, укажите часы",
	answer_7_1: "Любое время",
	question_8_1: "Минимальная продолжительность визита на которую Вы согласны? ", // number
	question_8_2: " часов",
	error_5: "Пожалуйста, выберите как минимум один день",
	btn_7: "Пропустить",
	summary_title : "Пожалуйста подтвердите корректность информации перед подтверждением",
	summary_ok : "Подтвердить",
	btn_verify : "Сверить",
}
export default availRus;
