/**
 * If user is trying to navigate using direct urls
 * check if user data has been loaded, which means access was accepted or redirect to login page
* */
import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

const ProtectedLayout = ({children, ...rest}) => {

	const {uploadDone} = rest;

	return (
				<Route
						{...rest}
						render={({location}) =>
								uploadDone ? (
										children
								) : (
										<Redirect
												to={{
													pathname: "/login",
													state: {from: location}
												}}
										/>
								)
						}
				/>
	);
};

const mapStateToProps = (state) => {
	return {
		uploadDone: state.app.uploadDone,
	}
};

export default connect(mapStateToProps)(ProtectedLayout);
