import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader";
import FaqButton from "../../components/FaqButton";
import Button from "../../components/CustomButtons/Button";
import CardBody from "../../components/Card/CardBody";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import CustomInput from "../../components/CustomInput/CustomInput";
import FaqBlock from "../../components/FaqBlock";
import Danger from "../../components/Typography/Danger";
// icons
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import Room from "@material-ui/icons/Room";
import Schedule from "@material-ui/icons/Schedule";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
// tabs
import GeneralAvail from "./General";
import AvailLocation from "./Location";
import ScheduleOfAvail from "./ScheduleOfAvail";
import Done from "./Done";
// reducer functions
import {
  getAvailabilityChunk,
  setStepTab,
  setComment,
} from "../../modules/availability";
// language
import availEng from "../../assets/languages/eng/availEng";
import availEsp from "../../assets/languages/esp/availEsp";
import availRus from "../../assets/languages/rus/availRus";
// tour
import Tour from "reactour";
import {
  handleWizardSubmit,
  handleWizardSkip,
  //getWizardChunk,
} from "../../modules/availabilitywizard";
import PinFormDialog from "../../components/PinFormDialog/PinFormDialog";
import SummaryDialog from "./SummaryDialog";
import { FEATURE_ENABLE_A_SUMMARY } from "../../constants";
import ScrollLock from "react-scrolllock"; //scroll lock for availability wizard

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const Availability = (props) => {
  const {
    step,
    is_cdpap,
    language,
    wizard,
    setWizardSubmit,
    setWizardSkip,
    isAvailabilityLoaded,
    onAvailabilityRet,
    errGender,
    errCaseType,
    avail,
    errDistrict,
    errScheduleDays,
    comment,
    setMessageComment,
  } = props;

  React.useEffect(() => {
    //console.log('changed step scrolling to active element');
    //let scroll_el = document.querySelector("#AvailStepScroller");

    let scroll_el = document.querySelector("#mainDashboardPanel");
    if (scroll_el) {
      scroll_el.scrollTop = 0;
      /**scroll_el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });*/
    } else {
      scroll_el = document.querySelector("#AvailStepScroller");
      if (scroll_el) {
        scroll_el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    //window.scrollTo(0, 0)
  }, [step]);
  /**
  React.useEffect(() => {
    if (!isAvailabilityWizardLoaded) {
      onWizardRet();
    }
  }, [isAvailabilityWizardLoaded]);*/
  React.useEffect(() => {
    if (!isAvailabilityLoaded) {
      onAvailabilityRet();
    }
  }, [isAvailabilityLoaded, onAvailabilityRet]);

  let wizard_steps_sorted = [];
  if (wizard.steps !== undefined) {
    wizard_steps_sorted = wizard.steps.sort((a, b) =>
      a.wizard_step > b.wizard_step ? 1 : -1
    );
  }
  //const wizard_steps_sorted = wizard.steps.sort((a, b) => (a.wizard_step > b.wizard_step) ? 1 : -1);
  //wizard_steps_sorted
  //console.log('wizard_steps_sorted');
  //console.log(wizard_steps_sorted);

  //const isTourOpen = true;
  const [isTourOpen, setIsTourOpen] = React.useState(true);
  const [isLockScroll, setIsLockScroll] = React.useState(false);
  const [isTourStep, setisTourStep] = React.useState(0);

  React.useEffect(() => {
    //console.log('step',step)
    if (isTourOpen && isTourStep !== 7) {
      setIsLockScroll(true);
    } else {
      setIsLockScroll(false);
    }
  }, [setIsLockScroll, isTourStep, isTourOpen]);

  if (wizard.show_count > 0 && !isTourOpen) {
    setIsTourOpen(true);
    setIsLockScroll(true);
  } else {
    if (wizard.show_count <= 0 && isTourOpen) {
      setIsTourOpen(false);
      setIsLockScroll(false);
    }
  }

  const handlesetWizardSubmit = (event, value) => {
    setWizardSubmit(props);
  };

  const handleSkipWizard = (event, value) => {
    setWizardSkip(props);
  };

  const classes = useStyles();

  let t = [null, availEng, availEsp, availRus][language];

  //wizard steps
  const reactour_steps = [
    {
      // selector: '#campaign_step',

      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[0].wizard_text
              : ""}
          </p>
          <div className={classes.btnGroup}>
            <Button color="success" type={`button`} onClick={() => goTo(1)}>
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_1",
      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[1].wizard_text
              : ""}
          </p>
          {!avail && <Danger>{t.error_1}</Danger>}
          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              onClick={() => {
                if (avail) {
                  goTo(2);
                } else {
                  close();
                  handlesetWizardSubmit();
                }
              }}
            >
              {avail ? t.btn_3 : t.btn_5}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_2",
      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[2].wizard_text
              : ""}
          </p>
          {errCaseType && <Danger>{t.error_2}</Danger>}
          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              onClick={() => goTo(3)}
              disabled={errCaseType}
            >
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_3",
      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[3].wizard_text
              : ""}
          </p>
          {errGender && <Danger>{t.error_3}</Danger>}
          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              onClick={() => goTo(4)}
              disabled={errGender}
            >
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_4",
      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[4].wizard_text
              : ""}
          </p>
          <div className={classes.btnGroup}>
            <Button color="success" type={`button`} onClick={() => goTo(5)}>
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_5",
      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[5].wizard_text
              : ""}
          </p>
          <CustomInput
            labelText={t.mesPlaceholder}
            id="add-msg"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              rows: 2,
              onChange: (event) => setMessageComment(event.target.value),
              value: comment,
            }}
            labelProps={{
              classes: {
                focused: classes.longLabelText,
                root: classes.longLabelText,
              },
            }}
          />

          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              onClick={() => {
                document
                  .querySelector("#general_next_button_div")
                  .children[0].click();
                goTo(6);
              }}
            >
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_6",
      position: [0, 0],
      content: ({ goTo, close }) => (
        <div>
          {wizard_steps_sorted.length > 1
            ? wizard_steps_sorted[6].wizard_text
            : ""}
          {errDistrict && <Danger>{t.error_4}</Danger>}

          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              disabled={errDistrict}
              onClick={() => {
                document
                  .querySelector("#location_next_button_div")
                  .children[0].click();
                goTo(7);
              }}
            >
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_7",
      position: [0, 0],
      //style:{padding:0},

      content: ({ goTo, close }) => (
        <div>
          {wizard_steps_sorted.length > 1
            ? wizard_steps_sorted[7].wizard_text
            : ""}
          {errScheduleDays && <Danger>{t.error_5}</Danger>}
          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              disabled={errScheduleDays}
              onClick={() => {
                goTo(8);
              }}
            >
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      selector: "#tour_wizard_step_8",
      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[8].wizard_text
              : ""}
          </p>
          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              onClick={() => {
                document
                  .querySelector("#schedule_next_button_div")
                  .children[0].click();
                goTo(9);
              }}
            >
              {t.btn_3}
            </Button>
            <Button
              color="white"
              type={`button`}
              onClick={() => {
                handleSkipWizard();
                close();
              }}
            >
              {t.btn_7}
            </Button>
          </div>
        </div>
      ),
    },
    {
      content: ({ goTo, close }) => (
        <div>
          <p>
            {wizard_steps_sorted.length > 1
              ? wizard_steps_sorted[FEATURE_ENABLE_A_SUMMARY ? 10 : 9]
                  .wizard_text
              : ""}
          </p>
          <div className={classes.btnGroup}>
            <Button
              color="success"
              type={`button`}
              onClick={() => {
                close();
                handlesetWizardSubmit();
              }}
            >
              {t.btn_5}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  // collapse
  const [checked, setChecked] = React.useState(false);
  const content = React.createRef();

  function handleFaqButtonClick() {
    setChecked((prev) => !prev);
    content.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  const handleTabChange = (event, value) => {
    props.setTab(value + 1);
  };
  let aTabs = [
    {
      tabName: t.tab_1,
      tabIcon: PermContactCalendarIcon,
      tabContent: <GeneralAvail />,
    },
    {
      tabName: t.tab_2,
      tabIcon: Room,
      tabContent: <AvailLocation />,
    },
    {
      tabName: t.tab_3,
      tabIcon: Schedule,
      tabContent: (
        <div id="tour_wizard_step_7">
          <ScheduleOfAvail />
        </div>
      ),
    },
    {
      tabName: t.btn_5,
      tabIcon: DoneOutlineIcon,
      //replace Done w SummaryDialog after confirmation
      tabContent: FEATURE_ENABLE_A_SUMMARY ? <SummaryDialog /> : <Done />,
      //tabContent: <SummaryDialog/>,
    },
  ];

  function paFaq() {
    return (
      <span>
        {t.faq_answer_2_1}
        <a target={`_blank`} href="http://allheartcare.com">
          {" "}
          {t.faq_answer_2_2}
        </a>{" "}
        {t.faq_answer_2_3}
      </span>
    );
  }

  return (
    <div>
      {is_cdpap ? <Redirect to={"/main/timesheet"} /> : ""}
      {isTourOpen ? "" : <PinFormDialog />}
      <Tour
        inViewThreshold={100}
        disableDotsNavigation
        showButtons={false}
        showCloseButton={false}
        showNavigation={false}
        showNavigationNumber={false}
        showNumber={false}
        steps={reactour_steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        getCurrentStep={(curr) => {
          //console.log(`The current step is ${curr}`);
          setisTourStep(curr);
        }}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} id="AvailStepScroller">
          <CustomTabs
            handleTabChange={handleTabChange}
            value={step - 1}
            headerColor={`primary`}
            tabs={aTabs.slice(0, step)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card profile>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite} ref={content}>
                FAQ
              </h4>
              <p className={classes.cardCategoryWhite}>{t.title}</p>
            </CardHeader>
            <CardBody profile>
              <FaqBlock question={t.faq_question_3} answer={t.faq_answer_3} />
              <FaqBlock question={t.faq_question_4} answer={t.faq_answer_4} />
              <div hidden={checked}>
                <FaqButton
                  title={t.btn_1}
                  onClickHandler={handleFaqButtonClick}
                />
              </div>
              <div>
                <Collapse in={checked}>
                  <FaqButton
                    title={t.btn_2}
                    onClickHandler={handleFaqButtonClick}
                  />
                  <FaqBlock
                    question={t.faq_question_1}
                    answer={t.faq_answer_1}
                  />
                  {is_cdpap && (
                    <FaqBlock question={t.faq_question_2} answer={paFaq()} />
                  )}
                </Collapse>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ScrollLock isActive={isLockScroll} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    wizard,
    //  isAvailabilityWizardLoaded
  } = state.wizard;
  const { language } = state.user;
  const {
    step,
    avail,
    is_cdpap,
    caseType,
    loc,
    minDur,
    allergy,
    gender,
    sch,
    isAvailabilityLoaded,
    errGender,
    errCaseType,
    errDistrict,
    errScheduleDays,
    comment,
  } = state.availability;
  return {
    language,
    step,
    avail,
    is_cdpap,
    caseType,
    loc,
    minDur,
    allergy,
    gender,
    sch,
    wizard,
    isAvailabilityLoaded,
    errGender,
    errCaseType,
    errDistrict,
    errScheduleDays,
    comment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTab: (tab) => dispatch(setStepTab(tab)),
    setWizardSubmit: (props) => dispatch(handleWizardSubmit(props)),
    setWizardSkip: (props) => dispatch(handleWizardSkip(props)),
    onAvailabilityRet: () => dispatch(getAvailabilityChunk()),
    setMessageComment: (message) => dispatch(setComment(message)),
    //onWizardRet: () => dispatch(getWizardChunk()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Availability);
