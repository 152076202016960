import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// redux
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
// modules
import rootReducer from "./modules";
// style
import "assets/css/material-dashboard-react.css?v=1.8.0";
import Snackbar from "./components/Snackbar/Snackbar";
import { Capacitor } from "@capacitor/core";

const composeEnhancers =
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      {Capacitor.isNativePlatform()?'':<Snackbar />}
    </Provider>
  </BrowserRouter>,

  document.getElementById("root")
);
