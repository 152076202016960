import React from "react";
// UI
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomRadio from "../../../components/CustomRadio/CustomRadio";
// redux
import { connect } from "react-redux";
import { handleExplanationChange } from "../../../modules/editVisit";
// style
import styles from "../styles";
import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";

function Explanation(props) {
  const {
    explanations,
    reasonId,
    explanationChange,
    currentExplanation,
  } = props;

  function handleChange(event) {
    const explanation = event.target.value;
    explanationChange(explanation);
  }

  return (
    <FormControl component="fieldset" style={styles.formControl}>
      <RadioGroup aria-label="main" name="main" value={"explanations"}>
        {explanations[reasonId].map((explanation, index) => {
          const checked = currentExplanation === explanation;
          return (
            <FormControlLabel
              style={checked ? { color: grayColor[7] } : {}}
              checked={checked}
              key={index}
              value={explanation}
              control={<CustomRadio />}
              label={explanation}
              onChange={(event) => handleChange(event)}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

const mapStateToProps = (state) => {
  return {
    reasonId: state.editVisit.reasonId,
    currentExplanation: state.editVisit.explanation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    explanationChange: (explanation) =>
      dispatch(handleExplanationChange(explanation)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Explanation);
