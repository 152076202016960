import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const Male = (props) => {
	return (
			<div style={{paddingRight: "10px"}}>
				<SvgIcon fontSize={`large`} viewBox={`0 0 53.545 53.545`} {...props}>
					<circle  cx="26.686" cy="4.507" r="4.507"/>
					<path d="M28.256,11.163c-1.123-0.228-2.344-0.218-3.447,0.042c-7.493,0.878-9.926,9.551-9.239,16.164
			c0.298,2.859,4.805,2.889,4.504,0c-0.25-2.41-0.143-6.047,1.138-8.632c0,3.142,0,6.284,0,9.425c0,0.111,0.011,0.215,0.016,0.322
			c-0.003,0.051-0.015,0.094-0.015,0.146c0,7.479-0.013,14.955-0.322,22.428c-0.137,3.322,5.014,3.309,5.15,0
			c0.242-5.857,0.303-11.717,0.317-17.578c0.244,0.016,0.488,0.016,0.732,0.002c0.015,5.861,0.074,11.721,0.314,17.576
			c0.137,3.309,5.288,3.322,5.15,0c-0.309-7.473-0.32-14.949-0.32-22.428c0-0.232-0.031-0.443-0.078-0.646
			c-0.007-3.247-0.131-6.497-0.093-9.742c1.534,2.597,1.674,6.558,1.408,9.125c-0.302,2.887,4.206,2.858,4.504,0
			C38.678,20.617,36.128,11.719,28.256,11.163z"/>
				</SvgIcon>
			</div>
	);
};

export default Male;
