/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Badge from "@material-ui/core/Badge";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
//import Button from "../../components/CustomButtons/Button.js";

import { connect } from "react-redux";
import routesEng from "../../assets/languages/eng/routesEng";
import routesEsp from "../../assets/languages/esp/routesEsp";
import routesRus from "../../assets/languages/rus/routesRus";
import dashboardRoutes from "../../routes";
import bgImage from "assets/img/sidebar-2.png";
import logo from "assets/img/Logo_All-Heart_wite.png";
import { NOTIFICATION_STATUS_SENT } from "../../modules/appnotifications";
import {
  BITRIX_CHAT_LOADER_URL,
  FEATURE_ENABLE_BITRIX_CHAT,
  FEATURE_ENABLE_BITRIX_CHAT_AS_SCRIPT,
  FEATURE_ENABLE_BOTTOMBAR,
} from "../../constants";
import { openAppFeedbackDialog } from "modules/appModule";
import { Capacitor } from "@capacitor/core";
import BitrixWebChat from "@comparaonline/ui-offer-web-chat-bitrix";
import md5 from "md5";

const useStyles = makeStyles(styles);

const Sidebar = (props) => {
  const {
    language,
    appnotifications,
    is_cdpap,
    openFeedbackDialog,
    fullName,
    username,
    phone,
  } = props;
  const sent_notifications = appnotifications.filter(function(e) {
    return e.status === NOTIFICATION_STATUS_SENT;
  });

  const [iwidth, setiWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setiWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const [onBitrixLiveChat, setonBitrixLiveChat] = React.useState(false);
  function onChatOpenClick() {
    if (FEATURE_ENABLE_BITRIX_CHAT_AS_SCRIPT && FEATURE_ENABLE_BITRIX_CHAT) {
      if (!onBitrixLiveChat){
        (function(w, d, u) {
          var s = d.createElement("script");
          s.async = true;
          s.src = u + "?" + ((Date.now() / 60000) | 0);
          var h = d.getElementsByTagName("script")[0];
          h.parentNode.insertBefore(s, h);
        })(
          window,
          document,
          BITRIX_CHAT_LOADER_URL
        );
        setonBitrixLiveChat(true);
      }
      
    }
  }

  const bitrixWebChatUrl = () => {
    let u = BITRIX_CHAT_LOADER_URL;
    let src = u + "?" + ((Date.now() / 60000) | 0);
    return src;
  };
  const BitrixWebChatChilren = (cprops) => {
    const { onClick } = cprops;
    return (
      <Link href="#" onClick={onClick}>
        <ListItem
          button
          className={classNames(
            classes.itemLink,
            //listItemClasses,
            classes.flexDisplay
          )}
        >
          <ListItemText
            primary={"Chat"}
            className={classNames(
              classes.itemText,
              //whiteFontClasses,
              {
                [classes.itemTextRTL]: props.rtlActive,
              }
            )}
            disableTypography={true}
          />
        </ListItem>
      </Link>
    );
  };
  let isMobile = iwidth <= 959;

  let t = [routesEng, routesEsp, routesRus];
  let routes = dashboardRoutes.map((prop) => ({
    ...prop,
    name: t[language - 1][prop.name],
  }));
  if (is_cdpap) {
    routes = routes.filter(function(obj) {
      return obj.path !== "/availability";
    });
  }

  let color = "purple";
  let image = bgImage;

  const classes = useStyles();

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1;
  }
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.path === "/campaign" && isMobile) {
          return "";
        }
        if (
          prop.path === "/availability" &&
          isMobile &&
          Capacitor.isNativePlatform() &&
          FEATURE_ENABLE_BOTTOMBAR
        ) {
          return "";
        }
        //skip qtn tab
        if (
          prop.path === "/qtn"
        ) {
          return "";
        }
        //skip patient_info tab
        if (
          prop.path === "/patient_info"
        ) {
          return "";
        }
        if (
          prop.path === "/timesheet" &&
          Capacitor.isNativePlatform() &&
          isMobile &&
          FEATURE_ENABLE_BOTTOMBAR
        ) {
          return "";
        }
        if (
          prop.path === "/poc" &&
          Capacitor.isNativePlatform() &&
          isMobile &&
          FEATURE_ENABLE_BOTTOMBAR
        ) {
          return "";
        }
        if (
          prop.path === "/schedule" &&
          Capacitor.isNativePlatform() &&
          isMobile &&
          FEATURE_ENABLE_BOTTOMBAR
        ) {
          return "";
        }
        var activePro = " ";
        var listItemClasses;

        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path),
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem
              button
              className={classNames(
                classes.itemLink,
                listItemClasses,
                classes.flexDisplay
              )}
            >
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : appnotifications.length > 0 && prop.path === "/campaign" ? (
                <Badge
                  badgeContent={sent_notifications.length}
                  color="primary"
                  overlap="circular"
                  max={9}
                >
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                </Badge>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive,
                  })}
                />
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive,
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      <Link href="#" onClick={openFeedbackDialog}>
        <ListItem
          button
          className={classNames(
            classes.itemLink,
            //listItemClasses,
            classes.flexDisplay
          )}
        >
          <ListItemText
            primary={"Feedback"}
            className={classNames(
              classes.itemText,
              //whiteFontClasses,
              {
                [classes.itemTextRTL]: props.rtlActive,
              }
            )}
            disableTypography={true}
          />
        </ListItem>
      </Link>
      {FEATURE_ENABLE_BITRIX_CHAT ? (
        FEATURE_ENABLE_BITRIX_CHAT_AS_SCRIPT ? (
          <BitrixWebChatChilren
            onClick={() => {
              onChatOpenClick();
            }}
          />
        ) : (
          <BitrixWebChat
            name={fullName}
            email={username}
            id={md5(phone)}
            autoInit={true}
            src={bitrixWebChatUrl()}
          >
            {BitrixWebChatChilren}
          </BitrixWebChat>
        )
      ) : (
        ""
      )}

      {/**<Button onClick={openFeedbackDialog} color="success" type={`button`}>
              		{"Feedback"}
            	</Button>*/}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://www.allheartcare.com"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          PaperProps={{
            onClick: props.handleDrawerToggle,
            style: { paddingTop: "env(safe-area-inset-top)" },
          }}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { language, fullName, username } = state.user;
  const { phone } = state.auth;
  const { appnotifications } = state.appnotifications;
  const { is_cdpap } = state.availability;
  return { language, appnotifications, is_cdpap, fullName, username, phone };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openFeedbackDialog: () => dispatch(openAppFeedbackDialog()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
