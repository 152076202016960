const scheduleEsp = {
	schedule: "Calendario",
	month: "Mes",
	week: "Semana",
	day: "Dia",
	today: "Hoy",
	previous: "Retroceder",
	next: "Siguiente",
	agenda: "Agenda",
	date: "Fecha",
	time: "Hora",
	event: "Paciente",
	address:"Dirección",
};

export default scheduleEsp;