import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const StatenSvg = (props) => {
	return (
			<div style={{paddingRight: "10px"}}>
				<SvgIcon fontSize={`large`} viewBox={`0 0 512.005 512.005`} {...props}>
					<path d="m360.953 106c0-8.284-6.716-15-15-15h-180c-8.284 0-15 6.716-15 15v45h210z"/>
					<path d="m311.336 15c0-8.284-6.716-15-15-15h-80.666c-8.284 0-15 6.716-15 15v46h110.666z"/>
					<path
							d="m507.914 469.657c-5.684-6.023-15.177-6.303-21.204-.618-18.422 17.375-57.393 17.377-75.814 0-5.779-5.453-14.807-5.451-20.586 0-18.421 17.375-57.391 17.377-75.813 0-5.779-5.453-14.807-5.452-20.586 0-18.422 17.377-57.393 17.377-75.814 0-5.779-5.453-14.807-5.451-20.586 0-18.422 17.378-57.392 17.377-75.813 0-5.779-5.453-14.807-5.452-20.586 0-18.422 17.378-57.393 17.378-75.814 0-6.028-5.688-15.522-5.408-21.204.618-5.685 6.026-5.408 15.52.618 21.204 26.617 25.11 75.172 27.969 106.693 7.966 27.35 17.357 68.4 17.769 96.399 0 27.35 17.357 68.401 17.769 96.4 0 27.35 17.357 68.4 17.769 96.399 0 31.518 20.001 80.072 17.147 106.693-7.966 6.026-5.684 6.302-15.177.618-21.204z"/>
					<path d="m406.003 275.115v-79.115c0-8.284-6.716-15-15-15h-134.998c9.197 0 18.056 2.768 25.616 8.004z"/>
					<path
							d="m256.005 181h-135.002c-8.284 0-15 6.716-15 15v79.114l124.386-86.113c7.567-5.235 16.422-8.001 25.616-8.001z"/>
					<path
							d="m127.389 437.882c5.473 2.079 10.535 5.222 14.896 9.336 6.807 6.423 27.804 6.445 34.639-.002 17.334-16.351 44.407-16.37 61.761.002.568.536 1.355 1.08 2.318 1.602v-230.68l-158.539 109.757c-6.591 4.563-8.429 13.502-4.174 20.296.399.636 27.351 43.864 49.099 89.689zm68.614-116.882c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.715-15 15-15z"/>
					<path
							d="m271.003 448.821c3.369-1.827 13.171-13.871 33.201-13.871 11.528 0 22.495 4.357 30.881 12.27 6.81 6.423 27.805 6.444 34.64-.003 4.083-3.852 8.782-6.853 13.855-8.927 21.115-46.858 47.309-91.652 47.693-92.307 3.975-6.77 2.059-15.46-4.396-19.929l-155.874-107.913zm45-127.821c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.715-15 15-15z"/>

				</SvgIcon>
			</div>
	);
};

export default StatenSvg;
