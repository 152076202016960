const scheduleEng = {
	schedule: "Schedule",
	month: "Month",
	week: "Week",
	day: "Day",
	today: "Today",
	previous: "Back",
	next: "Next",
	agenda: "Agenda",
	date: "Date",
	time: "Time",
	event: "Patient",
	address:"Address",
};

export default scheduleEng;