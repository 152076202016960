/**
 * messages
 */

export const DANGER = 'DANGER';
export const SUCCESS = 'SUCCESS';
export const WARNING = 'WARNING';

/**
 * Time formats
 */
export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_STRING_FORMAT = 'D MMMM, dddd';
export const LONG_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const MILITARY_TIME = 'HH:mm';
export const ZULU = ':00.000Z';
export const SHORT_WEEKDAY = 'ddd';
export const DAY_NUMBER = 'D';

/**
 * Timesheet steps
 */
export const MAX_VISIT_CHANGE_DURATION = 900000
export const TIME_STEP = 'visitTime'
export const POC_STEP = 'planOfCare'
export const PATIENT_SIGN_STEP = 'patientSign'
export const CAREGIVER_SIGN_STEP = 'caregiverSign'
export const MIN_TASKS = 7;
export const MIN_CATEGORIES = 5;
export const STATUS_PENDING = 'Pending';
/**
 * Campaigh list
 */
export const CAMPAIGN_MESSAGE_LIST_POC = 'poc';
export const CAMPAIGN_MESSAGE_LIST_TS = 'timesheet-msg';
export const CAMPAIGN_MESSAGE_LIST = [CAMPAIGN_MESSAGE_LIST_POC, CAMPAIGN_MESSAGE_LIST_TS];
/**
 * New feature list
 */
export const FEATURE_ENABLE_BOTTOMBAR = true;
export const FEATURE_ENABLE_A_SUMMARY = true;
export const FEATURE_ENABLE_BITRIX_CHAT = false;
export const FEATURE_ENABLE_BITRIX_CHAT_AS_SCRIPT = true;
export const BITRIX_CHAT_LOADER_URL = "https://bitrix24-222615.commondatastorage.googleapis.com/crm/site_button/loader_7_wnl662.js";
//export const BITRIX_CHAT_LOADER_URL = "https://bitrix24-222615.commondatastorage.googleapis.com/crm/site_button/loader_8_u3ki0z.js";
