const pinEsp = {
	title: "Establezca un nuevo PIN",
    title2: "Confirme el nuevo PIN",
    title3: "Intentelo de nuevo",
    please0digits:"Ingrese 4 dígitos",
    please1digits:"Ingrese 3 dígitos más",
    please2digits:"Ingrese 2 dígitos más",
    please3digits:"Ingrese 1 dígito más",
    please4digits:"Guardar PIN",
    ooPS:"No coinciden",
}
export default pinEsp;