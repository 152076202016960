import app from './appModule';
import auth from './auth';
import user from './user';
import campaign from "./campaigns";
import schedule from './schedule';
import timesheet from './timesheet';
import editVisit from './editVisit';
import notification from './notification';
import availability from './availability';
import wizard from './availabilitywizard';
import nevvon from './nevvon';
import poc from './poc';
import patient_info from './patientinfo';
import importantmessage from './importantmessage';
import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form';
import appnotifications from './appnotifications';

const rootReducer = combineReducers({
	app,
	auth,
	user,
	campaign,
	schedule,
	timesheet,
	editVisit,
	notification,
	availability,
	wizard,
	nevvon,
	poc,
	patient_info,
	importantmessage,
	appnotifications,
	form: formReducer
});

export default rootReducer;
