// @material-ui/icons
//import AnnouncementIcon from '@material-ui/icons/Announcement';
import CalendarToday from "@material-ui/icons/CalendarToday";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import AssignmentIcon from "@material-ui/icons/Assignment";
// import BeachAccess from "@material-ui/icons/BeachAccess";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
//import LocationCity from "@material-ui/icons/LocationCity";
// core components/views for Admin layout
import Campaign from "./views/Dashboard/Dashboard.js";
//import Form from "views/ChangeAddress/ChangeAddress"
// import Vacation from "views/Vacation/Vacation";
import Timesheet from "./views/Timesheet";
import Schedule from "./views/Schedule/Schedule";
import Availability from "./views/Availability/Availability";
import Nevvon from "./views/Nevvon/Nevvon";
import POC from "./views/POC/POC";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import QTN from "./views/QTN/QTN";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PatientInfo from "./views/PatientInfo/PatientInfo";

const dashboardRoutes = [
  {
    path: "/campaign",
    name: "campaign",
    icon: NotificationsActiveIcon,
    component: Campaign,
    layout: "/main",
  },
  {
    path: "/availability",
    name: "availability",
    icon: EventAvailableIcon,
    component: Availability,
    layout: "/main",
  },
  {
    path: "/qtn",
    name: "qtn",
    icon: QuestionAnswerIcon,
    component: QTN,
    layout: "/main",
  },
  {
    path: "/timesheet",
    name: "timesheet",
    icon: LibraryBooks,
    component: Timesheet,
    layout: "/main",
  },
  {
    path: "/poc",
    name: "poc",
    icon: AssignmentIcon,
    component: POC,
    layout: "/main",
  },

  {
    path: "/patient_info",
    name: "info",
    icon: AssignmentIcon,
    component: PatientInfo,
    layout: "/main",
  },

  {
    path: "/schedule",
    name: "schedule",
    icon: CalendarToday,
    component: Schedule,
    layout: "/main",
  },

  {
    path: "/nevvon",
    name: "nevvon",
    icon: LocalLibraryIcon,
    component: Nevvon,
    layout: "/main",
  },
  
  // {
  //     path: "/vacation",
  //     name: t.vacation,
  //     icon: BeachAccess,
  //     component: Vacation,
  //     layout: "/main"
  // },
  // {
  //     path: "/address",
  //     name: 'changeAddress',
  //     icon: LocationCity,
  //     component: Form,
  //     layout: "/main"
  // },
];

export default dashboardRoutes;
