const pinEng = {
	title: "Please set a new PIN",
    title2: "Please confirm new PIN",
    title3: "Please try again",
    please0digits:"Input 4 digits",
    please1digits:"Input 3 more digits",
    please2digits:"Input 2 more digits",
    please3digits:"Input 1 more digit",
    please4digits:"Save PIN",
    ooPS:"Mismatch",
}
export default pinEng;