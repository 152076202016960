import React from "react";
import {addCodeDigit,sendCodeEmail} from "../../modules/auth";
import ReactCodeInput from "react-code-input/dist/ReactCodeInput";
import {connect} from 'react-redux';
import LoginLoading from '../LoginLoading'
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

let CodeForm = (props) => {

	const {
		code,
		isLoading,
		updateCode,
		handleSendCode
	} = props;

	return (
			isLoading ? (
					<LoginLoading />
			) : (
					<div>
						<style>{
							`
							.react-code-input input {
							-webkit-padding-start: 0px !important;
							-webkit-padding-end: 0px !important;
							text-align: center;
							}`
						}
						</style>
					<ReactCodeInput
							name={`phone`}
							type={`number`}
							value={code}
							onChange={code => updateCode(code)}
							{...props}
					/>
					<Typography variant="body2" color="textSecondary" align="center">&nbsp;</Typography>
					<Typography variant="body2" color="textSecondary" align="center">&nbsp;</Typography>
					<Link href="#" onClick={()=>handleSendCode()}>
					<Typography variant="body2" color="error" align="center">No code? Re-send it via email</Typography>
					</Link>
					</div>
			)
	);
};

const mapStoreToProps = (state) => {
	return {
		code: state.auth.code,
		isLoading: state.auth.isLoading,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateCode: (code) => dispatch(addCodeDigit(code)),
		handleSendCode: () => dispatch(sendCodeEmail()),
	}
};

export default connect(mapStoreToProps, mapDispatchToProps)(CodeForm)


