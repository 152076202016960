const routesEng = {
	campaign: "Notifications",
	timesheet: "Timesheet",
	info: "Patient's information",
	schedule: "Schedule",
	vacation: "Vacation",
	changeAddress: "Change Address",
	availability: "Availability",
	nevvon: "Training",
	poc: "POC",
};

export default routesEng;
