import * as Api from "../api";
import {
  NOTIFICATION_CLOSE,
  SUCCESS_NOTIFICATION_RAISE,
  ERROR_NOTIFICATION_RAISE,
} from "./notification";
import {
  SELECT_LANGUAGE_END,
  SELECT_LANGUAGE_OK,
  SELECT_LANGUAGE_START,
  SET_POLICY_END,
  SET_POLICY_START,
  SETUP_USER,
} from "./user";
import { adjustUserData } from "../utils/uploadUtils";
import { Storage } from "@capacitor/storage";
import { Device } from "@capacitor/device";
/**
 * Constants
 */

export const CHECK_PHONE = "auth/CHECK_PHONE";
export const ADD_PHONE_DIGIT = "auth/ADD_PHONE_DIGIT";
export const SETUP_PHONE = "auth/SETUP_PHONE";
export const SEND_PHONE_OK = "auth/SEND_PHONE_NUMBER_OK";
export const SEND_PHONE_ERROR = "auth/SEND_PHONE_NUMBER_ERROR";
export const CHECK_CODE = "auth/CHECK_CODE";
export const CHECK_PIN = "auth/CHECK_PIN";
export const ADD_CODE_DIGIT = "auth/ADD_DIGIT";
export const CODE_OK = "auth/CODE_OK";
export const PIN_OK = "auth/PIN_OK";
export const POLICY_ACCEPTED = "auth/POLICY_ACCEPTED";
export const SET_TOKEN = "auth/SET_TOKEN";
export const SET_PIN = "auth/SET_PIN";
export const CODE_ERROR = "auth/CODE_ERROR";
export const PIN_ERROR = "auth/PIN_ERROR";
export const CHECK_TOKEN = "auth/CHECK_TOKEN";
export const TOKEN_OK = "auth/TOKEN_OK";
export const TOKEN_WRONG = "auth/TOKEN_WRONG";
export const LOGOUT = "auth/LOGOUT";
export const TRAINING_TAB = "auth/TRAINING_TAB";
export const CAMPAIGN_MESSAGE = "auth/CAMPAIGN_MESSAGE";
export const TS_TAB = "auth/TS_TAB";
export const POC_TAB = "auth/POC_TAB";
export const AVAIL_TAB = "auth/AVAIL_TAB";
export const QTN_TAB = "auth/QTN_TAB";
export const PATIENT_INFO_TAB = "auth/PATIENT_INFO_TAB";

export const STORED_PHONE_KEY = "user_phone_k";
export const STORED_PIN_KEY = "user_pin_k";
export const STORED_FCM_TOKEN_KEY = "user_fcm_token_k";

export const ADD_PIN_MODE_SET = "ADD_PIN_MODE_SET";
export const ADD_PIN_MODE_CHECK = "ADD_PIN_MODE_CHECK";
/**
 * Action Creators
 */

export function logoutAction() {
  return (dispatch) => {
    //localStorage.clear();
    localStorage.removeItem("token");
    dispatch({ type: LOGOUT });
  };
}

export function addCodeDigit(code) {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_CODE_DIGIT, code });
    if (code.length === 4) {
      try {
        dispatch({ type: CHECK_CODE });

        const phone = getState().auth.phone;
        const clientIP = getState().app.clientIP;
        const payload = await Api.sendCode(code, phone, clientIP);
        if (payload.ok) {
          payload.json().then((data) => {
            const token = data.token;
            const userData = adjustUserData(data);

            localStorage.setItem("token", token);
            dispatch({ type: CODE_OK, token });
            dispatch({ type: SETUP_USER, userData });

            if (userData.isLanguageSelected) {
              dispatch({
                type: SELECT_LANGUAGE_OK,
                language: userData.language,
              });
            }
            if (userData.policyAccepted) {
              dispatch({ type: POLICY_ACCEPTED });
            }
          });
        } else {
          payload.json().then((err) => {
            dispatch({ type: CODE_ERROR });
            dispatch({ type: ERROR_NOTIFICATION_RAISE, message: err.error });
          });
          setTimeout(() => {
            dispatch({ type: NOTIFICATION_CLOSE });
          }, 5000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
}

export async function strToSha1(str) {
  const buffer = new TextEncoder("utf-8").encode(str);
  const digest = await crypto.subtle.digest("SHA-1", buffer);

  // Convert digest to hex string
  const result = Array.from(new Uint8Array(digest))
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");

  return result;
}

export function addPinDigit(pin, pin_mode) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PIN, pin });
    if (pin.length === 4) {
      if (pin_mode === ADD_PIN_MODE_CHECK) {
        try {
          dispatch({ type: CHECK_PIN });

          //const phone = //getState().auth.phone;
          const _phone = await Storage.get({ key: STORED_PHONE_KEY });
          let phone = "";
          if (_phone) {
            if (_phone.value) {
              if (_phone.value.length === 10) {
                dispatch({ type: SETUP_PHONE, phone: _phone.value });
                phone = _phone.value;
              }
            }
          }
          const clientIP = getState().app.clientIP;
          const device_id = (await Device.getId()).uuid;
          const payload = await Api.checkPin(pin, phone, clientIP, device_id);
          //const payload = await Api.checkPin(strToSha1(pin), phone, clientIP, device_id);
          if (payload.ok) {
            payload.json().then((data) => {
              const token = data.token;
              const userData = adjustUserData(data);

              localStorage.setItem("token", token);
              dispatch({ type: PIN_OK, token });
              dispatch({ type: SETUP_USER, userData });

              if (userData.isLanguageSelected) {
                dispatch({
                  type: SELECT_LANGUAGE_OK,
                  language: userData.language,
                });
              }
              if (userData.policyAccepted) {
                dispatch({ type: POLICY_ACCEPTED });
              }
            });
          } else {
            payload.json().then((err) => {
              dispatch({ type: CODE_ERROR });
              dispatch({ type: ERROR_NOTIFICATION_RAISE, message: err.error });
            });
            setTimeout(() => {
              dispatch({ type: NOTIFICATION_CLOSE });
            }, 5000);
          }
        } catch (error) {
          console.error(error);
        }
      } else if (pin_mode === ADD_PIN_MODE_SET) {
        try {
          await Storage.set({ key: STORED_PIN_KEY, value: pin });
          const phone = getState().auth.phone;
          const token = getState().auth.token;
          const device_id = (await Device.getId()).uuid;
          //const payload = await Api.setPin(token ,strToSha1(pin), phone);
          const payload = await Api.setPin(token, pin, phone, device_id);
          //console.log("Api.setPin: " + pin);
          if (payload.ok) {
            payload.json().then((data) => {});
          } else {
            payload.json().then((err) => {
              dispatch({ type: PIN_ERROR });
              dispatch({ type: ERROR_NOTIFICATION_RAISE, message: err.error });
            });
            setTimeout(() => {
              dispatch({ type: NOTIFICATION_CLOSE });
            }, 5000);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
}

export function addPhoneDigit(phone) {
  return async (dispatch) => {
    if (phone.length === 10) {
      try {
        dispatch({ type: CHECK_PHONE });
        dispatch({ type: SETUP_PHONE, phone });
        await Storage.set({ key: STORED_PHONE_KEY, value: phone });
        const payload = await Api.sendPhoneNumber(phone);

        if (payload.ok) {
          dispatch({ type: SEND_PHONE_OK });
          /*dispatch({
            type: SUCCESS_NOTIFICATION_RAISE,
            message: "Confirmation code has been sent",
          });*/
          payload.json().then((mes) => {
            dispatch({ type: SUCCESS_NOTIFICATION_RAISE, message: mes.message });
          });
        } else {
          payload.json().then((err) => {
            dispatch({ type: SEND_PHONE_ERROR });
            dispatch({ type: ERROR_NOTIFICATION_RAISE, message: err.error });
          });
        }
        setTimeout(() => {
          dispatch({ type: NOTIFICATION_CLOSE });
        }, 5000);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      dispatch({ type: ADD_PHONE_DIGIT, payload: phone });
    }
  };
}

export function sendCodeEmail() {
  return async (dispatch) => {
    try {
      const _phone = await Storage.get({ key: STORED_PHONE_KEY });
      if (_phone) {
        if (_phone.value) {
          if (_phone.value.length === 10) {
            const phone = _phone.value;
            const payload = await Api.sendCodeEmail(phone);

            if (payload.ok) {
              dispatch({ type: SEND_PHONE_OK });
              dispatch({
                type: SUCCESS_NOTIFICATION_RAISE,
                message: "Email with a code has been sent",
              });
            } else {
              payload.json().then((err) => {
                dispatch({ type: SEND_PHONE_ERROR });
                dispatch({
                  type: ERROR_NOTIFICATION_RAISE,
                  message: err.error,
                });
              });
            }
            setTimeout(() => {
              dispatch({ type: NOTIFICATION_CLOSE });
            }, 5000);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function storeFCMToken(fcm_token) {
  return async (dispatch) => {
    try {
      await Storage.set({ key: STORED_FCM_TOKEN_KEY, value: fcm_token });
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function setupPhone(phone) {
  return async (dispatch) => {
    if (phone.length === 10) {
      try {
        dispatch({ type: SETUP_PHONE, phone });
        //await Storage.set({key: STORED_PHONE_KEY, value: phone});
      } catch (error) {
        console.log("error", error);
      }
    }
  };
}

export function resetPIN() {
  return async (dispatch) => {
    try {
      await Storage.remove({ key: STORED_PHONE_KEY });
      await Storage.remove({ key: STORED_PIN_KEY });
      logoutAction();
    } catch (error) {
      console.log("error", error);
    }
  };
}

/**
 * Reducer
 */

const initState = {
  isLoading: false,
  isAuthenticated: false,
  isPhoneSent: false,
  isCodeSent: false,
  isPinSent: false,
  isTrainingTab: false,
  isTSTab: false,
  isPOCTab: false,
  isAvailTab: false,
  isQTNTab: false,
  isPatientInfoTab: false,
  campaign_message: "",
  phone: "",
  code: "",
  token: localStorage.getItem("token"),
  pin: "",
};

export default function auth(state = initState, action) {
  switch (action.type) {
    case SET_POLICY_END:
    case SELECT_LANGUAGE_END:
    case CHECK_TOKEN:
      return {
        ...state,
        isLoading: false,
      };
    case TRAINING_TAB:
      return {
        ...state,
        isTrainingTab: true,
      };
    case TS_TAB:
      return {
        ...state,
        isTSTab: true,
      };
    case POC_TAB:
      return {
        ...state,
        isPOCTab: true,
      };
    case AVAIL_TAB:
      return {
        ...state,
        isAvailTab: true,
      };
    case QTN_TAB:
      return {
        ...state,
        isQTNTab: true,
      };
    case PATIENT_INFO_TAB:
      return {
        ...state,
        isPatientInfoTab: true,
      };
    case CAMPAIGN_MESSAGE:
      return {
        ...state,
        campaign_message: action.campaign_message,
      };
    case TOKEN_OK:
      return {
        ...state,
        isPhoneSent: true,
        isCodeSent: true,
      };
    case TOKEN_WRONG:
      return {
        ...state,
        isAuthenticated: false,
        phone: "",
        code: "",
        token: null,
      };
    case CODE_OK:
      return {
        ...state,
        token: action.token,
        isLoading: false,
        isPhoneSent: true,
        isPinSent: true,
        isCodeSent: true,
      };
    case PIN_OK:
      return {
        ...state,
        token: action.token,
        isLoading: false,
        isPinSent: true,
        isPhoneSent: true,
        isCodeSent: true,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_PIN:
      return {
        ...state,
        pin: action.pin,
      };
    case POLICY_ACCEPTED:
      return {
        ...state,
        isAuthenticated: true,
      };

    case SET_POLICY_START:
    case SELECT_LANGUAGE_START:
    case CHECK_CODE:
    case CHECK_PIN:
    case CHECK_PHONE:
      return {
        ...state,
        isLoading: true,
      };

    case SEND_PHONE_OK: {
      return {
        ...state,
        isPhoneSent: true,
        isLoading: false,
      };
    }
    case SETUP_PHONE:
      return {
        ...state,
        phone: action.phone,
      };

    case SEND_PHONE_ERROR: {
      return {
        ...state,
        isPhoneSent: false,
        isLoading: false,
        phone: "",
      };
    }
    case CODE_ERROR:
      return {
        ...state,
        isCodeSent: false,
        isLoading: false,
        code: "",
      };
    case PIN_ERROR:
      return {
        ...state,
        isPinSent: false,
        isLoading: false,
        pin: "",
      };

    case ADD_CODE_DIGIT:
      return {
        ...state,
        code: action.code,
      };

    case ADD_PHONE_DIGIT:
      return {
        ...state,
        phone: action.payload,
      };
    case LOGOUT:
      return {
        ...initState,
      };
    default:
      return state;
  }
}
