import { LOGOUT } from "./auth";
import { getImportantMessage } from "../api";
/**
 * Constants
 */
export const IMPORTANT_MESSAGE_LOADED =
  "importantmessage/IMPORTANT_MESSAGE_LOADED";
export const IMPORTANT_MESSAGE_CLOSE =
  "importantmessage/IMPORTANT_MESSAGE_CLOSE";

/**
 * Action Creators
 */

/**
 * Reducer
 */

const initState = {
  isMessageShow: {},
  isMessageLoaded: false,
};

export default function important_message(state = initState, action) {
  let form_name = action.form_name;
  let showmessage = action.showmessage;
  switch (action.type) {
    case IMPORTANT_MESSAGE_LOADED: {
      return {
        ...state,
        [form_name]: showmessage,
        //isMessageShow: Object.assign(getState().important_message.isMessageShow, new_obj),
        isMessageLoaded: true,
      };
    }
    case IMPORTANT_MESSAGE_CLOSE: {
      return {
        ...state,
        [form_name]: false,
        isMessageLoaded: true,
      };
    }
    case LOGOUT: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export function onCloseMessage(props) {
  const form_name = props;

  return async (dispatch, getState) => {
    try {
      dispatch({ type: IMPORTANT_MESSAGE_CLOSE, form_name: form_name });
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getImportantMessageOpened(props) {
  const form_name = props;
  return async (dispatch, getState) => {
    try {
      //dispatch({type: IMPORTANT_MESSAGE_CLOSE});
      const data = {
        form_name: form_name,
      };
      const token = getState().auth.token;
      const payload = await getImportantMessage(token, data);
      if (payload.ok) {
        payload.json().then((data) => {
          dispatch({
            type: IMPORTANT_MESSAGE_LOADED,
            showmessage: data.showmessage,
            form_name: form_name,
          });
          //console.log(data)
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}
