const timesheetEsp = {
	errMessage: "No se ha enviado su Timesheet. Por favor intente de nuevo mas tarde.",
	errMessage1: "El Formulario no se ha enviado. Por favor intente de nuevo mas tarde.",
	successMessage: "Formulario fue enviado",
	errSignature: "Por favor inserte firma",
	btnSend: "Enviar",
	btnNext: "Siguiente",
	btnBack: "Retroceder",
	btnFinish: "Completar",
	tab1: "Confirmar",
	tab2: "Editar",
	noTS: "No requiere Timesheets.",
	noEdit: "No hay visitas disponibles",
	tsHeader1: "Confirmacion de Timesheet",
	tsHeader2: "Complete los siguientes pasos",
	editHeader1: "Editor de visitas",
	editHeader2: "Por favor seleccione una opcion",
	signature: "Firma",
	_from: "De",
	to: "Para",
	more: "MAS",
	less: "MENOS",
	faqHeader1: "FAQ",
	faqHeader2: "Timesheets",
	faqQuestion1: "Por que debo someter mi Timesheet?",
	faqAnswer1: "En caso de que al menos una de las llamadas, no haya pasado por el sistema, la visita debe confirmar su Timesheet.",
	faqQuestion2: "Cuando tengo que enviar mi Timesheet y cual es la ultima fecha para hacerlo?",
	faqAnswer2: "Por Favor, envie timesheet lo antes posible. Lo mas tarde que se puede enviar es Martes.",
	faqQuestion3: "El paciente no desea firmala, que hacer en este caso?",
	faqAnswer3: "Por favor contactar su coordinadora.",
	preSend: "Todo fue completado - Su Timesheet esta lista para enviase.",
	taskError: "La hora de inicio de la visita no puede ser mбs de 15 minutos del horario. Si trabajo en este horario, por favor contactarse con su coordinador o complete 'Editar Formulario'",
	editSteps1: "Razon principal ",
	editSteps2: "Explicacion",
	editSend: "Todos los pasos cumplidos: la solicitud esta lista para enviarse.",
	mainEditReasons1: "El servicio no fue dado",
	mainEditReasons2: "Firma no pudo ser obtenida",
	mainEditReasons3: "Trabajo en otro horario",
	exp1: "Permiso por enfermedad",
	exp2: "Paciente nego los servicios",
	exp3: "Paciente esta hospitalizado",
	exp4: "Vacaciones",
	exp5: "Personal",
	exp6: "Paciente no quiso firma",
	exp7: "Ya no trabajo en este caso",
	exp9: "Según la solicitud del paciente",
	exp10: "Según solicitud de la agencia",
	message: "Mensaje",
	patient: "Paciente",
	date: "Fecha de visita",
	pocInfo: " tareas han sido completadas",
	tsSteps0: "Firma",
	tsSteps1: "Tiempo de la visita",
	tsSteps2: "Plan de cuidado",
	tsSteps3: " Firma",
	tsSteps4: "Su Firma",
	tsSteps5: "Confirmacionn",
	patSign1: "Con su firma confirma ",
	patSign2: " horas ",
	patSign3: " y ",
	patSign4: " minutos ",
	patSign5: " de servicios han sido recividos ",
	patSign6: " tareas fueron completadas.",
	carSign1: " de servicios han sido proveidas ",
	durInfo1: "La duracion de la visita es de ",
	durInfo2: " horas y ",
	durInfo3: " minutos",
	durInfo4: " horas",
	pocError1: "La cantidad de tareas no pueden ser menos de 7, pero solo ",
	pocError2: " han sido seleccionadas",
	pocError3: "Por lo menos una tarea debe ser completada por cada categoria, pero solo ",
	messEdit1: ". El tiempo que se realizу en esta visita es ",
	messEdit2: ". Por ",
	rejectedReason: "Razon rechazada: ",
	p911header1: "Noticia importante",
	p911header2: "Noticia",
	p911content1: "Llame al 911 de inmediato si el paciente tiene problemas para respirar, dolor en el pecho, nueva confusión, incapacidad para mantenerse despierto, labios o cara azulados.",
	p911content2: "¡Llame a la Agencia si el paciente tiene algún cambio en su estado de salud y / o comportamiento!",
	p911content3: "¡Llame al 911 en caso de caídas del paciente y siga el Protocolo posterior a la caída de la Agencia!",
	emergencyOption: "La hoja de tiempo es una opción de emergencia para documentar las tareas realizadas y se puede usar solo en situaciones extraordinarias, como un mal funcionamiento del teléfono. Es contra la ley usar una hoja de tiempo si el sistema de verificación electrónica de visitas (entrada / salida por teléfono) funciona normalmente."
};

export default timesheetEsp;
