import React from 'react';
// UI
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import Danger from "../../../components/Typography/Danger";
import Success from '../../../components/Typography/Success'
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
// moment
import MomentUtils from "@date-io/moment";
import moment from "moment";
// redux
import {connect} from "react-redux";
import {setVisitEnd, setVisitStart} from "../../../modules/timesheet";
// styles
import styles from '../styles'
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";

const defaultMaterialTheme = createMuiTheme({
	palette: {
		primary: purple,
	},
});

const VisitTime = ({mLoc, t, ...rest}) => {

	const {
		visits,
		ts,
		pid,
		h,
		m,
		stepError,
		stepInfo,
		setVisitStart,
		setVisitEnd,
	} = rest

	const visitStart = ts.vs;
	const visitEnd = ts.ve;
	//Now always disable
	//const disabledCallIn = !!visits[pid][ts.ss_int].ci;
	//const disabledCallOut = !!visits[pid][ts.ss_int].co
	const disabledCallIn = true;
	const disabledCallOut = true;

	const durationInfo = t.durInfo1 + h.toString() + t.durInfo2 + m.toString() + t.durInfo3
	const timeError = t.taskError

	const StateInfoBlock = () => {
		return (
				<div style={styles.infoBlock}>
					{stepError && <Danger>{timeError}</Danger>}
					{stepInfo && <Success>{durationInfo}</Success>}
				</div>
		)
	}

	return (
			<div>
				<MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={mLoc}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={6}>
							<MuiThemeProvider theme={defaultMaterialTheme}>
								<TimePicker
										margin="normal"
										id="FROM"
										label={t._from}
										disabled={disabledCallIn}
										minutesStep={5}
										value={moment.utc(visitStart)}
										onChange={date => setVisitStart(date)}
								/>
							</MuiThemeProvider>
						</GridItem>
						<GridItem xs={12} sm={12} md={6}>
							<MuiThemeProvider theme={defaultMaterialTheme}>
								<TimePicker
										margin="normal"
										id="TO"
										label={t.to}
										disabled={disabledCallOut}
										minutesStep={5}
										value={moment.utc(visitEnd)}
										onChange={date => setVisitEnd(date)}
								/>
							</MuiThemeProvider>
						</GridItem>
					</GridContainer>
					<StateInfoBlock />
				</MuiPickersUtilsProvider>

			</div>
	);
}

const mapStateToProps = state => {
	return {
		visits: state.timesheet.visits,
		ts: state.timesheet.ts,
		pid: state.timesheet.ts.pid,
		h: state.timesheet.ts.h,
		m: state.timesheet.ts.m,
		stepError: state.timesheet.ts.stepError,
		stepInfo: state.timesheet.ts.stepInfo,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setVisitStart: (date) => dispatch(setVisitStart(date)),
		setVisitEnd: (date) => dispatch(setVisitEnd(date))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitTime);
