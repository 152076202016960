import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/snackbarContentStyle.js";
import {connect} from "react-redux";
import {closeNotification} from "../../modules/notification";


const useStyles = makeStyles(styles);

function Snackbar(props) {
  const classes = useStyles();

  const { message, color, close, icon, place, open } = props;

  const handleClose = () => {
    props.closeNotification();
  };

  var action = [];
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={handleClose}
      >
        <Close className={classes.close} />
      </IconButton>
    ];
  }
  return (
    <Snack
      //style={{paddingTop: "env(safe-area-inset-top)"}}
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          place.indexOf("l") !== -1
            ? "left"
            : place.indexOf("c") !== -1
            ? "center"
            : "right"
      }}
      open={open}
      message={
        <div>
          {icon !== undefined ? <props.icon className={classes.icon} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message,
        }
      }}
    />
  );
}

Snackbar.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
  close: PropTypes.bool,
  icon: PropTypes.object,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool,
  closeNotification: PropTypes.func
};

const mapStoreToProps = (state) => {
  const { message, color, close, icon, place, open } = state.notification;
  return {
    message,
    color,
    close,
    icon,
    place,
    open
  }
};
const mapDispatchToProps = (dispatch) => {
	return {
		closeNotification: () => dispatch(closeNotification())
	}
};
export default connect(mapStoreToProps, mapDispatchToProps)(Snackbar)
