import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

	container: {
		display: "flex",
		flex: "1",
		flexDirection: "column",
	},
	policyItemContainer: {
		display: "flex",
		flexDirection: "row",
	},
	checkBoxContainer: {
		display: "flex",
		flex: "1",
		alignItems: "center",
		justifyContent: "center",
	},
	textContainer: {
		display: "flex",
		flex: "4",
	},
	policyText: {
		fontWeight: "500",
		fontSize: "0.8em"
	}
}));

export default useStyles;
