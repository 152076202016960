import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const BrooklynSvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 512 512`} {...props}>
				<path d="M512,224.373v-30c-23.73,0-45.725-11.809-58.834-31.589l-10.956-16.53v-4.82v-15v-12.282h-30v12.282H399v-12.282h-30
			v12.282h-13.21v-12.282h-30v12.282v15v4.82l-10.956,16.53c-13.109,19.78-35.104,31.589-58.834,31.589
			c-23.73,0-45.725-11.809-58.834-31.589l-10.956-16.53v-4.82v-15v-12.282h-30v12.282H143v-12.282h-30v12.282H99.79v-12.282h-30
			v12.282v15v4.82l-10.956,16.53C45.725,182.564,23.73,194.373,0,194.373v30c26.382,0,51.257-10.244,69.79-28.131v58.706H0v30h69.79
			v38.864H51.602v74.035h152.797v-74.035H186.21v-38.864h139.58v38.864h-18.188v74.035h152.797v-74.035H442.21v-38.864H512v-30
			h-69.79v-58.706C460.743,214.131,485.618,224.373,512,224.373z M99.79,156.436h56.42v98.514H143v-80.004h-30v80.004H99.79V156.436
			z M99.79,284.949h56.42v38.864H99.79V284.949z M174.399,367.848L174.399,367.848H81.602v-14.035h92.797V367.848z M325.79,254.949
			H186.21v-58.706c18.533,17.887,43.408,28.131,69.79,28.131c26.382,0,51.257-10.244,69.79-28.131V254.949z M355.79,156.434h56.42
			v98.514H399v-80.004h-30v80.004h-13.21V156.434z M355.79,284.949h56.42v38.864h-56.42V284.949z M430.398,367.848h-92.797v-14.035
			h92.797V367.848z"/>
			</SvgIcon>
</div>
	);
};

export default BrooklynSvg;
