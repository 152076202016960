import React from 'react';
import {withStyles} from "@material-ui/core/styles";
// redux
import LinearProgress from "@material-ui/core/LinearProgress";
import LogoAnimated from '../components/LogoAnimated/LogoAnimated'

const BorderLinearProgress = withStyles(() => ({
	root: {
		height: 5,
		borderRadius: 2,
	},
	colorPrimary: {
		backgroundColor: '#fff',
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#FF0000',
	},
}))(LinearProgress);

const LogoLayout = () => {

	const [completed, setCompleted] = React.useState(0);
	const [buffer, setBuffer] = React.useState(10);

	const progress = React.useRef(() => {
	});
	React.useEffect(() => {
		progress.current = () => {
			if (completed > 100) {
				setCompleted(0);
				setBuffer(10);
			} else {
				const diff = Math.random() * 5;
				const diff2 = Math.random() * 5;
				setCompleted(completed + diff);
				setBuffer(completed + diff + diff2);
			}
		};
	});

	React.useEffect(() => {
		function tick() {
			progress.current();
		}

		const timer = setInterval(tick, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const styles = {
		layoutContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "left",
			textAlign: "center",
			minHeight: "100vh",
			padding: "0 30px"
		},
		progressBarContainer: {
			margin: "auto",
			justifyContent: "center",
			alignItems: "center",
			maxWidth: "300px",
			width: "calc(100% * 1 / 2)",
			marginTop: "20px",
		}
	}

	return (
			<div style={styles.layoutContainer}>
				<div style={{position: 'relative'}}>
					<LogoAnimated />
					<div style={styles.progressBarContainer}>

						<BorderLinearProgress
								variant="determinate"
								value={completed}
								valueBuffer={buffer}
						/>
					</div>
				</div>
			</div>

	)
};

export default LogoLayout
