import DoneAllRounded from "@material-ui/icons/DoneAllRounded";
import ErrorRounded from "@material-ui/icons/ErrorRounded";
import { Toast } from "@capacitor/toast";
import { Capacitor } from "@capacitor/core";

/**
 * Constants
 */

export const ERROR_NOTIFICATION_RAISE = "notify/ERROR_NOTIFICATION_RAISE";
export const SUCCESS_NOTIFICATION_RAISE = "notify/SUCCESS_NOTIFICATION_RAISE";
export const NOTIFICATION_CLOSE = "notify/CLOSE";

/**
 * Action Creators
 */

export const closeNotification = () => {
  return (dispatch) => {
    dispatch({ type: NOTIFICATION_CLOSE });
  };
};

/**
 * Reducer
 */

const initState = {
  message: "",
  color: "success",
  close: true,
  open: false,
  icon: DoneAllRounded,
  place: "tc",
};

const showToast = async (msg) => {
  //console.log("Toast.show: ", msg);
  if (Capacitor.isNativePlatform()) {
    await Toast.show({
      text: msg,
    });
  }
};

export default function notification(state = initState, action) {
  switch (action.type) {
    case ERROR_NOTIFICATION_RAISE:
      showToast(action.message);
      return {
        ...state,
        open: true,
        close: false,
        icon: ErrorRounded,
        color: "danger",
        message: action.message,
      };
    case SUCCESS_NOTIFICATION_RAISE:
      showToast(action.message);
      return {
        ...state,
        open: true,
        close: false,
        icon: DoneAllRounded,
        color: "success",
        message: action.message,
      };
    case NOTIFICATION_CLOSE:
      return {
        ...state,
        message: "",
        icon: ErrorRounded,
        open: false,
        close: true,
      };
    default:
      return state;
  }
}
