import React from "react";
// UI
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomRadio from "../../../components/CustomRadio/CustomRadio";
import FormControl from "@material-ui/core/FormControl";
// redux
import { handleMainReasonChange } from "../../../modules/editVisit";
import { connect } from "react-redux";
// style
import styles from "../styles";
import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";

function Reason(props) {
  const { mainReasonChange, mainEditReasons, currentReasonId } = props;

  function handleChange(event) {
    const reason = event.target.value;
    const reasonId = mainEditReasons.indexOf(reason);
    mainReasonChange(reason, reasonId);
  }

  return (
    <FormControl component="fieldset" style={styles.formControl}>
      <RadioGroup aria-label="main" name="main" value={"reasons"}>
        {mainEditReasons.map((reason, reasonId) => {
          const checked = reasonId === currentReasonId;
          return (
            <FormControlLabel
              style={checked ? { color: grayColor[7] } : {}}
              checked={checked}
              key={reasonId}
              value={reason}
              control={<CustomRadio />}
              label={reason}
              onChange={(event) => handleChange(event)}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

const mapStateToProps = (state) => {
  return {
    currentReasonId: state.editVisit.reasonId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mainReasonChange: (reason, reasonId) =>
      dispatch(handleMainReasonChange(reason, reasonId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reason);
