import {withStyles} from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import React from "react";
import {primaryColor} from "assets/jss/material-dashboard-react.js";

const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: primaryColor[0],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default CustomRadio
