import React from "react";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Collapse from "@material-ui/core/Collapse";
// core components
//import GridContainer from "../../components/Grid/GridContainer";
//import GridItem from "../../components/Grid/GridItem";
//import Card from "../../components/Card/Card.js";
//import CardHeader from "../../components/Card/CardHeader";
//import FaqButton from "../../components/FaqButton";
import Button from "../../components/CustomButtons/Button";
//import CardBody from "../../components/Card/CardBody";
//import CustomTabs from "../../components/CustomTabs/CustomTabs";
//import FaqBlock from "../../components/FaqBlock";
// icons
//import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
//import Room from "@material-ui/icons/Room";
//import Schedule from "@material-ui/icons/Schedule";
//import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
// tabs
import GeneralAvail from "./General";
import AvailLocation from "./Location";
import ScheduleOfAvail from "./ScheduleOfAvail";
//import Done from "./Done";
// reducer functions
import { handleNextStep, setStepTab } from "../../modules/availability";
// language
import availEng from "../../assets/languages/eng/availEng";
import availEsp from "../../assets/languages/esp/availEsp";
import availRus from "../../assets/languages/rus/availRus";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const SummaryDialog = (props) => {
  const { //step, is_cdpap, 
    language } = props;

  const classes = useStyles();

  let t = [null, availEng, availEsp, availRus][language];

  const [iwidth, setiWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
      setiWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  let isMobile = (iwidth <= 959);

  // collapse
  //const [checked, setChecked] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(true);
  //const content = React.createRef();
  /**
  let aTabs = [
    {
      tabName: t.tab_1,
      tabIcon: PermContactCalendarIcon,
      tabContent: <GeneralAvail />,
    },
    {
      tabName: t.tab_2,
      tabIcon: Room,
      tabContent: <AvailLocation />,
    },
    {
      tabName: t.tab_3,
      tabIcon: Schedule,
      tabContent: (
        <div id="tour_wizard_step_7">
          <ScheduleOfAvail />
        </div>
      ),
    },
    {
      tabName: t.btn_5,
      tabIcon: DoneOutlineIcon,
      tabContent: <Done />,
    },
  ];*/

  return (
    <div>
      <Dialog
        fullScreen={isMobile}
        disableBackdropClick
        disableEscapeKeyDown
        open={dialogOpen}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ margin: "0 auto", padding: 0 }}
        >
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h4 style={{ margin: "auto", padding: "15px" }}>
              <strong>{t.title}</strong>
            </h4>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.allcenter}>{t.summary_title}</div>
          </DialogContentText>
          <Link
            href="#"
            onClick={() => {
              props.setTab(1);
            }}
          >
            Edit
          </Link>
          <div disabled={true} style={{ pointerEvents: "none" }}>
            <GeneralAvail as_dialog />
          </div>
          <Link
            href="#"
            onClick={() => {
              props.setTab(2);
            }}
          >
            Edit
          </Link>
          <div disabled={true} style={{ pointerEvents: "none" }}>
            <AvailLocation as_dialog />
          </div>
          <Link
            href="#"
            onClick={() => {
              props.setTab(3);
            }}
          >
            Edit
          </Link>
          <div disabled={true} style={{ pointerEvents: "none" }}>
            <ScheduleOfAvail as_dialog />
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ margin: "auto" }}>
            <Button
              onClick={() => {
                setDialogOpen(false);
                props.setTab(1);
              }}
              color="white"
              type={`button`}
            >
              {t.btn_6}
            </Button>
            <Button
              onClick={() => {
                setDialogOpen(false);
                props.nextStep(props, true);
              }}
              color="success"
              type={`button`}
            >
              {t.summary_ok}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/**<GridContainer>
        <GridItem xs={12} sm={12} md={6} id="AvailStepScroller">
          <CustomTabs
            handleTabChange={handleTabChange}
            value={step - 1}
            headerColor={`primary`}
            tabs={aTabs.slice(0, step)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}></GridItem>
      </GridContainer>*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { language } = state.user;
  const {
    //step,
    avail,
    //is_cdpap,
    caseType,
    loc,
    minDur,
    allergy,
    gender,
    comment,
    sch,
  } = state.availability;
  return {
    language,
    //step,
    avail,
    //is_cdpap,
    caseType,
    loc,
    minDur,
    allergy,
    gender,
    comment,
    sch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTab: (tab) => dispatch(setStepTab(tab)),
    nextStep: (props, sendToBackend) => dispatch(handleNextStep(props, sendToBackend)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryDialog);
