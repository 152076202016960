import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { makeStyles } from "@material-ui/core/styles";
import EditStepperButtons from "./EditStepperButtons";
import { connect } from "react-redux";
import { handleEditBack } from "../../modules/editVisit";

import Reason from "./EditSteps/Reason";
import Explanation from "./EditSteps/Explanation";

import styles from "./styles";
import ConfirmEdit from "./EditSteps/ConfirmEdit";

const useStyles = makeStyles(styles);

function EditedStepper(props) {
  const { t, activeStep } = props;

  const classesStepped = useStyles();
  // const editSteps = getEditSteps();
  const editSteps = [t.editSteps1, t.editSteps2, t.tsSteps5];
  const mainEditReasons = [
    t.mainEditReasons1,
    t.mainEditReasons2,
    t.mainEditReasons3,
  ];
  const explanations = [
    [t.exp1, t.exp2, t.exp3, t.exp4, t.exp5],
    [t.exp6, t.exp7],
    [t.exp9, t.exp10],
  ];

  function getEditStepContent(editStep) {
    switch (editStep) {
      case 0:
        return <Reason mainEditReasons={mainEditReasons} />;

      case 1:
        return <Explanation explanations={explanations} />;
      case 2:
        return <ConfirmEdit t={t} />;
      default:
        return "Unknown step";
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {editSteps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classesStepped.icon,
                    active: classesStepped.activeIcon,
                    completed: classesStepped.completedIcon,
                  },
                }}
              >
                {label}
              </StepLabel>
              <StepContent>
                {getEditStepContent(index)}
                <div>
                  {activeStep !== editSteps.length - 1 ? (
                    <EditStepperButtons steps={editSteps} {...props} />
                  ) : (
                    <div />
                  )}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    visitDate: state.editVisit.visitDate,
    patientName: state.editVisit.patientName,
    reason: state.editVisit.reason,
    // reasonId: state.editVisit.reasonId,
    explanation: state.editVisit.explanation,
    // reasonView: state.editVisit.viewSelected.reasonView,
    // explanationView: state.editVisit.viewSelected.explanationView,
    // confirmationView: state.editVisit.viewSelected.confirmationView,

    activeStep: state.editVisit.activeStep,
    // isNextDisabled: state.editVisit.isNextDisabled
  };

  // const {activeStep, ed, isNextDisabled} = state.timesheet
  // const {reason, exp, ve, vs, ci, co} = state.timesheet.ed
  // return {activeStep, ed, reason, exp, ve, vs, ci, co, isNextDisabled}
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onEditSend: (ed, visits, edMes1, edMes2, sucMes, errMes) => dispatch(handleEditSend(ed, visits, edMes1, edMes2, sucMes, errMes)),
    editBack: () => dispatch(handleEditBack()),
  };

  // changeEditStep: (isNext, activeStep) => dispatch(handleEditStep(isNext, activeStep)),
  // mainReasonChange: (reason) => dispatch(handleMainReasonChange(reason)),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditedStepper);
