import Button from "../../components/CustomButtons/Button";
import React from "react";
import { handleTimesheetStep } from "../../modules/timesheet";
import { connect } from "react-redux";

const StepperButtons = (props) => {
  const { activeStep, t, steps, changeStep, isNextDisabled } = props;

  return (
    <>
      <Button onClick={() => changeStep(false, activeStep)}>{t.btnBack}</Button>
      <Button
        disabled={isNextDisabled}
        variant="contained"
        color="primary"
        onClick={() => changeStep(true, activeStep)}
      >
        {activeStep === steps.length - 1 ? t.btnFinish : t.btnNext}
      </Button>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeStep: (isNext, activeStep) =>
      dispatch(handleTimesheetStep(isNext, activeStep)),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(StepperButtons);
