import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const TFHours = (props) => {
	return (
		<div style={{paddingRight: "10px"}}>
			<SvgIcon
					fontSize={`large`}
					viewBox={`0 0 512.001 512.001`}
					htmlColor={`#d81b60`}
					{...props}>
				<path d="M400.147,131.245l87.687,6.388l6.388-87.686l-30.05-2.189l-2.513,34.499C413.521,30.176,345.737,0,273.78,0
		c-141.16,0-256,114.842-256,256s114.842,256,256.001,256c85.357,0,164.845-42.459,212.629-113.579l-25.01-16.803
		c-42.176,62.775-112.314,100.252-187.619,100.252C149.235,481.871,47.91,380.545,47.91,256S149.234,30.13,273.78,30.13
		c64.05,0,124.351,27.126,166.9,73.857l-38.344-2.793L400.147,131.245z"/>
				<path d="M148.036,284.294c0-9.686,9.687-15.972,23.45-22.94c15.802-7.986,26.847-17.162,26.847-36.874
		c0-23.28-14.954-37.723-41.801-37.723c-23.11,0-40.272,11.045-47.749,19.541l16.823,18.013
		c6.116-6.287,16.313-13.424,27.188-13.424c8.835,0,14.613,4.758,14.613,13.934c0,14.273-14.104,20.39-28.547,26.847
		c-24.469,11.045-30.417,25.659-30.417,57.095h90.061v-24.469H148.036z"/>
				<path d="M285.504,258.975v-71.029h-18.691l-56.076,71.029v24.299h47.579v25.319h27.188v-25.319h14.954v-24.299H285.504z
		 M261.375,258.974h-23.62l23.62-30.247V258.974z"/>
				<path d="M374.034,217.852c-13.084,0-24.469,6.117-30.927,16.653v-49.788h-27.188v124.046h27.188v-51.147
		c3.4-9.516,12.406-15.803,18.862-15.803c7.817,0,12.914,5.268,12.914,16.823v50.128h27.189v-57.095
		C402.071,236.205,397.992,217.852,374.034,217.852z"/>
			</SvgIcon>
		</div>


	);
};

export default TFHours;
