import React from "react";
// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
// core components
import NewTimesheetRow from "./RowTypes/NewTimesheetRow";
import RejectedTimesheetRow from "./RowTypes/RejectedTimesheetRow";
// style
import styles from "./styles";
// redux
import { connect } from "react-redux";
import { selectTimesheet } from "../../modules/timesheet";
// moment
import moment from "moment";
// constants
import { DATE_STRING_FORMAT } from "../../constants";

const TimesheetMainList = (props) => {
  const { visits, t, pn, confirmTs } = props;

  const handleRowClick = (visit) => {
    if ([2, 3].includes(visit.st_vis)) {
      confirmTs(visit);
    }
  };

  function visitsArrayFunction(allVisits) {
    let arr = [];
    Object.entries(allVisits).forEach(([_, visData]) => {
      if (visData.st_vis !== 1) {
        arr.push(visData);
      }
    });

    arr.sort((a, b) =>
      a.st_vis > b.st_vis
        ? -1
        : a.st_vis === b.st_vis
        ? a.hasOwnProperty("vd") && b.hasOwnProperty("vd") && a.vd > b.vd
          ? 1
          : -1
        : 1
    );
    return arr;
  }

  return Object.entries(visits).map(([pid, allVisits]) => {
    let visitsArray = visitsArrayFunction(allVisits);
    if (visitsArray.length === 0) {
      return '';
    }
    const patientName = pn[pid];
    return (
      <FormControl
        key={Math.random()}
        component="fieldset"
        fullWidth={true}
        style={styles.formControl}
      >
        <FormLabel component="legend" style={styles.formControlLabel}>
          {patientName}
        </FormLabel>
        {visitsArray.map((visit, index) => {
          const rejectedVisit = visit.st_vis === 3;
          let rejectionComment = "";
          if (visit.hasOwnProperty("comm")) {
            if (visit.comm){
              rejectionComment = `${t.rejectedReason} ${visit.comm}`;
            }
          }
          let visitDate = "";
          let oldVisit = false;
          if (visit.hasOwnProperty("vd")) {
            visitDate = moment(visit.vd).format(DATE_STRING_FORMAT);
            //if visit more that a month ago
            if (moment().subtract(1, "months") > moment(visit.vd)) {
              oldVisit = true;
            }
          }

          return (
            <div key={index} style={styles.tsRow}>
              {!rejectedVisit ? (
                <NewTimesheetRow
                  visit={visit}
                  visitDate={visitDate}
                  oldVisit={oldVisit}
                  handleRowClick={handleRowClick}
                  reasonText={rejectionComment}
                />
              ) : (
                <RejectedTimesheetRow
                  visit={visit}
                  visitDate={visitDate}
                  handleRowClick={handleRowClick}
                  reasonText={rejectionComment}
                />
              )}
            </div>
          );
        })}
      </FormControl>
    );
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmTs: (visit) => dispatch(selectTimesheet(visit)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(TimesheetMainList);
