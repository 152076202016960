
import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const ThursdaySvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 32.75 32.75`} {...props}>
				<path d="M29.375,1.25h-1.123c0.029-0.093,0.059-0.186,0.059-0.289c0-0.53-0.432-0.961-0.963-0.961s-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.059,0.289h-3.68c0.029-0.093,0.059-0.186,0.059-0.289C22.823,0.431,22.393,0,21.861,0
			C21.331,0,20.9,0.431,20.9,0.961c0,0.103,0.029,0.196,0.059,0.289h-3.682c0.029-0.093,0.059-0.186,0.059-0.289
			c0-0.53-0.43-0.961-0.961-0.961c-0.531,0-0.961,0.431-0.961,0.961c0,0.103,0.028,0.196,0.058,0.289h-3.681
			c0.029-0.093,0.059-0.186,0.059-0.289C11.85,0.431,11.419,0,10.889,0c-0.531,0-0.962,0.431-0.962,0.961
			c0,0.103,0.028,0.196,0.058,0.289h-3.68c0.03-0.093,0.059-0.186,0.059-0.289C6.364,0.43,5.934,0,5.403,0
			C4.872,0,4.441,0.431,4.441,0.961c0,0.103,0.028,0.196,0.058,0.289H3.375c-1.518,0-2.75,1.233-2.75,2.75v26
			c0,1.518,1.232,2.75,2.75,2.75H26.27l5.855-5.855V4C32.125,2.483,30.893,1.25,29.375,1.25z M30.625,26.273l-0.311,0.311h-2.355
			c-1.102,0-2,0.9-2,2v2.355l-0.311,0.311H3.375c-0.689,0-1.25-0.561-1.25-1.25V9h28.5V26.273z"/>
		<polygon points="6.465,23.812 9.061,23.812 9.061,17.97 11.393,17.97 11.393,15.891 4.132,15.891 4.132,17.97 6.465,17.97 		"/>
		<polygon points="14.538,20.743 16.992,20.743 16.992,23.812 19.588,23.812 19.588,15.891 16.992,15.891 16.992,18.564
			14.538,18.564 14.538,15.891 11.941,15.891 11.941,23.812 14.538,23.812 		"/>
		<path d="M24.384,24.021c2.63,0,3.806-1.111,3.806-3.652v-4.478h-2.596v4.741c0,0.858-0.275,1.453-1.21,1.453
			c-1.067,0-1.222-0.671-1.222-1.453v-4.741h-2.596v4.466C20.566,23.086,21.93,24.021,24.384,24.021z"/>
			</SvgIcon>
</div>
	);
};

export default ThursdaySvg;
