
import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const SaturdaySvg = (props) => {
	return (
			<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 32.75 32.75`} {...props}>
				<path d="M29.375,1.25h-1.123c0.029-0.093,0.059-0.186,0.059-0.289c0-0.53-0.432-0.961-0.963-0.961s-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.059,0.289h-3.68c0.029-0.093,0.059-0.186,0.059-0.289C22.823,0.431,22.393,0,21.861,0
			C21.331,0,20.9,0.431,20.9,0.961c0,0.103,0.029,0.196,0.059,0.289h-3.682c0.029-0.093,0.059-0.186,0.059-0.289
			c0-0.53-0.43-0.961-0.961-0.961c-0.531,0-0.961,0.431-0.961,0.961c0,0.103,0.028,0.196,0.058,0.289h-3.681
			c0.029-0.093,0.059-0.186,0.059-0.289C11.85,0.431,11.419,0,10.889,0c-0.531,0-0.962,0.431-0.962,0.961
			c0,0.103,0.028,0.196,0.058,0.289h-3.68c0.03-0.093,0.059-0.186,0.059-0.289C6.364,0.43,5.934,0,5.403,0
			C4.872,0,4.441,0.431,4.441,0.961c0,0.103,0.028,0.196,0.058,0.289H3.375c-1.518,0-2.75,1.233-2.75,2.75v26
			c0,1.518,1.232,2.75,2.75,2.75H26.27l5.855-5.855V4C32.125,2.483,30.893,1.25,29.375,1.25z M30.625,26.273l-0.311,0.311h-2.355
			c-1.102,0-2,0.9-2,2v2.355l-0.311,0.311H3.375c-0.689,0-1.25-0.561-1.25-1.25V9h28.5V26.273z"/>
		<path d="M9.226,22.195c-0.253,0-0.528-0.066-0.737-0.198c-0.22-0.132-0.374-0.341-0.429-0.627H5.574
			c0.055,1.43,1.419,2.651,3.509,2.651c2.179,0,3.708-0.892,3.708-2.729c0-2.882-4.555-2.146-4.555-3.333
			c0-0.286,0.253-0.451,0.825-0.451c0.253,0,0.484,0.044,0.671,0.143c0.187,0.1,0.33,0.265,0.374,0.541h2.465
			c-0.188-1.386-1.188-2.509-3.389-2.509c-1.98,0-3.433,0.847-3.433,2.531c0,3.025,4.555,2.2,4.555,3.398
			C10.304,21.986,9.875,22.195,9.226,22.195z"/>
		<path d="M15.264,23.812l0.297-1.045h2.585l0.31,1.045h2.584l-2.838-7.921h-2.641l-2.838,7.921H15.264z M16.859,18.277l0.836,2.839
			h-1.639L16.859,18.277z"/>
		<polygon points="22.271,23.812 24.867,23.812 24.867,17.97 27.199,17.97 27.199,15.891 19.938,15.891 19.938,17.97 22.271,17.97
					"/>
			</SvgIcon>
			</div>

	);
};

export default SaturdaySvg;
