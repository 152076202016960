import React from "react";
// components
//import GridContainer from "../../components/Grid/GridContainer.js";
import Grid from "@material-ui/core/Grid";
import CampaignCard from "../../components/CampaignCard";
import NotificationCard from "../../components/NotificationCard";
import dashEng from "../../assets/languages/eng/dashEng";
import dashEsp from "../../assets/languages/esp/dashEsp";
import dashRus from "../../assets/languages/rus/dashRus";
// redux
import { connect } from "react-redux";
import { CAMPAIGN_MESSAGE_LIST } from "../../constants";
import { getCampaignsChunk, onCampaignsTexts } from "../../modules/campaigns";
import PinFormDialog from "../../components/PinFormDialog/PinFormDialog";
import { getNotificationsChunk } from "../../modules/appnotifications";
import { Typography } from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
//import CardFooter from "../../components/Card/CardFooter";

const Dashboard = (props) => {
  const {
    campaigns,
    campaign_message,
    campaign_texts,
    //isCampaignsTextsLoaded,
    onCampaignsTextsRet,
    appnotifications,
    language,
    isNotificationsLoaded,
    onNotificationsRet,
    isCampaignsLoaded,
    onCampaignsRet,
  } = props;

  let t = [null, dashEng, dashEsp, dashRus][language];

  React.useEffect(() => {
    if (!isNotificationsLoaded) {
      onNotificationsRet();
    }
  }, [isNotificationsLoaded, onNotificationsRet]);

  React.useEffect(() => {
    if (!isCampaignsLoaded) {
      onCampaignsRet();
    }
  }, [isCampaignsLoaded,onCampaignsRet]);

  React.useEffect(() => {
    if (CAMPAIGN_MESSAGE_LIST.includes(campaign_message)) {
      onCampaignsTextsRet();
    }
  }, [campaign_message,onCampaignsTextsRet]);

  const [iwidth, setiWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setiWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = iwidth <= 959;

  const topmargin = isMobile ? "auto" : "100px";

  const NoMessages = () => {
    return (
      <Card profile >
        <CardHeader color="success">
          <Typography variant="h6">{t.noMessage}</Typography>
        </CardHeader>
        <CardBody profile>
          <Typography variant="subtitle1">{t.noMessage2}</Typography>
        </CardBody>
      </Card>
    );
  };

  return (
    <div style={{ marginTop: { topmargin } }}>
      <PinFormDialog />

      <Grid container direction={`column`}>
        {campaign_texts &&
          campaign_texts.map((campaign) => {
            return <div dangerouslySetInnerHTML={{ __html: campaign }} />;
          })}
        {campaigns &&
          campaigns.map((campaign) => {
            return <CampaignCard campaign={campaign} key={Math.random()} />;
          })}
        {appnotifications &&
          appnotifications.map((appnotification) => {
            return (
              <NotificationCard
                notification={appnotification}
                key={Math.random()}
              />
            );
          })}
        {appnotifications.length<1 && <NoMessages />}
      </Grid>
    </div>
  );
};

const mapStoreToProps = (state) => {
  const { language } = state.user;
  const {
    campaigns,
    campaign_texts,
    //isCampaignsTextsLoaded,
    isCampaignsLoaded,
  } = state.campaign;
  const { appnotifications, isNotificationsLoaded } = state.appnotifications;
  const { campaign_message } = state.auth;
  return {
    campaigns,
    campaign_message,
    campaign_texts,
    //isCampaignsTextsLoaded,
    language,
    appnotifications,
    isNotificationsLoaded,
    isCampaignsLoaded,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onCampaignsRet: () => dispatch(getCampaignsChunk()),
    onCampaignsTextsRet: () => dispatch(onCampaignsTexts()),
    onNotificationsRet: () => dispatch(getNotificationsChunk()),
  };
};

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(Dashboard);
