import { LOGOUT } from "./auth";
import { getSchedule } from "../api";
import { adjustScheduleTime } from "../utils/uploadUtils";

/**
 * Constants
 */
export const SCHEDULE_LOADED = "schedule/SCHEDULE_LOADED";
export const SCHEDULE_ERROR = "schedule/GET_SCHEDULE_ERROR";

/**
 * Action Creators
 */

/**
 * Reducer
 * **/

const initState = {
  isScheduleLoaded: false,
  schedule: [],
};

export default function schedule(state = initState, action) {
  switch (action.type) {
    case SCHEDULE_LOADED: {
      return {
        ...state,
        schedule: action.schedule,
        isScheduleLoaded: true,
      };
    }
    case LOGOUT:
    case SCHEDULE_ERROR: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export function getScheduleChunk() {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth.token;
      const payload = await getSchedule(token);
      if (payload.ok) {
        payload.json().then((data) => {
          console.log('schedule before adjust',data.schedule);
          const schedule = adjustScheduleTime(data.schedule);
          console.log('schedule after adjust',schedule);
          dispatch({ type: SCHEDULE_LOADED, schedule });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}
