import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";

const styles = {
	formLabel: {
		color: "black",
	},
	formLabelInactive: {},
	svgStyle: {
		marginRight: "20px"
	}
}
const useStyles = makeStyles(styles);

const AvailFormControlLabel = (props) => {
	const {isChecked, controlLabel, control} = props
	const classes = useStyles();

	return (
			<div>
				<FormControlLabel
						className={isChecked ? classes.formLabel : classes.formLabelInactive}
						control={control}
						label={controlLabel}
				/>
			</div>
	);
};

export default AvailFormControlLabel;
