import React, { useState, createRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../Navbars/Navbar.js";
import Footer from "../Footer/Footer.js";
import Sidebar from "../Sidebar/Sidebar.js";

import dashboardRoutes from "../../routes.js";

import styles from "../../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import { connect } from "react-redux";
import routesEng from "../../assets/languages/eng/routesEng";
import routesEsp from "../../assets/languages/esp/routesEsp";
import routesRus from "../../assets/languages/rus/routesRus";
import bgImage from "../../assets/img/sidebar-2.png";
import logo from "../../assets/img/Logo_All-Heart_wite.png";
import {
  CAMPAIGN_MESSAGE_LIST,
  FEATURE_ENABLE_BITRIX_CHAT,
  FEATURE_ENABLE_BITRIX_CHAT_AS_SCRIPT,
  FEATURE_ENABLE_BOTTOMBAR,
} from "../../constants";
import AppFeedbackDialog from "../../components/AppFeedbackDialog/AppFeedbackDialog.js";
//import { BottomNavigation } from "@material-ui/core";
import Bottombar from "../../components/Sidebar/Bottombar.js";
//import NativeNotifications from "../../components/NativeNotifications/NativeNotifications";
import PolicyForm from "../../components/PolicyForm";
import LoginLangSelect from "../../components/LanguageSelect/LoginLangSelect";
import LoginCard from "../../components/LoginCard";
import { Capacitor } from "@capacitor/core";
import md5 from "md5";
import Language from "@material-ui/icons/Language";
import Gavel from "@material-ui/icons/Gavel";

import Dialog from "../../components/DialogMigrate/DialogMigrate";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(styles);

function Main({ ...rest }) {
  const {
    language,
    isTrainingTab,
    campaign_message,
    isTSTab,
    isPOCTab,
    isAvailTab,
    isQTNTab,
    isPatientInfoTab,
    is_cdpap,
    fullName,
    username,
    phone,
    isLanguageSelected,
    policyAccepted,
  } = rest;

  const [iwidth, setiWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setiWidth(window.innerWidth);
  }
  const [onBitrixLiveChat, setonBitrixLiveChat] = React.useState(false);
  React.useEffect(() => {
    if (FEATURE_ENABLE_BITRIX_CHAT_AS_SCRIPT && FEATURE_ENABLE_BITRIX_CHAT) {
      if (!onBitrixLiveChat && phone && fullName && username) {
        window.addEventListener("onBitrixLiveChat", function(event) {
          var user = {
            hash: md5({ phone }),
            name: { fullName },
            email: { username },
          };

          var widget = event.detail.widget;

          widget.setUserRegisterData(user);
          widget.open();
        });
        setonBitrixLiveChat(true);
      }
    }
  }, [onBitrixLiveChat, phone, fullName, username]);
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = iwidth <= 959;

  let t = [routesEng, routesEsp, routesRus];

  let routes = dashboardRoutes.map((prop) => ({
    ...prop,
    name: t[language - 1][prop.name],
  }));

  if (is_cdpap) {
    routes = routes.filter(function(obj) {
      return obj.path !== "/availability";
    });
  }
  //console.log(routes);
  //console.log('main2')

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const Nav = () => {
    return (
      <Navbar
        routes={routes}
        handleDrawerToggle={handleDrawerToggle}
        {...rest}
      />
    );
  };
  //console.log('main3')

  function getDefaultRedirect() {
    //console.log('getDefaultRedirect')
    if (isTrainingTab) {
      return "/main/nevvon";
    }
    if (isTSTab) {
      return "/main/timesheet";
    }
    if (isPOCTab) {
      return "/main/poc";
    }
    if (isAvailTab) {
      return "/main/availability";
    }
    if (isQTNTab) {
      return "/main/qtn";
    }
    if (isPatientInfoTab) {
      return "/main/patient_info";
    }
    if (CAMPAIGN_MESSAGE_LIST.includes(campaign_message)) {
      return "/main/campaign";
    }
    if (isMobile) {
      if (is_cdpap) {
        return "/main/timesheet";
      }
      return "/main/availability";
    } else {
      return "/main/campaign";
    }
  }

  const switchRoutes = (
    <div>
      <Switch>
        {routes.map((prop, key) => {
          if (prop.layout === "/main") {
            return (
              <Route
                path={prop.layout + prop.path}
                key={key}
                children={() => <Nav />}
              />
            );
          }
          return null;
        })}
      </Switch>
      <Switch>
        {routes.map((prop, key) => {
          if (prop.layout === "/main") {
            return (
              <Route
                path={prop.layout + prop.path}
                key={key}
                children={() => <Sidebar />}
              />
            );
          }
          return null;
        })}
      </Switch>
      <Switch>
        {routes.map((prop, key) => {
          if (prop.layout === "/main") {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
          return null;
        })}
      </Switch>
      <Redirect to={getDefaultRedirect()} />
    </div>
  );

  // styles
  const classes = useStyles();

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);

  const getRoute = () => {
    return window.location.pathname !== "/main/maps";
  };

  return (
    <div className={classes.wrapper} id="MainWrapper">
      <>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={!isLanguageSelected}
        >
          <DialogContent>
            <LoginCard cardTitle={`Language`} IconLogin={Language}>
              <LoginLangSelect />
            </LoginCard>
          </DialogContent>
        </Dialog>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={!policyAccepted && isLanguageSelected}
        >
          <DialogContent>
            <LoginCard cardTitle={`Company policy`} IconLogin={Gavel}>
              <PolicyForm />
            </LoginCard>
          </DialogContent>
        </Dialog>
        <Sidebar
          routes={routes}
          logo={logo}
          image={bgImage}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color="purple"
          {...rest}
        />
        <div
          className={classes.mainPanel}
          ref={mainPanel}
          id="mainDashboardPanel"
        >
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}
          {getRoute() ? <AppFeedbackDialog /> : null}

          {getRoute() ? <Footer /> : null}
        </div>
        {Capacitor.isNativePlatform() &&
        isMobile &&
        FEATURE_ENABLE_BOTTOMBAR ? (
          <Bottombar />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { fullName, username, isLanguageSelected, policyAccepted } = state.user;
  const { phone } = state.auth;

  const { language } = state.user;
  const {
    isTrainingTab,
    campaign_message,
    isTSTab,
    isPOCTab,
    isAvailTab,
    isQTNTab,
    isPatientInfoTab,
  } = state.auth;
  const { is_cdpap } = state.availability;
  return {
    language,
    isTrainingTab,
    campaign_message,
    isTSTab,
    isPOCTab,
    isAvailTab,
    isQTNTab,
    isPatientInfoTab,
    is_cdpap,
    fullName,
    username,
    phone,
    isLanguageSelected,
    policyAccepted,
  };
};

export default connect(mapStateToProps)(Main);
