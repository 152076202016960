import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
// UI
import LogoLayout from "./layouts/LogoLayout";
import LocationDenied from "./components/LocationDenied/LocationDenied";
// layouts
import Main from "./components/Main/Main";
import Login from "./layouts/Login";
import ProtectedLayout from "./layouts/ProtectedLayout";
// redux
import { connect } from "react-redux";
import { init, uploadData, checkTrainigTab } from "./modules/appModule";

//capacitor
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import { Storage } from "@capacitor/storage";

import {
  getNotificationsChunk,
  regNativeNotification,
} from "./modules/appnotifications";
import { STORED_FCM_TOKEN_KEY } from "./modules/auth";
import MainLogoLayout from "./layouts/MainLogoLayout";

export const history = createBrowserHistory();

const App = (props) => {
  const {
    uploadDone,
    isInitialized,
    isMainLogoShown,
    isAllowedLocation,
    initializeApp,
    isTokenValid,
    uploadUserData,
    checkTrainigTabParam,
    appCrashed,
    isTrainingTab,
    campaign_message,
    isNativeNotificationsRegistered,
    regNativeNotificationDispatch,
  } = props;
  useEffect(() => {
    const register = () => {
      //console.log("Initializing HomePage");

      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token) => {
        regNativeNotificationDispatch();
        let store_fcm_async = async function() {
          return await Storage.set({
            key: STORED_FCM_TOKEN_KEY,
            value: token.value,
          });
        };
        store_fcm_async();
        //console.log("registration token", token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          //console.log("pushNotificationReceived and ", notification);
          try {
            showToast("New Notification");
            getNotificationsChunk();
          } catch (error) {
            console.log("error", error);
          }
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (action) => {
          //console.log(
          //  "pushNotificationActionPerformed data ",
          //  action.notification.data
          //);
          try {
            if (action.notification.data) {
              if (action.notification.data.redirect) {
                let redirect_link = action.notification.data.redirect;

                if (redirect_link.length > 0) {
                  //console.log("redirect to availability");
                  showToast("Redirect");
                  setTimeout(
                    () =>
                      (window.location = window.location + "/" + redirect_link),
                    2000
                  );
                  //setTimeout(() => history.push("/main/availability"), 2000);
                }
              }
            }
          } catch (error) {
            console.log("error", error);
          }
        }
      );
    };

    if (Capacitor.isNativePlatform()) {
      if (!isNativeNotificationsRegistered) {
        PushNotifications.checkPermissions().then((res) => {
          if (res.receive !== "granted") {
            PushNotifications.requestPermissions().then((res) => {
              if (res.receive === "denied") {
                showToast("Push Notification permission denied");
              } else {
                showToast("Push Notification permission granted");
                register();
              }
            });
          } else {
            register();
          }
        });
      }
    }
  }, [isNativeNotificationsRegistered, regNativeNotificationDispatch]);

  useEffect(() => {
    //add redirect state to redux to Training TAB or any campaign message
    if (!isTrainingTab && campaign_message === "") {
      checkTrainigTabParam();
    }

    if (!isInitialized) {
      /**
       * Check user's location and token
       */
      initializeApp();
    } else if (isAllowedLocation && isTokenValid && !uploadDone) {
      /**
       * Token is expired or null, then route to the login screen
       */
      uploadUserData();
    }
  }, [
    isInitialized,
    campaign_message,
    isTrainingTab,
    isTokenValid,
    uploadDone,
    isAllowedLocation,
    initializeApp,
    uploadUserData,
    checkTrainigTabParam,
  ]);

  return (
    <div>
      {appCrashed ? (
        <LocationDenied errorMessage={`Application error`} />
      ) : isInitialized && !isAllowedLocation ? (
        <LocationDenied errorMessage={`Access denied`} />
      ) : !isInitialized || (isInitialized && isTokenValid && !uploadDone) ? (
        <LogoLayout />
      ) : !isMainLogoShown ? (
        <MainLogoLayout />
      ) : (
        <Router history={history}>
          {isTokenValid ? (
            <Redirect to={"/main"} />
          ) : (
            <Redirect to={"/login"} />
          )}
          <Switch>
            <Route path="/main">
              <ProtectedLayout>
                <Main />
              </ProtectedLayout>
            </Route>
            <Route path="/login" component={Login} />
          </Switch>
        </Router>
      )}
    </div>
  );
};

const showToast = async (msg) => {
  console.log("Toast.show: ", msg);
  await Toast.show({
    text: msg,
  });
};

const mapStateToProps = (state) => {
  return {
    isInitialized: state.app.isInitialized,
    isMainLogoShown: state.app.isMainLogoShown,
    isTokenValid: state.app.isTokenValid,
    isAllowedLocation: state.app.isAllowedLocation,
    uploadDone: state.app.uploadDone,
    appCrashed: state.app.appCrashed,
    isTrainingTab: state.auth.isTrainingTab,
    campaign_message: state.auth.campaign_message,
    isNativeNotificationsRegistered:
      state.appnotifications.isNativeNotificationsRegistered,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeApp: () => dispatch(init()),
    uploadUserData: () => dispatch(uploadData()),
    checkTrainigTabParam: () => dispatch(checkTrainigTab()),
    regNativeNotificationDispatch: () => dispatch(regNativeNotification()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
