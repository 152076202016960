export function getMomentLocale(language) {
  switch (language) {
    case 1:
      return "en";
    case 2:
      return "es";
    default:
      return "ru";
  }
}
