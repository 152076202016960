import {
  LOGOUT,
  SET_TOKEN,
  TOKEN_WRONG,
  TRAINING_TAB,
  CAMPAIGN_MESSAGE,
  TS_TAB,
  POC_TAB,
  STORED_PHONE_KEY,
  STORED_PIN_KEY,
  SETUP_PHONE,
  SET_PIN,
  AVAIL_TAB,
  QTN_TAB,
  PATIENT_INFO_TAB,
  STORED_FCM_TOKEN_KEY,
} from "./auth";
import { isLocationAllowed2, isTokenValid } from "../utils/setupUtils";
import * as Api from "../api";
import { AVAIL_LOAD_SUCCESS } from "./availability";
import { SCHEDULE_LOADED } from "./schedule";
import { TIMESHEET_LOADED } from "./timesheet";
import { SETUP_USER } from "./user";
import {
  adjustScheduleTime,
  adjustTimesheets,
  adjustUserData,
} from "../utils/uploadUtils";
import { CAMPAIGNS_LOADED, CAMPAIGNS_TEXTS } from "./campaigns";
import { AVAILABILITY_WIZARD_LOADED } from "./availabilitywizard";
//import { CAMPAIGN_MESSAGE_LIST_TS } from "../constants";
import { POC_LOADED } from "./poc";
import { IMPORTANT_MESSAGE_LOADED } from "./importantmessage";
import { NOTIFICATIONS_LOADED } from "./appnotifications";
import { Storage } from "@capacitor/storage";

/**
 * Constants
 */

export const INITIALIZED = "app/INITIALIZED";
export const LOGOSHOWN = "app/LOGOSHOWN";
export const TOKEN_OK = "app/TOKEN_OK";
export const UPLOAD_FINISHED = "app/UPLOAD_FINISHED";
export const UPLOAD_FAIL = "app/UPLOAD_FAIL";
export const CHECK_IP_FAIL = "app/CHECK_IP_FAIL";
export const CLIENT_IP_ALLOWED = "app/CLIENT_IP_ALLOWED";
export const CLIENT_IP_REJECTED = "app/CLIENT_IP_REJECTED";

export const APP_FEEDBACK_OPEN = "app/APP_FEEDBACK_OPEN";
export const APP_FEEDBACK_CLOSE = "app/APP_FEEDBACK_CLOSE";
/**
 * Action Creators
 */

export function init() {
  return async (dispatch) => {
    try {
      //console.log('isLocationAllowed2 before')
      const [_isLocationAllowed, clientIP] = await isLocationAllowed2();
      console.log("isLocationAllowed2 after", _isLocationAllowed, clientIP);
      if (!_isLocationAllowed) {
        dispatch({ type: CLIENT_IP_REJECTED });
        dispatch({ type: INITIALIZED });
        return;
      } else {
        dispatch({ type: CLIENT_IP_ALLOWED, clientIP });
      }
      const phone = await Storage.get({ key: STORED_PHONE_KEY });
      const pin = await Storage.get({ key: STORED_PIN_KEY });
      if (phone) {
        if (phone.value) {
          if (phone.value.length === 10) {
            dispatch({ type: SETUP_PHONE, phone: phone.value });
          }
        }
      }
      if (pin) {
        if (pin.value) {
          if (pin.value.length === 4) {
            dispatch({ type: SET_PIN, pin: pin.value });
          }
        }
      }

      const [_isTokenValid, payload] = await isTokenValid();
      if (_isTokenValid) {
        payload.json().then((data) => {
          const token = data.token;
          const userData = adjustUserData(data);

          localStorage.setItem("token", token);

          dispatch({ type: SET_TOKEN, token });
          dispatch({ type: SETUP_USER, userData });
          dispatch({ type: TOKEN_OK });
          dispatch({ type: INITIALIZED });
        });
      } else {
        dispatch({ type: TOKEN_WRONG });
        dispatch({ type: INITIALIZED });
      }
    } catch (error) {
      //console.log("init error", error);
    }
  };
}

export function uploadData() {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth.token;
      //const phone = getState().auth.phone;
      //const campaign_message = CAMPAIGN_MESSAGE_LIST_TS;
      //const text_data = {
      //  phone: phone,
      //  campaign_name: campaign_message,
      //};
      let fcm_token_value = "";
      const fcm_token = await Storage.get({ key: STORED_FCM_TOKEN_KEY });
      if (fcm_token) {
        if (fcm_token.value) {
          if (fcm_token.value.length > 5) {
            fcm_token_value = fcm_token.value;
          }
        }
      }
      //console.log("fcm_token_value",fcm_token_value)
      const fcm_obj = {
        fcm_token: fcm_token_value,
      };
      if (fcm_token_value !== "") {
        Api.sendFCMToken(token, fcm_obj);
      }

      Promise.all([
        //Api.getAvailability(token),
        //Api.getSchedule(token),
        //Api.getTimesheet(token),
        //Api.getCampaigns(token),
        Api.getAvailabilityWizard(token),
        //Api.getCampainTexts(token, text_data),
        //Api.getPOC(token),
        Api.getNotifications(token),
        //Api.sendFCMToken(token, fcm_obj)
      ])
        .then((responses) => {
          responses.forEach((response) => {
            if (!response.ok) {
              dispatch({ type: UPLOAD_FAIL });
            } else {
              response.json().then((data) => {
                if (data.hasOwnProperty("available")) {
                  dispatch({
                    type: AVAIL_LOAD_SUCCESS,
                    available: data.available,
                  });
                } else if (data.hasOwnProperty("schedule")) {
                  const schedule = adjustScheduleTime(data.schedule);
                  dispatch({ type: SCHEDULE_LOADED, schedule });
                } else if (data.hasOwnProperty("timesheets")) {
                  const timesheets = adjustTimesheets(data.timesheets);
                  dispatch({ type: TIMESHEET_LOADED, payload: timesheets });
                } else if (data.hasOwnProperty("campaigns")) {
                  dispatch({
                    type: CAMPAIGNS_LOADED,
                    campaigns: data.campaigns,
                  });
                } else if (data.hasOwnProperty("wizard")) {
                  //console.log('TADA!!!');
                  dispatch({
                    type: AVAILABILITY_WIZARD_LOADED,
                    steps: data.steps,
                    show_count: data.show_count,
                  });
                } else if (data.hasOwnProperty("campaign_texts")) {
                  dispatch({
                    type: CAMPAIGNS_TEXTS,
                    campaign_texts: data.campaign_texts,
                  });
                } else if (data.hasOwnProperty("poc")) {
                  dispatch({ type: POC_LOADED, pocs: data.poc });
                } else if (data.hasOwnProperty("showmessage")) {
                  dispatch({
                    type: IMPORTANT_MESSAGE_LOADED,
                    showmessage: data.showmessage,
                  });
                } else if (data.hasOwnProperty("app_notifications")) {
                  dispatch({
                    type: NOTIFICATIONS_LOADED,
                    appnotifications: data.app_notifications,
                  });
                }
              });
            }
          });
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => dispatch({ type: UPLOAD_FINISHED }));
    } catch (error) {
      console.error(error);
      dispatch({ type: UPLOAD_FAIL });
    }
  };
}

export function checkTrainigTab() {
  return async (dispatch) => {
    //add redirect state to redux to Training TAB or any message
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let nevvon = params.has("nevvon-credentials");
    if (nevvon) {
      dispatch({ type: TRAINING_TAB });
      dispatch({ type: CAMPAIGN_MESSAGE, campaign_message: "---" });
    } else {
      let campaign_location = window.location.pathname.split("/").pop();
      //console.log('campaign_location=')
      //console.log(campaign_location)
      if (campaign_location === "ts") {
        dispatch({ type: TS_TAB });
        dispatch({ type: CAMPAIGN_MESSAGE, campaign_message: "---" });
      } else if (campaign_location === "avail") {
        dispatch({ type: AVAIL_TAB });
        dispatch({ type: CAMPAIGN_MESSAGE, campaign_message: "---" });
      } else if (campaign_location === "qtn") {
        dispatch({ type: QTN_TAB });
        dispatch({ type: CAMPAIGN_MESSAGE, campaign_message: "---" });
      } else if (campaign_location === "info") {
        dispatch({ type: PATIENT_INFO_TAB });
        dispatch({ type: CAMPAIGN_MESSAGE, campaign_message: "---" });
      } else {
        if (campaign_location === "poc") {
          dispatch({ type: POC_TAB });
          dispatch({ type: CAMPAIGN_MESSAGE, campaign_message: "---" });
        } else {
          dispatch({
            type: CAMPAIGN_MESSAGE,
            campaign_message: campaign_location,
          });
        }
      }
    }
  };
}

export function closeAppFeedbackDialog() {
  return async (dispatch) => {
    dispatch({ type: APP_FEEDBACK_CLOSE });
  };
}

export function openAppFeedbackDialog() {
  return async (dispatch) => {
    dispatch({ type: APP_FEEDBACK_OPEN });
  };
}

export function closeMainLogo() {
  return async (dispatch) => {
    dispatch({ type: LOGOSHOWN });
  };
}

/**
 * Reducer
 */
const initialState = {
  isIPChecked: false,
  isAllowedLocation: false,
  clientIP: null,
  isInitialized: false,
  isMainLogoShown: false,
  isTokenValid: false,
  uploadDone: false,
  appCrashed: false,
  appFeedBackOpen: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case CHECK_IP_FAIL:
      return {
        ...state,
        isIPChecked: true,
      };
    case CLIENT_IP_ALLOWED:
      return {
        ...state,
        isIPChecked: true,
        isAllowedLocation: true,
        clientIP: action.clientIP,
      };
    case CLIENT_IP_REJECTED:
      return {
        ...state,
        isIPChecked: true,
        isAllowedLocation: false,
      };
    case INITIALIZED:
      return {
        ...state,
        isInitialized: true,
      };
    case LOGOSHOWN:
      return {
        ...state,
        isMainLogoShown: true,
      };
    case TOKEN_OK:
      return {
        ...state,
        isTokenValid: true,
      };
    case UPLOAD_FAIL:
      return {
        ...state,
        uploadDone: true,
        appCrashed: true,
      };
    case UPLOAD_FINISHED:
      return {
        ...state,
        uploadDone: true,
      };
    case APP_FEEDBACK_OPEN:
      return {
        ...state,
        appFeedBackOpen: true,
      };
    case APP_FEEDBACK_CLOSE:
      return {
        ...state,
        appFeedBackOpen: false,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
