const qtnEng = {
	q0: "1. Do you work for any other agency?",
	q1: "2. Do you complete your in-service online?",
	q2: "3. What platform website do you use?",
	q3: "Thank you, we've received your answers.",
	title: "Survey",//"Do you want to win $25 Starbucks gift card?",//Do you want to win $25 Starbucks gift card?
	subtitle: "Please answer these 3 questions and your name will be entered into this lottery.	We will inform everyone who the winner is on our FB and Instagram pages.",
	btnYes: "YES",
    btnNo: "NO",
    btnOK: "OK",
	btnBegin: "Begin",
    website: "website",
	bottomCardText: "Win $25 Starbucks gift card",

}
export default qtnEng;
