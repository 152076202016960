import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
// core components
import EditedRow from "./RowTypes/EditedRow";
// reducer functions
import { connect } from "react-redux";
import { selectEdit } from "../../modules/editVisit";
// style
import styles from "./styles";

const useStyles = makeStyles(styles);

const EditedMainList = (props) => {
  const { visits, pn, confirmEd } = props;
  const classes = useStyles();

  function visitsEditedArrayFunction(allVisits) {
    let arr = [];
    Object.entries(allVisits).forEach(([_, visData]) => {
      if (
        !visData.is_ed &&
        visData.hasOwnProperty("st_conf") &&
        !visData.st_conf
      ) {
        arr.push(visData);
      }
    });
    arr.sort((a, b) =>
      a.hasOwnProperty("vd") && b.hasOwnProperty("vd") && a.vd > b.vd ? 1 : -1
    );
    return arr;
  }

  return Object.entries(visits).map(([pid, allVisits]) => {
    let visitsArray = visitsEditedArrayFunction(allVisits);
    if (visitsArray.length === 0) {
      return '';
    }
    return (
      <FormControl
        key={Math.random()}
        component="fieldset"
        fullWidth={true}
        style={styles.formControl}
      >
        <FormLabel component="legend" style={styles.formControlLabel}>
          {pn[pid]}
        </FormLabel>
        {visitsArray.map((visit, index) => {
          return (
            <div key={index} className={classes.tsRow}>
              <EditedRow visit={visit} handleRowClick={confirmEd} />
            </div>
          );
        })}
      </FormControl>
    );
  });
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmEd: (visit) => dispatch(selectEdit(visit)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(EditedMainList);
