import React, {useState} from 'react';
// UI
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import InputLabel from "@material-ui/core/InputLabel";
// signature
import SignatureCanvas from "react-signature-canvas";
// styles
import styles from "../styles";
// redux
import {connect} from "react-redux";
import {handlerCaregiverSignatures} from "../../../modules/timesheet";

const isSmallScreen = window.matchMedia("(min-width: 720px)").matches

function CaregiverSign(props) {

	const {
		t,
		h,
		m,
		ts,
		setSignature,
	} = props
	const [sigPadCar, setSigPadCar] = useState({});

	const StateInfoBlock = () => {
		return (
				<div style={styles.infoBlock}>
					<p>{t.patSign1}<b>{h}{t.patSign2} {m ? t.patSign3 + m + t.patSign4 : ''}</b>
						{t.carSign1}<b>{ts.taskSelected.size.toString()}</b>{t.patSign6}
					</p>
				</div>
		)
	}
	const signatureText = t.tsSteps0

	function sendSign() {
		const sign = sigPadCar.getTrimmedCanvas().toDataURL('image/png')
		setSignature(sign)
	}

	return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<StateInfoBlock/>
					<InputLabel>{signatureText}</InputLabel>
					<SignatureCanvas
							ref={(ref) => {
								setSigPadCar(ref)
							}}
							penColor='black'
							onEnd={() => sendSign()}
							canvasProps={{
								className: !isSmallScreen ? 'sigCanvas' : 'sigCanvasBig',
								required: true,
							}}
					/>
				</GridItem>
			</GridContainer>
	);
}

const mapStateToProps = state => {
	return {
		ts: state.timesheet.ts,
		h: state.timesheet.ts.h,
		m: state.timesheet.ts.m,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		setSignature: (sign) => dispatch(handlerCaregiverSignatures(sign)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CaregiverSign);
