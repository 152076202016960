import React, { Component } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
//import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../components/CustomAgenda/custom_css.css";


import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import scheduleEng from "../../assets/languages/eng/scheduleEng";
import scheduleEsp from "../../assets/languages/esp/scheduleEsp";
import scheduleRus from "../../assets/languages/rus/scheduleRus";
import { connect } from "react-redux";
import { getScheduleChunk } from "../../modules/schedule.js";
import { Popover, Typography, Paper } from "@material-ui/core";
//import CustomAgenda from "../../components/CustomAgenda/CustomAgenda.jsx";
//import ImportantMessage from "../../components/ImportantMessage/ImportantMessage";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const localizer = momentLocalizer(moment);


const Schedule = (props) => {
  const classes = useStyles();

  const { schedule, language, isScheduleLoaded, onScheduleRet } = props;
  if (!isScheduleLoaded) {
    onScheduleRet(props);
  }
  let t = [null, scheduleEng, scheduleEsp, scheduleRus][language];
  let mLoc;
  switch (language) {
    case 1:
      mLoc = "en";
      break;
    case 2:
      mLoc = "es";
      break;
    default:
      mLoc = "ru";
  }
  moment.locale(mLoc, {
    week: {
      dow: 1,
      doy: 1,
    },
  });

  class CustomEvent extends Component{
    constructor(props) {
      super(props);
      this.state = {
        isModalOpen: false,
      };
    }
  
    handleOpen = () => {
      this.setState({ isModalOpen: true });
    };
  
    handleClose = () => {
      this.setState({ isModalOpen: false });
    };
    render() {
      //const { classes } = this.props;
      const { event} = this.props;
  
      const startm = moment(Date.parse(event.start)).format("h:mm a");
      const endmm = moment(Date.parse(event.end)).format("h:mm a");
      const eventtime = startm + " - " + endmm;
      //console.log(this.props);
      return (
        <div>
          <div onClick={this.handleOpen}> {event.title}</div>
          <Popover
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.isModalOpen}
            onClose={this.handleClose}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            anchorReference={"none"}
          >
            <Paper style={{ padding: "10px" }}>
              {/*<Typography variant="subtitle1">
                {localizer.messages.event}
          </Typography>*/}
              <Typography variant="subtitle1">{event.title}</Typography>
              <Typography variant="subtitle1">{t.address}</Typography>
              <Typography variant="body1">{event.address}</Typography>
              <Typography variant="subtitle1">
                {t.time}
              </Typography>
              <Typography variant="body1">{eventtime}</Typography>
            </Paper>
          </Popover>
        </div>
      );
    }
  }
 
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>{t.schedule}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ height: "500pt" }}>
                  <Calendar
                    localizer={localizer}
                    events={schedule}
                    components={{
                      event: CustomEvent,
                    }}
                    startAccessor="start"
                    endAccessor="end"
                    titleAccessor={"title"}
                    messages={{
                      month: t.month,
                      week: t.week,
                      day: t.day,
                      today: t.today,
                      previous: t.previous,
                      next: t.next,
                      agenda: t.agenda,
                      date: t.date,
                      time: t.time,
                      event: t.event,
                    }}
                    /*views={{
                      week: true,
                      month: true,
                      day: true,
                      agenda: CustomAgenda
                    }}*/
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};
const mapStoreToProps = (state) => {
  const { schedule, isScheduleLoaded } = state.schedule;
  const { language } = state.user;
  return {
    schedule,
    language,
    isScheduleLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onScheduleRet: (props) => dispatch(getScheduleChunk(props)),
  };
};

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(Schedule);
