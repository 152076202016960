import React from 'react';
import Button from "../CustomButtons/Button";

const FaqButton = (props) => {

	const {title, onClickHandler} = props

	return (
			<Button
					color="info"
					hidden={true}
					onClick={onClickHandler}
					fullWidth={true}
					simple={true}
					size={`lg`}
			>
				{title}
			</Button>
	);
}

export default FaqButton;
