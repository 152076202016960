import React from 'react';
import Button from "@material-ui/core/Button";

const LangButton = (props) => {
	const {children, ...rest} = props;

	return (
			<Button color={`secondary`}
							fullWidth={true}
							{...rest}
			>
				{children}
			</Button>
	);
};

export default LangButton;
