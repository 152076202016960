
import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const SundaySvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 32.75 32.75`} {...props}>
				<path d="M29.375,1.25h-1.124c0.028-0.093,0.058-0.186,0.058-0.289C28.311,0.431,27.879,0,27.348,0s-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.059,0.289h-3.68c0.029-0.093,0.058-0.186,0.058-0.289C22.823,0.43,22.393,0,21.861,0
			C21.331,0,20.9,0.431,20.9,0.961c0,0.103,0.029,0.196,0.059,0.289h-3.68c0.029-0.093,0.058-0.186,0.058-0.289
			C17.336,0.431,16.906,0,16.375,0c-0.531,0-0.961,0.431-0.961,0.961c0,0.103,0.029,0.196,0.058,0.289h-3.68
			c0.029-0.093,0.058-0.186,0.058-0.289C11.85,0.431,11.419,0,10.888,0c-0.531,0-0.961,0.431-0.961,0.961
			c0,0.103,0.028,0.196,0.058,0.289h-3.68c0.03-0.093,0.058-0.186,0.058-0.289C6.363,0.43,5.933,0,5.402,0
			C4.871,0,4.441,0.431,4.441,0.961c0,0.103,0.029,0.196,0.058,0.289H3.375c-1.517,0-2.75,1.233-2.75,2.75v26
			c0,1.518,1.233,2.75,2.75,2.75H26.27l5.855-5.855V4C32.125,2.483,30.893,1.25,29.375,1.25z M30.625,26.273l-0.311,0.311h-2.356
			c-1.101,0-2,0.9-2,2v2.355l-0.31,0.311H3.375c-0.689,0-1.25-0.561-1.25-1.25V9h28.5V26.273z"/>
		<path d="M7.807,22.195c-0.253,0-0.528-0.066-0.737-0.198c-0.22-0.132-0.374-0.341-0.429-0.627H4.155
			c0.055,1.43,1.419,2.651,3.509,2.651c2.178,0,3.708-0.892,3.708-2.729c0-2.882-4.555-2.146-4.555-3.333
			c0-0.286,0.253-0.451,0.825-0.451c0.253,0,0.484,0.044,0.671,0.143c0.187,0.1,0.33,0.265,0.374,0.541h2.464
			c-0.187-1.386-1.188-2.509-3.388-2.509c-1.98,0-3.433,0.847-3.433,2.531c0,3.025,4.555,2.2,4.555,3.398
			C8.885,21.986,8.456,22.195,7.807,22.195z"/>
		<path d="M15.771,24.021c2.629,0,3.806-1.111,3.806-3.652v-4.478H16.98v4.741c0,0.858-0.274,1.453-1.209,1.453
			c-1.067,0-1.221-0.671-1.221-1.453v-4.741h-2.597v4.466C11.953,23.086,13.317,24.021,15.771,24.021z"/>
		<polygon points="23.042,21.305 22.941,19.5 25.77,23.812 28.201,23.812 28.201,15.891 25.715,15.891 25.715,18.41 25.814,20.214
			22.986,15.891 20.555,15.891 20.555,23.812 23.042,23.812 		"/>
			</SvgIcon>
</div>
	);
};

export default SundaySvg;
