import React from "react";
// UI
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
// styles
import styles from "../../views/Timesheet/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  ...styles,
});
const POCTable = (props) => {
  const classesStepped = useStyles();
  const { poc } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Paper style={styles.root}>
          <Table style={styles.tableSmall}>
            <TableBody>
              {Object.entries(poc).map((singleTask, key) => {
                //console.log(singleTask);

                return (
                  <TableRow key={key} className={classesStepped.tableRow}>
                    <TableCell classes={{ root: classesStepped.tCell }}>
                      <b>{singleTask[0]}</b>
                    </TableCell>
                    <TableCell classes={{ root: classesStepped.tCell }}>
                      {singleTask[1]}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </GridItem>
    </GridContainer>
  );
};

export default POCTable;
