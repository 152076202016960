import React from "react";
import { connect } from "react-redux";
//import Button from '@material-ui/core/Button';
import Button from "../../components/CustomButtons/Button";
import Dialog from "../../components/DialogMigrate/DialogMigrate";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import feedbackEng from "../../assets/languages/eng/feedbackEng";
import feedbackEsp from "../../assets/languages/esp/feedbackEsp";
import feedbackRus from "../../assets/languages/rus/feedbackRus";
import styles from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import { sendAppFeedbackTrunk } from "modules/appFeedback";
import { closeAppFeedbackDialog } from "modules/appModule";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(styles);

const AppFeedbackDialog = (props) => {
  const classes = useStyles();
  //console.log('tada')
  const { language, appFeedBackOpen, sendAppFeedback, closeDialog } = props;

  let t = [null, feedbackEng, feedbackEsp, feedbackRus][language];

  const [valueInput, setValueInput] = React.useState("");

  const handleInputChange = (event) => {
    setValueInput(event.target.value);
  };

  const handlesendAppFeedback = () => {
    sendAppFeedback(valueInput);
    closeDialog();
  };

  return (
    <div className={classes.container}>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={appFeedBackOpen}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ margin: "0 auto", padding: 0 }}
        >
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h4 style={{ margin: "auto", padding: "15px" }}>
              <strong>{t.title}</strong>
            </h4>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.allcenter}>
              <TextField
                id="outlined-multiline-static"
                label={t.smalltitle}
                multiline
                rows={4}
                placeholder={t.title2}
                onChange={handleInputChange}
                value={valueInput}
                variant="outlined"
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ margin: "auto" }}>
            <Button onClick={closeDialog} color="success" type={`button`}>
              {t.closeButton}
            </Button>
            <Button
              onClick={handlesendAppFeedback}
              color="info"
              type={`button`}
            >
              {t.sendButton}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { appFeedBackOpen } = state.app;
  const { language } = state.user;
  return { appFeedBackOpen, language };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAppFeedback: (feedback_string) =>
      dispatch(sendAppFeedbackTrunk(feedback_string)),
    closeDialog: () => dispatch(closeAppFeedbackDialog()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppFeedbackDialog);
