const apiUrl = process.env["REACT_APP_PROXY"];

//user
export function setLanguage(token, lang) {
  return callApi(token, "set-language/", {
    method: "post",
    body: JSON.stringify({ lang }),
  });
}

// auth
export function acceptCompanyPolicy(token) {
  return callApi(token, "accept-policy/", { method: "post", body: {} });
}

export function checkLocation(clientIP) {
  return callApi("", "check-location/", {
    method: "post",
    body: JSON.stringify({ clientIP }),
  });
}

export function checkToken(token) {
  return callApi(token, "api-token-verify/", {
    method: "post",
    body: JSON.stringify({ token }),
  });
}

export function sendPhoneNumber(phone) {
  return callApi("", "check-phone/", {
    method: "post",
    body: JSON.stringify({ phone }),
  });
}

export function sendCodeEmail(phone) {
  return callApi("", "send-code-email/", {
    method: "post",
    body: JSON.stringify({ phone }),
  });
}

export function sendCode(code, phone, clientIP) {
  return callApi("", "check-code/", {
    method: "post",
    body: JSON.stringify({ code, phone, clientIP }),
  });
}

export function checkPin(pin, phone, clientIP, device_id) {
  return callApi("", "check-pin/", {
    method: "post",
    body: JSON.stringify({ pin, phone, clientIP, device_id }),
  });
}

export function setPin(token, pin, phone, device_id) {
  return callApi(token, "set-pin/", {
    method: "post",
    body: JSON.stringify({ pin, phone, device_id }),
  });
}

// campaign
export function getCampaigns(token) {
  return callApi(token, "get-campaigns/");
}

// notifications
export function getNotifications(token) {
  return callApi(token, "app-notification/");
}

export function sendNotificationReadStatus(token, data) {
  return callApi(token, "app-notification-read/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

export function sendFCMToken(token, data) {
  return callApi(token, "send-fcm-token/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

//app feedback
export function sendAppFeedback(token, data) {
  return callApi(token, "app-feedback/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// schedule
export function getSchedule(token) {
  return callApi(token, "calendar/");
}

// availability
export function getAvailability(token) {
  return callApi(token, "get-availability/");
}

export function sendAvailability(token, data) {
  return callApi(token, "set-availability/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// timesheet
export function getTimesheet(token) {
  return callApi(token, "timesheet-required-list/");
}

export function sendTimesheet(token, data) {
  return callApi(token, "confirmed-timesheets/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

export function sendEdited(token, data) {
  return callApi(token, "edited-visits/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// change address
export function sendCAForm(token, data) {
  return callApi(token, "address-change/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// Availability Wizard
export function getAvailabilityWizard(token) {
  return callApi(token, "availability_wizard/");
}

export function sendAvailabilityWizard(token, data) {
  return callApi(token, "availability_wizard/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// Nevvon
export function getNevvon(token, data) {
  return callApi(token, "nevvon/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// QTN
export function getSaveQTN(token, data) {
  return callApi(token, "save-qtn/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// Text messages
export function getCampainTexts(token, data) {
  return callApi(token, "campain_texts/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

// POC
export function getPOC(token) {
  return callApi(token, "poc/");
}

// POC
export function getPatientInfo(token) {
  return callApi(token, "patient_info/");
}
//check one per day message
export function getImportantMessage(token, data) {
  return callApi(token, "important-message/", {
    method: "post",
    body: JSON.stringify(data),
  });
}

/**
 * Private functions
 */

function callApi(token, endpoint, options = { method: "get" }) {
  const url = `${apiUrl}/${endpoint}`;
  return fetch(url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("API error", err);
    });
}
