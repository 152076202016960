import React from "react";
import {connect} from "react-redux";
import {handleNextStep} from "../../modules/availability";
import availEng from "../../assets/languages/eng/availEng";
import availEsp from "../../assets/languages/esp/availEsp";
import availRus from "../../assets/languages/rus/availRus";
import Button from "../../components/CustomButtons/Button";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";


const useStyles = makeStyles(styles)

const Done = (props) => {
	let t = [null, availEng, availEsp, availRus][props.language];

	const classes = useStyles();
	return (
			<div>
				<div className={classes.centeredMessage}>
					<Typography>{props.formSendSuccess ? t.successMessage : t.errorMessage}</Typography>
				</div>

				<div className={classes.btnGroup}>
					<Button
							color="success"
							type={`button`}
							onClick={() => props.nextStep(props, true)}
					>
						{t.btn_5}
					</Button>
				</div>

			</div>
	)
}

const mapStateToProps = (state) => {
	return  {
		language: state.user.language,
		formSendSuccess: state.availability.formSendSuccess
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		nextStep: (props, sendToBackend) => dispatch(handleNextStep(props, sendToBackend))
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Done);
