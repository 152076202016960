import React from "react";
import { connect } from "react-redux";
// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
// reducer functions
import { onGetNevvon } from "../../modules/nevvon";
// language
import PinFormDialog from "../../components/PinFormDialog/PinFormDialog";

const Nevvon = (props) => {
  const { nevvon, onGetNevvonText } = props;

  if (!nevvon.isNevvonLoaded) {
    onGetNevvonText(props);
  }

  //const classes = useStyles();
  function nevvonText() {
    if (nevvon.isNevvonLoaded) {
      const nevvon_rendered = nevvon.template
        .replace("%%LOGIN%%", nevvon.nevvon_login)
        .replace("%%PASSWORD%%", nevvon.nevvon_pass);
      return (
        <div>
          <h4 dangerouslySetInnerHTML={{ __html: nevvon_rendered }} />
        </div>
      );
    } else {
      return <p>loading...</p>;
    }
  }

  return (
    <div>
      <PinFormDialog />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <div>{nevvonText()}</div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { nevvon } = state.nevvon;
  return { nevvon };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetNevvonText: (props) => dispatch(onGetNevvon(props)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nevvon);
