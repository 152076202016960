const styles = {
	formGroupClass: {
		paddingLeft: "30px",
		margin: "10px 0"
	},
	formControlClass: {
		marginTop: "20px"
	},
	btnGroup: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: "20px 0 5px 0"
	},
	svgIcon: {
		paddingRight: "10px",
	},
	svgIconSmall: {
		paddingRight: "20px"
	},
	sliderClass: {
		padding: "0 10px"
	},
	timeCell: {
		fontWeight: "bold",
		textAlign: "center"
	},
	errMessageFragment: {
		textAlign: "center",
		margin: "15px 0 0 0"
	},
	alertContainer: {
		color: "#fff",
		boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4)",
		backgroundColor: "#ffa21a",
		borderRadius: "3px",
	},
	alertMessage: {
		padding: "20px 15px",
		fontWeight: 300,
		display: "flex"
	},
	alertInnerText: {
		marginLeft: "15px"
	},
	titleBlockText: {
		'& h5': {
			margin: '5px 0',
			fontSize: '16px'
		}
	},
	longLabelText: {
		marginTop: '-16px',
		color: 'red'
	},
	centeredMessage: {
		textAlign: "center",
		margin: "20px"
	}
}

export default styles;
