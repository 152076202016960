import React from "react";
// UI
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import StepperButtons from "./StepperButtons";
// steps
import VisitTime from "./ConfirmSteps/VisitTime";
import PlanOfCare from "./ConfirmSteps/PlanOfCare";
import PatientSign from "./ConfirmSteps/PatientSign";
import CaregiverSign from "./ConfirmSteps/CaregiverSign";
import ConfirmSend from "./ConfirmSteps/ConfirmSend";
// redux
import { connect } from "react-redux";
// styles
import styles from "./styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

function TimesheetStepper(props) {
  const { mLoc, t, pn, pid, activeStep } = props;
  const classesStepped = useStyles();

  const steps = [
    t.tsSteps1,
    t.tsSteps2,
    pn[pid] + t.tsSteps3,
    t.tsSteps4,
    t.tsSteps5,
  ];

  React.useEffect(() => {
    //console.log('changed step scrolling to active element');
    let scroll_el = document.querySelector(".scroll_el" + activeStep);
    scroll_el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    //window.scrollTo(0, 0)
  }, [activeStep]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <VisitTime mLoc={mLoc} t={t} />;
      case 1:
        return <PlanOfCare steps={steps} t={t} />;
      case 2:
        return <PatientSign t={t} />;
      case 3:
        return <CaregiverSign t={t} />;
      case 4:
        return <ConfirmSend t={t} />;
      default:
        return "Unknown step";
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          classes={{ root: classesStepped.stepperRoot }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classesStepped.icon,
                    active: classesStepped.activeIcon,
                    completed: classesStepped.completedIcon,
                  },
                }}
              >
                {label}
              </StepLabel>
              <StepContent className={"scroll_el" + activeStep}>
                {getStepContent(index)}
                {activeStep !== steps.length - 1 && (
                  <StepperButtons steps={steps} {...props} />
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    activeStep: state.timesheet.activeStep,
    pid: state.timesheet.ts.pid,
  };
};

export default connect(mapStateToProps)(TimesheetStepper);
