import { CHECK_IP_FAIL } from "../modules/appModule";
import { checkLocation, checkToken } from "../api";
import { store } from "index";

export async function isTokenValid() {
  const token = localStorage.getItem("token");
  const payload = await checkToken(token);

  if (payload.ok) {
    return [true, payload];
  } else {
    //localStorage.clear();
    localStorage.removeItem("token");
  }
  return [false, null];
}
//deprecated IP check
export function isLocationAllowed() {
  return fetch("https://api.ipify.org/?format=json")
    .then((res) => res.json())
    .then(async (data) => {
      const payload = await checkLocation(data.ip);

      if (payload.ok) {
        return [true, data.ip];
      } else {
        payload.json().then((err) => {
          console.error("err.error", err.error);
        });
        return [false, null];
      }
    })
    .catch((err) => {
      console.error("Checking IP error", err);
      store.dispatch({ type: CHECK_IP_FAIL });
      return [true, ""];
    });
}
//only backend IP check
export async function isLocationAllowed2() {
  return checkLocation("0.0.0.0")
    .then((payload) => payload.json())
    .then((data) => {
      return [true, data.ip];
    })
    .catch((err) => {
      console.error("Checking IP error", err);
      store.dispatch({ type: CHECK_IP_FAIL });
      return [true, ""];
    });
  /*  try {
          console.log("checkLocation")
          checkLocation("0.0.0.0").then
		const payload = await checkLocation("0.0.0.0");
        console.log("checkLocation payload",payload)
		if (payload.ok) {
            payload.json().then((data) => {
                console.log("checkLocation payload json",data)
                return [true, data.ip];
              });
            
        } else {
            payload.json().then(err => {
                console.error('err.error', err.error);
            });
            return [false, null];
        }
	  } catch (error) {
		console.log("error", error);
        return [false, null];
	  }*/
}
