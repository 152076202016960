import { LOGOUT } from "./auth";
import { getNotifications, sendNotificationReadStatus } from "../api";
/**
 * Constants
 */
export const NOTIFICATIONS_LOADED = "appnotifications/NOTIFICATIONS_LOADED";
export const NOTIFICATIONS_ERROR = "appnotifications/NOTIFICATIONS_ERROR";
export const NOTIFICATIONS_SET_READ = "appnotifications/NOTIFICATIONS_SET_READ";
export const NATIVE_NOTIFICATION_RECEIVED = "appnotifications/NATIVE_NOTIFICATION_RECEIVED";
export const NATIVE_NOTIFICATION_REGISTERED = "appnotifications/NATIVE_NOTIFICATION_REGISTERED";

export const NOTIFICATION_STATUS_SENT = "sent";
export const NOTIFICATION_STATUS_READ = "read";

/**
 * Action Creators
 */

/**
 * Reducer
 */

const initState = {
  isNotificationsLoaded: false,
  appnotifications: [],
  isNativeNotificationsRegistered: false,
  nativenotifications: [],
};

export default function appnotifications(state = initState, action) {
  switch (action.type) {
    case NATIVE_NOTIFICATION_RECEIVED: {
      return {
        ...state,
        nativenotifications: [
          state.nativenotifications,
          {
            id: action.notification.id,
            title: action.notification.title,
            body: action.notification.body,
            type: "foreground",
          },
        ] ,
        appnotifications: [state.appnotifications,
          {
            status: NOTIFICATION_STATUS_READ,
            id: action.notification.id,
            title: action.notification.title,
            message: action.notification.body,
            post_time: Date.now(),
            pinned: true,
          },
        ]
      };
    }
    case NATIVE_NOTIFICATION_REGISTERED: {
      return {
        ...state,
        isNativeNotificationsRegistered: true,
      };
    }
    case NOTIFICATIONS_LOADED: {
      return {
        ...state,
        appnotifications: action.appnotifications,
        isNotificationsLoaded: true,
      };
    }
    case NOTIFICATIONS_SET_READ: {
      return {
        ...state,
        appnotifications: state.appnotifications.map((notification, i) =>
          notification.id === action.id
            ? { ...notification, status: NOTIFICATION_STATUS_READ }
            : notification
        ),
      };
    }
    case LOGOUT:
    case NOTIFICATIONS_ERROR: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export function getNotificationsChunk() {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth.token;
      const payload = await getNotifications(token);
      if (payload.ok) {
        payload.json().then((data) => {
          dispatch({
            type: NOTIFICATIONS_LOADED,
            appnotifications: data.app_notifications,
          });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function setNotificationRead(id) {
  return async (dispatch, getState) => {
    /* dispatch({
      type: NOTIFICATIONS_SET_READ,
      id: id,
    });*/

    try {
      const token = getState().auth.token;
      const data = {
        message_id: id,
      };
      const payload = await sendNotificationReadStatus(token, data);
      if (payload.ok) {
        payload.json().then((data) => {
          dispatch({
            type: NOTIFICATIONS_SET_READ,
            id: id,
          });
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function addNewNativeNotification(notification) {
  return async (dispatch, getState) => {
     dispatch({
      type: NATIVE_NOTIFICATION_RECEIVED,
      notification: notification,
    });


  };
}

export function regNativeNotification() {
  return async (dispatch, getState) => {
     dispatch({
      type: NATIVE_NOTIFICATION_REGISTERED,
    });
  };
}