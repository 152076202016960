import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//icons
import BuildRounded from "@material-ui/icons/BuildRounded";
import DoneAllRounded from "@material-ui/icons/DoneAllRounded";
import Collapse from "@material-ui/core/Collapse";
// custom components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import FaqBlock from "../../components/FaqBlock";
import FaqButton from "../../components/FaqButton";
// utils
import { getMomentLocale } from "../../utils/languageUtils";
// moment
import moment from "moment";
import "moment/locale/es";
import "moment/locale/ru";
//styles
import taskStyles from "../../assets/jss/material-dashboard-react/components/tasksStyle.js";
import common from "../../assets/styles/common";
//languages
import timesheetEng from "../../assets/languages/eng/timesheetEng";
import timesheetEsp from "../../assets/languages/esp/timesheetEsp";
import timesheetRus from "../../assets/languages/rus/timesheetRus";
// redux
import { connect } from "react-redux";
import { getTimesheetChunk, tabChangeHandler } from "../../modules/timesheet";
import TimesheetMainList from "./TimesheetMainList";
import EditedMainList from "./EditedMainList";
import TimesheetStepper from "./TimesheetStepper";
import EditedStepper from "./EditedStepper";
import Grid from "@material-ui/core/Grid";
import styles from "./styles";
import ImportantMessage from "../../components/ImportantMessage/ImportantMessage";
import { Typography } from "@material-ui/core";
import PinFormDialog from "../../components/PinFormDialog/PinFormDialog";

const useTaskStyles = makeStyles(taskStyles);

const Timesheet = (props) => {
  const {
    language,
    visits,
    pn,
    confirmEd,
    isTsSelected,
    isEdSelected,
    tabChange,
    onTimesheetRet,
    isTimesheetLoaded,
  } = props;

  if (!isTimesheetLoaded){
    onTimesheetRet(props);
  }

  const faqScroll = React.createRef();

  const t = [null, timesheetEng, timesheetEsp, timesheetRus][language];
  const momentLocale = getMomentLocale(language);
  moment.locale(momentLocale);

  const taskClasses = useTaskStyles();

  // FAQ
  const [checked, setChecked] = React.useState(false);

  function handleFaqButtonClick() {
    setChecked((prev) => !prev);
    faqScroll.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  // tabs switch
  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, value) => {
    setValue(value);
  };
  const visitsExist = Object.entries(visits).length !== 0;

  const EmptyListView = ({ message }) => {
    return (
      <h4>
        <b>{message}</b>
      </h4>
    );
  };

  const Confirm = () => {
    return !isTsSelected ? (
      visitsExist ? (
        <div>
          <Typography color="error" style={styles.emergencyOption}>
            {t.emergencyOption}
          </Typography>
          <TimesheetMainList t={t} visits={visits} pn={pn} {...props} />
        </div>
      ) : (
        <EmptyListView message={t.noTS} />
      )
    ) : (
      <TimesheetStepper
        mLoc={momentLocale}
        taskClasses={taskClasses}
        t={t}
        {...props}
      />
    );
  };

  const Edit = () => {
    return !isEdSelected ? (
      visitsExist ? (
        <div>
          <Typography color="error" style={styles.emergencyOption}>
            {t.emergencyOption}
          </Typography>
          <EditedMainList
            t={t}
            visits={visits}
            pn={pn}
            handleVisit={confirmEd}
          />
        </div>
      ) : (
        <EmptyListView message={t.noEdit} />
      )
    ) : (
      <EditedStepper mLoc={momentLocale} t={t} {...props} />
    );
  };
  const tabs = [
    {
      tabName: t.tab1,
      tabIcon: DoneAllRounded,
      tabContent: <Confirm />,
      tabClick: () => tabChange(),
    },
    {
      tabName: t.tab2,
      tabIcon: BuildRounded,
      tabContent: <Edit />,
      tabClick: () => tabChange(),
    },
  ];
  return (
    <GridContainer>
      <PinFormDialog />
      <GridItem xs={12} sm={12} md={6} lg={6}>
        <ImportantMessage form_name="TS" />
        <CustomTabs
          value={value}
          handleTabChange={handleTabChange}
          headerColor="primary"
          tabs={tabs}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={6}>
        <Card profile>
          <CardHeader color="warning">
            <h4 style={common.cardTitleWhite}>{t.p911header1}</h4>
          </CardHeader>
          <CardBody profile>
            <Grid container direction={`row`} style={styles.newRow}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container direction={`column`} alignContent="stretch">
                  {t.p911content1}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container direction={`column`} alignContent="stretch">
                  {t.p911content2}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container direction={`column`} alignContent="stretch">
                  {t.p911content3}
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={6}>
        <Card profile>
          <CardHeader color="info">
            <h4 style={common.cardTitleWhite} ref={faqScroll}>
              {t.faqHeader1}
            </h4>
            <p style={common.cardCategoryWhite}>{t.faqHeader2}</p>
          </CardHeader>
          <CardBody profile>
            <FaqBlock question={t.faqQuestion1} answer={t.faqAnswer1} />
            <FaqBlock question={t.faqQuestion2} answer={t.faqAnswer2} />
            <div hidden={checked}>
              <FaqButton title={t.more} onClickHandler={handleFaqButtonClick} />
            </div>
            <Collapse in={checked}>
              <FaqBlock question={t.faqQuestion3} answer={t.faqAnswer3} />
              <FaqButton title={t.less} onClickHandler={handleFaqButtonClick} />
            </Collapse>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.user.language,
    visits: state.timesheet.visits,
    pocs: state.timesheet.pocs,
    pn: state.timesheet.pn,
    isTsSelected: state.timesheet.isTsSelected,
    isEdSelected: state.timesheet.isEdSelected,
    isTimesheetLoaded: state.timesheet.isTimesheetLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tabChange: () => dispatch(tabChangeHandler()),
    onTimesheetRet: (props) => dispatch(getTimesheetChunk(props)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timesheet);
