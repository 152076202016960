import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const NoPet = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 511.99499 511.99499`} {...props}>
				<path d="m490.800781 511.996094-490.800781-490.800782 21.195312-21.195312 490.800782 490.800781zm0 0"/>
				<path d="m386.214844 93.253906-41.8125-83.632812-57.273438 143.171875c-6.863281 17.167969-23.257812 28.261719-41.742187 28.261719h-.746094l140.730469 140.734374c3.914062-21.609374 10.675781-42.585937 20.503906-62.253906 5.707031-11.429687 17.195312-18.527344 29.976562-18.527344 41.316407 0 75.933594-33.621093 75.933594-74.941406v-41.683594zm0 0"/>
				<path d="m82.820312 181.054688-82.613281-92.71875 23.65625 153.035156c4.496094 28.042968 1.273438 56.820312-9.277343 83.179687-8.871094 22.1875-14.378907 45.578125-14.378907 69.507813v86.75h54.226563l48.523437-95.046875c7.671875-15.324219 23.082031-24.855469 40.207031-24.855469h75.789063c17.125 0 32.535156 9.53125 40.203125 24.855469l47.523438 95.046875h51.929687l8.378906-50.242188-249.511719-249.511718zm0 0"/>
			</SvgIcon>
</div>
	);
};

export default NoPet;
