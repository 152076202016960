import React from "react";
import { connect } from "react-redux";
//import Button from '@material-ui/core/Button';
import Button from "../../components/CustomButtons/Button";
import Fab from "@material-ui/core/Fab";
import Dialog from "../../components/DialogMigrate/DialogMigrate";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import pinEng from "../../assets/languages/eng/pinEng";
import pinEsp from "../../assets/languages/esp/pinEsp";
import pinRus from "../../assets/languages/rus/pinRus";
import styles from "./styles";
import { makeStyles } from "@material-ui/core/styles";
//import ImportantIcon from '@material-ui/icons/ErrorOutlineOutlined';
//import { orange } from '@material-ui/core/colors';
//import ReactCodeInput from "react-code-input/dist/ReactCodeInput";
import { ADD_PIN_MODE_SET } from "../../modules/auth";
import { addPinDigit } from "../../modules/auth";
//import { Storage } from '@capacitor/storage';
import { Capacitor } from "@capacitor/core";
import BackspaceIcon from '@material-ui/icons/Backspace';

const useStyles = makeStyles(styles);

const PinFormDialog = (props) => {
  const classes = useStyles();
  //console.log('tada')
  const {
    language,
    //        is_open,
    //        pin,
    updatePin,
  } = props;

  let t = [null, pinEng, pinEsp, pinRus][language];

  //const [isOpen, setOpen] = React.useState(is_open);
  const [isOpen, setOpen] = React.useState(props.pin.length !== 4);
  const [pin1, setPin1] = React.useState("");
  const [pin2, setPin2] = React.useState("");
  const [pinStep, setPinStep] = React.useState(1);

  const [cbuttonlabel, setcbuttonlabel] = React.useState(t.please0digits);

  function addToPIN(c) {
    if (pinStep === 1) {
      if (pin1.length < 4) {
        setPin1(pin1 + c);
      }
    }
    if (pinStep === 2) {
      if (pin2.length < 4) {
        setPin2(pin2 + c);
      }
    }
  }
  function clearPIN() {
    if (pinStep === 1) {
      setPin1("");
    }
    if (pinStep === 2) {
      setPin2("");
    }
  }
  function slicePIN() {
    if (pinStep === 1) {
      setPin1((pin1) => pin1.slice(0, pin1.length - 1));
    }
    if (pinStep === 2) {
      setPin2((pin2) => pin2.slice(0, pin2.length - 1));
    }
  }

  const handleClose = () => {
    if (pinStep === 1) {
      if (pin1.length === 4) {
        setPinStep(2);
      }
    }
    if (pinStep === 2) {
      if (pin1 === pin2 && pin2.length === 4) {
        updatePin(pin1, ADD_PIN_MODE_SET);
        setOpen(false);
        setPinStep(1);
      } else if (pin1 !== pin2 && pin2.length === 4){
        setPin1("");
        setPin2("");
        setPinStep(3);
      }
    }
  };
  React.useEffect(() => {
    let current_pin = pin1;
    if (pinStep === 2) {current_pin = pin2}
    if (pinStep===1 || pinStep===2){
      switch (current_pin.length) {
        case 0:
          setcbuttonlabel(t.please0digits);
          break;
        case 1:
          setcbuttonlabel(t.please1digits);
          break;
        case 2:
          setcbuttonlabel(t.please2digits);
          break;
        case 3:
          setcbuttonlabel(t.please3digits);
          break;
        case 4:
          setcbuttonlabel(t.please4digits);
          break;
        default:
          setcbuttonlabel(t.please0digits);
      }
    } else if (pinStep===3){
      setcbuttonlabel(t.ooPS);
    }

  }, [cbuttonlabel, pin1, pin2, pinStep,
     setcbuttonlabel,t.please0digits,t.please1digits,
     t.please2digits,t.please3digits,t.please4digits,t.ooPS]);

  React.useEffect(() => {
    if (pinStep===3){
      setTimeout(() => {
        // do something 1 sec after clicked has changed
        setPinStep(1);
     }, 1000);
    }
  }, [pinStep]);

  if (!Capacitor.isNativePlatform()) {
    return "";
  }

  return (
    <div className={classes.container}>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ margin: "0 auto", padding: 0 }}
        >
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h4 style={{ margin: "auto", padding: "15px" }}>
              <strong>{pinStep === 1 ? t.title : ''}{pinStep === 2 ? t.title2 : ''}{pinStep === 3 ? t.title3 : ''}</strong>
            </h4>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.allcenter}>
              <div style={{ margin: "auto", fontSize: "20px" }}>
                <strong>&nbsp;{pinStep === 1 ? pin1 : ''}{pinStep === 2 ? pin2 : ''}{pinStep === 3 ? t.ooPS : ''}&nbsp;</strong>
              </div>
              <div>
                <div>
                  <Fab className={classes.fab} onClick={() => addToPIN("1")}>
                    <p className={classes.allblack}>1</p>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => addToPIN("2")}>
                    <p className={classes.allblack}>2</p>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => addToPIN("3")}>
                    <p className={classes.allblack}>3</p>
                  </Fab>
                </div>
                <div>
                  <Fab className={classes.fab} onClick={() => addToPIN("4")}>
                    <p className={classes.allblack}>4</p>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => addToPIN("5")}>
                    <p className={classes.allblack}>5</p>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => addToPIN("6")}>
                    <p className={classes.allblack}>6</p>
                  </Fab>
                </div>
                <div>
                  <Fab className={classes.fab} onClick={() => addToPIN("7")}>
                    <p className={classes.allblack}>7</p>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => addToPIN("8")}>
                    <p className={classes.allblack}>8</p>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => addToPIN("9")}>
                    <p className={classes.allblack}>9</p>
                  </Fab>
                </div>
                <div>
                  <Fab className={classes.fab} onClick={() => slicePIN()}>
                    <BackspaceIcon style={{ color: 'black' }}/>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => addToPIN("0")}>
                    <p className={classes.allblack}>0</p>
                  </Fab>
                  <Fab className={classes.fab} onClick={() => clearPIN()}>
                    <p className={classes.allblack}>C</p>
                  </Fab>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ margin: "auto" }}>
            <Button onClick={handleClose} color="success" type={`button`} style={{ minWidth: "255px" }}>
              {cbuttonlabel}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { language } = state.user;
  const { pin } = state.auth;
  return { language, pin };
};

const mapDispatchToProps = (dispatch) => {
  return { updatePin: (pin, pin_mode) => dispatch(addPinDigit(pin, pin_mode)) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PinFormDialog);
