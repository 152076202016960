/*eslint-disable*/
import React from "react";
//import classNames from "classnames";
//import PropTypes from "prop-types";
//import { NavLink } from "react-router-dom";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
//import Icon from "@material-ui/core/Icon";
// core components
//import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { connect } from "react-redux";
import routesEng from "../../assets/languages/eng/routesEng";
import routesEsp from "../../assets/languages/esp/routesEsp";
import routesRus from "../../assets/languages/rus/routesRus";
import dashboardRoutes from "../../routes";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Capacitor } from "@capacitor/core";

//const useStyles = makeStyles(styles);

const Bottombar = (props) => {
  const { language, is_cdpap } = props;

  let t = [routesEng, routesEsp, routesRus];
  let routes = dashboardRoutes.map((prop) => ({
    ...prop,
    name: t[language - 1][prop.name],
  }));
  routes = routes.filter(function(obj) {
    return (
      obj.path === "/availability" ||
      obj.path === "/timesheet" ||
      obj.path === "/poc" ||
      obj.path === "/schedule"
    );
  });
  if (is_cdpap) {
    routes = routes.filter(function(obj) {
      return obj.path !== "/availability";
    });
  }
  //console.log("routes", routes);

  //const pathname = window.location.pathname; // in case user visits the path directly. The BottomNavBar is able to follow suit.
  const [value, setValue] = React.useState(window.location.pathname);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    //console.log("window.location.pathname", window.location.pathname);
    setValue(window.location.pathname);
  }, [window.location.pathname]);

  //const classes = useStyles();

  //function isActiveRoute(route) {
  //  return window.location.href.indexOf(route.layout + route.path) > -1;
  //}
  //const value = routes.findIndex(isActiveRoute);
  //console.log("routes value", value);
  let bottonNavStyle = { margin: "2px 2px 2px 2px", height: "70px" };
  let bottonActionNavStyle = { padding: "6px 6px 6px 6px" };
  if (Capacitor.getPlatform() == "ios") {
    bottonActionNavStyle = {
      padding: "16px 6px 6px 6px",
      marginBottom: "env(safe-area-inset-bottom)",
    };
    bottonNavStyle = {
      //margin: "2px 2px 2px 2px",
      height: "100px",
    };
  }

  return (
    <Paper
      style={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 5 }}
      elevation={3}
    >
      <BottomNavigation
        value={value}
        onChange={handleChange}
        style={bottonNavStyle}
        elevation={3}
        showLabels
        className="nav primary"
      >
        {routes.map((prop, key) => {
          return (
            <BottomNavigationAction
              label={prop.name}
              style={bottonActionNavStyle}
              icon={<prop.icon />}
              key={"BottomNavigationAction" + prop.layout + prop.path}
              component={Link}
              to={prop.layout + prop.path}
              value={prop.layout + prop.path}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};
/**
Bottombar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};*/

const mapStateToProps = (state) => {
  const { language } = state.user;
  const { is_cdpap } = state.availability;
  return { language, is_cdpap };
};
export default connect(mapStateToProps)(Bottombar);
