import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const ManhattanSvg = (props) => {
	return (
<div style={{paddingRight: "10px"}}>
			<SvgIcon fontSize={`large`} viewBox={`0 0 19.939 19.939`} {...props}>
				<path d="M12.037,17.436v-1.014h-0.419V6.247h-0.354V5.401h-0.502V4.553h-0.24c-0.071-0.17-0.245-0.974-0.27-1.459
		c-0.025-0.513-0.008-0.441-0.001-0.544c0.011-0.032,0.016-0.067,0.016-0.102c0-0.138-0.089-0.255-0.21-0.294V0.238L9.942,0
		L9.818,0.238v1.945c-0.09,0.053-0.151,0.151-0.151,0.266c0,0.023,0.003,0.047,0.009,0.07h-0.01c0,0,0.031-0.039,0,0.574
		c-0.022,0.486-0.197,1.29-0.268,1.46H9.156v0.848H8.622v0.846H8.295v10.176H7.837v1.625H7.362v1.891h5.215v-2.504L12.037,17.436
		L12.037,17.436z"/>
			</SvgIcon>
</div>
	);
};

export default ManhattanSvg;
