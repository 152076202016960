const timesheetRus = {
	errMessage: "Таймшит не был отправлен. Пожалуйста повторите попытку позже.",
	errMessage1: "Форма не была отправлена. Пожалуйста повторите попытку позже.",
	successMessage: "Форма была отправлена.",
	errSignature: "Подпись обязательна",
	btnSend: "Отправить",
	btnNext: "Дальше",
	btnBack: "Назад",
	btnFinish: "Завершить",
	tab1: "Заполнить",
	tab2: "Редактировать",
	noTS: "Таймшит не нужен.",
	noEdit: "Нет визитов доступных для редактирования.",
	tsHeader1: "Заполнение таймшита",
	tsHeader2: "Выполните шаги ниже",
	editHeader1: "Редактор визита",
	editHeader2: "Пожалуйста выбирите один из варианов",
	editSend: "Все шаги пройдены, форма готова к отправке",
	signature: "Подпись",
	_from: "С",
	to: "До",
	more: "Ещё",
	less: "Свернуть",
	faqHeader1: "Вопрос / Ответ",
	faqHeader2: "Таймшит",
	faqQuestion1: "Почему мне необходимо заполнить таймшит?",
	faqAnswer1: "В случае, если один из звонков отсутствует (IN или OUT) в системе, визит должен быть подтверждён документально.",
	faqQuestion2: "Когда мне необходимо отправлять таймшиты и какой последний день для подачи?",
	faqAnswer2: "Пожалуйста, отпраляйте таймшиты как только для этого будет время. Крайний день подачи вторник.",
	faqQuestion3: "Пациент отказывается подписывать таймшит, что делать в этом случае?",
	faqAnswer3: "Пожалуйста, свяжитесь со своим координатаром.",
	preSend: "Все шаги пройдены - таймшит готов к отправке.",
	taskError: "Время начала или окончания визита н должно отличаться от графика более чем на 15 минут. Если Вы работали в это время, то, пожалуйста обратитесь к координатору или заполните редакционную форму.",
	editSteps1: "Причина",
	editSteps2: "Пояснение",
	mainEditReasons1: "Сервис не проводился.",
	mainEditReasons2: "Нет возможности получить подпись пациента.",
	mainEditReasons3: "Сервис проводился в другое время.",
	exp1: "Больничный",
	exp2: "Отказ от предоставления сервиса",
	exp3: "Пациент был госпитализирован",
	exp4: "Отпуск",
	exp5: "По личным причинам",
	exp6: "Пациент(ка) отказывается подписать таймшит",
	exp7: "Я больше там не работаю",
	exp9: "По просьбе пациента",
	exp10: "По запросу агенства",
	message: "Сообщение",
	patient: "Пациент",
	date: "Дата визита",
	pocInfo: " заданий выбрано",
	tsSteps0: "Подпись",
	tsSteps1: "Время Визита",
	tsSteps2: "План по уходу",
	tsSteps3: " подпись",
	tsSteps4: "Ваша подпись",
	tsSteps5: "Подтверждение",
	patSign1: "Своей подписью Вы подтверждаете, что ",
	patSign2: " часов ",
	patSign3: " и ",
	patSign4: " минут ",
	patSign5: " сервиса получено ",
	patSign6: " заданий согласно плану по уходу выполнено",
	carSign1: " сервиса было предоставленно и ",
	durInfo1: "Продолжительность визита ",
	durInfo2: " часов и ",
	durInfo3: " минут",
	durInfo4: " часов",
	pocError1: "Количество отмеченных заданий должно быть не менее 7, но лишь ", //num of tasks
	pocError2: " выбрано",
	pocError3: "Как минимум одно задание из каждой категории долно быть отмечено, но лишь ",
	messEdit1: ". Время, в которое проводился визит ",
	messEdit2: ". По причине ",
	rejectedReason: "Не принят по причине: ",
	p911header1: "Важное уведомление",
	p911header2: "Связь",
	p911content1: "Немедленно позвоните в службу 911, если у пациента имеются проблемы с дыханием, присутствует боль в груди, путается речь, пациент неспособен бодрствовать, имеются посинения губ или лица!",
	p911content2: "Звоните в агентство, если у пациента есть изменения в состоянии здоровья и / или поведении!",
	p911content3: "Звоните 911 в случае падения пациента и следуйте протоколу Агентства после падения!",
	emergencyOption: "Таймшит - это экстренная опция для документирования выполненных обязанностей и может использоваться только в чрезвычайных ситуациях, например, при неисправности телефона. Использование таймшита является противозаконным, если система электронного подтверждения посещений (время прихода / ухода в телефоне) работает нормально."
};

export default timesheetRus;
