const pocEng = {
	title: "Plan of Care",
	p911header1: "Important Notice",
	p911header2: "Notice",
	p911content1: "Call 911 immediately if the patient has trouble breathing, chest pain, new confusion, inability to stay awake, bluish lips, or face!",
	p911content2: "Call Agency if the patient has any changes in health and/or behavioral status!",
	p911content3: "Call 911 in case of a patient's fall and follow Agency's Post-fall Protocol!",
	p911Confirmed: "Confirmed"
}
export default pocEng;
