import { LOGOUT } from "./auth";
import { getAvailabilityWizard, sendAvailabilityWizard } from "../api";
/**
 * Constants
 */
export const AVAILABILITY_WIZARD_LOADED = "wizard/AVAILABILITY_WIZARD_LOADED";
export const AVAILABILITY_WIZARD_COUNT = "wizard/AVAILABILITY_WIZARD_COUNT";

/**
 * Action Creators
 */

/**
 * Reducer
 */

const initState = {
  wizard: {
    steps: [],
    isAvailabilityWizardLoaded: false,
    show_count: 0,
  },
};

export default function wizard(state = initState, action) {
  //console.log(' wizard action');
  //console.log(action);
  if (action === undefined) {
    return state;
  }
  switch (action.type) {
    case AVAILABILITY_WIZARD_LOADED: {
      //console.log('TADA2!!!');
      //console.log(action.steps);
      return {
        ...state,
        wizard: {
          steps: action.steps,
          show_count: action.show_count,
          isAvailabilityWizardLoaded: true,
        },
      };
    }
    case AVAILABILITY_WIZARD_COUNT: {
      //console.log(' show_count TADA3!!!');
      //console.log(action.show_count);
      return {
        ...state,
        wizard: {
          steps: action.steps,
          show_count: action.show_count,
          isAvailabilityWizardLoaded: true,
        },
      };
    }
    case LOGOUT: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export function handleWizardSubmit(props) {
  return async (dispatch, getState) => {
    const currState = getState();
    const data = {
      c_code: currState.user.username,
    };
    try {
      dispatch({
        type: AVAILABILITY_WIZARD_COUNT,
        steps: currState.wizard.steps,
        show_count: 0,
      });
    } catch (error) {
      console.log("error", error);
    }
    //console.log('step2');
    try {
      const token = getState().auth.token;
      const response = await sendAvailabilityWizard(token, data);
      if (response.ok) {
        response.json().then((rdata) => {
          /** Add complite step */
          //console.log('step3');
          dispatch({
            type: AVAILABILITY_WIZARD_COUNT,
            steps: rdata.steps,
            show_count: rdata.show_count,
          });
          //console.log('step4');
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function handleWizardSkip(props) {
  return async (dispatch, getState) => {
    const currState = getState();
    try {
      dispatch({
        type: AVAILABILITY_WIZARD_COUNT,
        steps: currState.wizard.steps,
        show_count: 0,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getWizardChunk() {
	return async (dispatch, getState) => {
	  try {
		const token = getState().auth.token;
		const payload = await getAvailabilityWizard(token);
		if (payload.ok) {
		  payload.json().then((data) => {
			dispatch({
                type: AVAILABILITY_WIZARD_LOADED,
                steps: data.steps,
                show_count: data.show_count,
              });
		  });
		}
	  } catch (error) {
		console.log("error", error);
	  }
	};
  }
