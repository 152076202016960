import {
  cardTitle,
  //grayColor
} from "../../assets/jss/material-dashboard-pro-react";


const styles = {
	cardImg: {
		width: `100%`,
		marginTop: `-30px`,
		borderRadius: `6px`,
	},
	cardHover: {
    "&:hover": {
      "& $cardHeaderHover": {
        transform: "translate3d(0, -50px, 0)"
      }
    }
  },
  cardHeaderHover: {
    transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)"
  },
	cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardProductDescription: {
		'& iframe': {
    	width: "100%"
		}
  },
};

export default styles
