import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// redux
import { closeMainLogo } from "../modules/appModule";
import { Paper } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles({
  paper: {
    maxWidth: "500px",
    minHeight: "100vh",
    backgroundColor: "white",
    backgroundImage: `url(${process.env.PUBLIC_URL +
      "/transparent_logo_square.png"})`,
    backgroundRepeat: "no-repeat",
    //backgroundSize: "cover",
    backgroundSize: '90%',
    backgroundPosition: "center",
    //backgroundOrigin: "padding-box",
    //padding: '10px 10px 10% 10%',
    margin: 'auto',
  },
  whitepaper: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "white",
    
  },
  card: {
    backgroundColor: "blue",
  },
});

const MainLogoLayout = (props) => {
  const { isMainLogoShown, closeLogo } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(true);
  }, [setChecked]);

  React.useEffect(() => {
    if (!isMainLogoShown) {
      setTimeout(() => {
        // do something 1 sec after clicked has changed
        closeLogo();
      }, 2500);
    }
  }, [isMainLogoShown]);

  return (
    <div className={classes.whitepaper}>
      <Grow in={checked} timeout={1000}>
        <div className={classes.paper}></div>
      </Grow>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isMainLogoShown: state.app.isMainLogoShown,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeLogo: () => dispatch(closeMainLogo()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLogoLayout);
