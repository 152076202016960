import React from "react";
// flags
import Eng from "assets/img/flags/Eng.png";
import Esp from "assets/img/flags/esp.png";
import Rus from "assets/img/flags/rus.png";
// UI
import LangButton from "./LangButton";
import LoginLoading from "../LoginLoading";
// redux
import { connect } from "react-redux";
import { selectLanguageAction } from "../../modules/user";

const flags = [Eng, Esp, Rus];

const LoginLangSelect = (props) => {
  const { isLoading, setLang } = props;

  const handleClick = (e, l) => {
    e.preventDefault();
    setLang(l);
  };

  return isLoading ? (
    <LoginLoading />
  ) : (
    <>
      {flags.map((lang, key) => (
        <LangButton onClick={(e) => handleClick(e, key + 1)} key={key}>
          <img src={lang} alt={"lang"} key={key} />
        </LangButton>
      ))}
    </>
  );
};

const mapStoreToProps = (state) => {
  return {
    isLoading: state.auth.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (language) => dispatch(selectLanguageAction(language)),
  };
};

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(LoginLangSelect);
