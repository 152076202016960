import React from 'react';
import {connect} from "react-redux";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
// core components
import AvailFormControlLabel from "../../components/AvailFormControlLabel/AvailFormControlLabel";
import NoPet from "../../components/Svg/NoPet/NoPet";
import Male from "../../components/Svg/Gender/Male";
import Female from "../../components/Svg/Gender/Female";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Danger from "../../components/Typography/Danger";
// icons
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SmokeFreeIcon from '@material-ui/icons/SmokeFree';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
// style
import styles from "./styles"
// reducer functions
import {handleAvailSwitcher, handleNextStep, setComment} from "../../modules/availability";
// language
import availEng  from "../../assets/languages/eng/availEng";
import availEsp from "../../assets/languages/esp/availEsp";
import availRus from "../../assets/languages/rus/availRus";
import {grayColor, primaryColor, roseColor} from "../../assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles)

const GeneralAvail = (props) => {

	const {avail, perm, temp, is_cdpap, pets_allergy, smoke_allergy, 
		male, female, nextStep, setSwitch, errGender, errCaseType, comment, setMessageComment} = props
	let t = [null, availEng, availEsp, availRus][props.language];

	//const [message, setMessage] = React.useState('')

	function handleNext() {
		//props.setMessageComment(message)
		nextStep(props, false)
	}

	const classes = useStyles();

	return (
			<>
				{!props.as_dialog && is_cdpap &&
				<div className={classes.alertContainer}>
					<div className={classes.alertMessage}>
						<WarningIcon/>
						<span className={classes.alertInnerText}>
							{t.warning_1}
								</span>
					</div>
				</div>}
				{!props.as_dialog && <h5>{t.title_2_1}</h5>}
				{!props.as_dialog && avail &&
					<div className={classes.titleBlockText}>
						<h5>{t.title_2_2}</h5>
						<h5>{t.title_2_3}</h5>
						<h5>{t.title_2_4}</h5>
						<h5>{t.title_2_5}</h5>
						<h5>{t.title_2_6}</h5>
					</div>
				}
				<FormControl component="fieldset" fullWidth={true} className={classes.formControlClass}>
				{!props.as_dialog && <FormLabel component="legend" className={classes.formControlClass}>{t.question_1}</FormLabel>}
					<div id='tour_wizard_step_1'>
					<FormGroup className={classes.formGroupClass}>
						<Grid spacing={1} container>
							<Grid item xs={12} sm={12} md={12} onClick={() => setSwitch(`avail`, props)}>
								<AvailFormControlLabel
										control={
											<ThumbUpIcon
													htmlColor={avail ? primaryColor[0] : grayColor[3]}
													className={classes.svgIcon}
													fontSize={`large`}
											/>}
										isChecked={avail}
										controlLabel={t.answer_1_1}
								/>
							</Grid>
						</Grid>
					</FormGroup>
					</div>
					{avail &&
					<>
						<Divider/>
						<Grid spacing={1} container>
							<Grid item xs={12} sm={12} md={6}>
								<FormLabel component="legend" className={classes.formControlClass}>{t.question_2}</FormLabel>
								<div id='tour_wizard_step_2'>
								<FormGroup className={classes.formGroupClass}>
								{(!props.as_dialog||perm) &&
									<Grid item xs={12} sm={12} md={6} onClick={() => setSwitch(`perm`, props)}>
										<AvailFormControlLabel
												control={
													<DoneAllIcon
															htmlColor={perm ? primaryColor[0] : grayColor[3]}
															className={classes.svgIcon}
															fontSize={`large`}
													/>}
												isChecked={perm}
												controlLabel={t.answer_2_1}
										/>
									</Grid>}
									{(!props.as_dialog||temp) &&
									<Grid item xs={12} sm={12} md={6} onClick={() => setSwitch(`temp`, props)}>
										<AvailFormControlLabel
												control={
													<DoneIcon
															htmlColor={temp ? primaryColor[0] : grayColor[3]}
															className={classes.svgIcon}
															fontSize={`large`}
													/>}
												isChecked={temp}
												controlLabel={t.answer_2_2}
										/>
									</Grid>}
								</FormGroup>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<FormLabel component="legend" className={classes.formControlClass}>{t.question_3}</FormLabel>
								<div id='tour_wizard_step_3'>
								<FormGroup className={classes.formGroupClass}>
								{(!props.as_dialog||female) &&
									<Grid item xs={12} sm={12} md={12} onClick={() => setSwitch(`female`, props)}>
										<AvailFormControlLabel
												control={
													<Female
															htmlColor={female ? primaryColor[0] : grayColor[3]}
															className={classes.svgIcon}
															fontSize={`large`}
													/>}
												isChecked={female}
												controlLabel={t.answer_3_1}
										/>
									</Grid>}
									{(!props.as_dialog||male) &&
									<Grid item xs={12} sm={12} md={12} onClick={() => setSwitch(`male`, props)}>
										<AvailFormControlLabel
												control={
													<Male
															htmlColor={male ? primaryColor[0] : grayColor[3]}
															className={classes.svgIcon}
															fontSize={`large`}
													/>}
												isChecked={male}
												controlLabel={t.answer_3_2}
										/>
									</Grid>}
								</FormGroup>
								</div>
							</Grid>
						</Grid>
						<Divider/>

						<Grid spacing={1} container>
							<Grid item xs={12} sm={12} md={6}>
								<FormLabel component="legend" className={classes.formControlClass}>{t.question_4}</FormLabel>
								<div id='tour_wizard_step_4'>
								<FormGroup className={classes.formGroupClass}>
									<Grid spacing={1} container>
									{(!props.as_dialog||pets_allergy) &&
										<Grid item xs={12} sm={12} md={12}
													onClick={() => setSwitch(`pets_allergy`, props)}
										>
											<AvailFormControlLabel
													control={
														<NoPet
																htmlColor={pets_allergy ? roseColor[2] : grayColor[3]}
																className={classes.svgIcon}
														/>}
													isChecked={pets_allergy}
													controlLabel={t.answer_4_1}
											/>
										</Grid>}
										{(!props.as_dialog||smoke_allergy) &&
										<Grid item xs={12} sm={12} md={12}
													onClick={() => setSwitch(`smoke_allergy`, props)}
										>
											<AvailFormControlLabel
													control={
														<SmokeFreeIcon
																htmlColor={smoke_allergy ? roseColor[2] : grayColor[3]}
																className={classes.svgIconSmall}
																fontSize={`large`}
														/>}
													isChecked={smoke_allergy}
													controlLabel={t.answer_4_2}
											/>
										</Grid>}
									</Grid>
								</FormGroup>
								</div>
							</Grid>
							{(!props.as_dialog||comment) &&
							<Grid item xs={12} sm={12} md={6}>
								<FormLabel component="legend" className={classes.formControlClass}>{t.addMessage}</FormLabel>
								<div id='tour_wizard_step_5'>
								<FormGroup className={classes.formGroupClass}>
									<CustomInput
											labelText={t.mesPlaceholder}
											id="add-msg"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												multiline: true,
												rows: 2,
												onChange: (event) => setMessageComment(event.target.value),
												value: comment,
											}}
											labelProps={{
												classes: {
													focused: classes.longLabelText,
													root: classes.longLabelText,
												}
											}}
									/>
								</FormGroup>
								</div>
							</Grid>}
						</Grid>
						<Divider/>
					</>
					}

				</FormControl>
				<div className={classes.errMessageFragment}>
					{!avail && <Danger>{t.error_1}</Danger>}
					{errCaseType && <Danger>{t.error_2}</Danger>}
					{errGender && <Danger>{t.error_3}</Danger>}
				</div>
				{!props.as_dialog &&
				<div className={classes.btnGroup} id='general_next_button_div'>
					<Button
							color="success"
							type={`button`}
							onClick={handleNext}
							disabled={errGender || errCaseType}
					>
						{avail ? t.btn_3 : t.btn_4}
					</Button>
				</div>}
			</>
	);
};

const mapStateToProps = (state) => {
	const {language} = state.user;
	const {step, avail, is_cdpap, perm, temp, pets_allergy, smoke_allergy, male, female, errGender, errCaseType, comment} = state.availability;
	return {
		step,
		avail,
		is_cdpap,
		perm,
		temp,
		pets_allergy,
		smoke_allergy,
		male,
		female,
		errGender,
		errCaseType,
		language,
		comment
		
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setMessageComment: (message) => dispatch(setComment(message)),
		setSwitch: (name, props) => dispatch(handleAvailSwitcher(name, props)),
		nextStep: (props, sendToBackend) => dispatch(handleNextStep(props, sendToBackend)),
		//validateAvail: () => dispatch(validateAvailability())
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralAvail);
