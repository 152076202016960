const pinRus = {
	title: "Пожалуйста установите PIN",
	title2: "Пожалуйста подтвердите PIN",
	title3: "Попробуйте еще раз",
    please0digits:"Введите 4 цифры",
    please1digits:"Введите ещё 3",
    please2digits:"Введите ещё 2",
    please3digits:"Введите ещё 1",
    please4digits:"Сохранить PIN",
    ooPS:"Несовпадение",
}
export default pinRus;