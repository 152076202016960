import React from 'react';
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
// core components
import AvailFormControlLabel from "../../components/AvailFormControlLabel/AvailFormControlLabel";
import Danger from "../../components/Typography/Danger";
import Button from "../../components/CustomButtons/Button";
// districts
import BrooklynSvg from "../../components/Svg/Districts/Brooklyn";
import ManhattanSvg from "../../components/Svg/Districts/Manhattan";
import BronxSvg from "../../components/Svg/Districts/Bronx";
import QueensSvg from "../../components/Svg/Districts/Queens";
import StatenSvg from "../../components/Svg/Districts/Staten";
// language
import availEng  from "../../assets/languages/eng/availEng";
import availEsp from "../../assets/languages/esp/availEsp";
import availRus from "../../assets/languages/rus/availRus";
// style
import styles from "./styles"
// reducer functions
import {connect} from "react-redux";
import {handleAvailSwitcher, handleNextStep} from "../../modules/availability";
//import {grayColor, primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {getIconPrimaryDisabledColor} from "../../utils/styleUtils";

const useStyles = makeStyles(styles)

const AvailLocation = (props) => {

	const {
		bk,
		mh,
		bx,
		qn,
		si,
		setSwitch,
		nextStep,
		errDistrict,
		language
	} = props

	const t = [null, availEng, availEsp, availRus][language];

	const classes = useStyles();

	const districts = [
		[bk, "bk", t.answer_5_1, <BrooklynSvg htmlColor={getIconPrimaryDisabledColor(bk)}/>],
		[mh, "mh", t.answer_5_2, <ManhattanSvg htmlColor={getIconPrimaryDisabledColor(mh)}/>],
		[bx, "bx", t.answer_5_3, <BronxSvg htmlColor={getIconPrimaryDisabledColor(bx)}/>],
		[qn, "qn", t.answer_5_4, <QueensSvg htmlColor={getIconPrimaryDisabledColor(qn)}/>],
		[si, "si", t.answer_5_5, <StatenSvg htmlColor={getIconPrimaryDisabledColor(si)}/>],
	]

	return (
			<>
				<FormControl component="fieldset" fullWidth={true} className={classes.formControlClass}>
				{!props.as_dialog &&
					<FormLabel component="legend" className={classes.formControlClass}>{t.question_5}</FormLabel>
				}
					<div id='tour_wizard_step_6'>
					{
						districts.map((dist, index) => {
							return (
									<div key={index}>
										{(!props.as_dialog||dist[0]) &&
										<FormGroup className={classes.formGroupClass}>
											<Grid item xs={12} sm={12} md={12}
														id={dist[1]}
														onClick={() => setSwitch(dist[1], props)}
											>
												<AvailFormControlLabel
														control={dist[3]}
														isChecked={dist[0]}
														controlName={dist[2]}
														controlLabel={dist[2]}
												/>
											</Grid>
										</FormGroup>}
										{(!props.as_dialog||dist[0]) &&
										<Divider/>}
									</div>
							)
						})
					}
					</div>

				</FormControl>

				<div className={classes.errMessageFragment}>
				{ errDistrict && <Danger>{t.error_4}</Danger> }
				</div>
				{!props.as_dialog &&
				<div className={classes.btnGroup}  id='location_next_button_div'>
					<Button
							color="success"
							type={`button`}
							onClick={() => nextStep(props, false)}
							disabled={errDistrict}
					>
						{t.btn_3}
					</Button>
				</div>
				}
			</>
	);
};

const mapStateToProps = (state) => {
	const {language, username} = state.user;
	const {avail, step, bk, mh, bx, qn, si, maxDist, errDistrict} = state.availability;
	return {avail, step, bk, mh, bx, qn, si, maxDist, errDistrict, language, username}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSwitch: (name, props) => dispatch(handleAvailSwitcher(name, props)),
		nextStep: (props, sendToBackend) => dispatch(handleNextStep(props, sendToBackend))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailLocation);
