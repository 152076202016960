import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { Paper } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import common from "../../assets/styles/common";
// language
import patientInfoEng from "../../assets/languages/eng/patientInfoEng";
import patientInfoEsp from "../../assets/languages/esp/patientInfoEsp";
import patientInfoRus from "../../assets/languages/rus/patientInfoRus";
//import styles from "../Timesheet/styles";
//import ImportantMessage2 from "../../components/ImportantMessage/ImportantMessage";
import { orange } from "@material-ui/core/colors";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PinFormDialog from "../../components/PinFormDialog/PinFormDialog";
import POCTable from "../../views/POC/POCTable";
import { onGetPatientInfo } from "../../modules/patientinfo";

const PatientInfo = (props) => {
  const {
    patient_info,
    language,
    //	pin
    onGetPatientInfoDispatch,
    isPatientInfoLoaded,
  } = props;

  if (!isPatientInfoLoaded) {
    onGetPatientInfoDispatch(props);
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
    },
    headingNotice: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: orange[500],
      padding: "15px",
      color: "white",
    },
    nopadding: {
      padding: 0,
    },
    dropdownIcon: {
      transition: theme.transitions.create(["transform"], {
        duration: theme.transitions.standard,
      }),
    },
    dropdownIconOpen: {
      transform: "rotate(-180deg)",
    },
    dropdownIconClosed: {
      transform: "rotate(0)",
    },
    panelSummaryCol: {
      flexDirection: "column",
      padding: 0,
    },
  }));

  const classes = useStyles();
  let t = [null, patientInfoEng, patientInfoEsp, patientInfoRus][language];

  function POCDuties(p) {
    const { duties, mode } = p;
    let duties_arr = [];
    let duties_title = "";
    let duties_tasks = {};
    if (mode === "as_needed") {
      let large = [];
      duties_title = "Upon Patient's request";
      duties.forEach((x) => (x.asneeded ? duties_arr : large).push(x));
      duties.forEach((key) =>
        key.asneeded ? (duties_tasks[key.code] = key.taskname) : ""
      );
    }
    if (mode === "once") {
      let large = [];
      duties_title = "Once a week";
      duties.forEach((x) =>
        (!x.asneeded && x.timesperweekmax === 1 ? duties_arr : large).push(x)
      );
      duties.forEach((key) =>
        !key.asneeded && key.timesperweekmax === 1
          ? (duties_tasks[key.code] = key.taskname)
          : ""
      );
    }
    if (mode === "week") {
      let large = [];
      duties_title = "Daily Duties";
      duties.forEach((x) =>
        (!x.asneeded && x.timesperweekmax !== 1 ? duties_arr : large).push(x)
      );
      duties.forEach((key) =>
        !key.asneeded && key.timesperweekmax !== 1
          ? (duties_tasks[key.code] = key.taskname)
          : ""
      );
    }
    let codes = duties_arr.map((e) => e.code).join(", ");
    //duties_tasks = duties_arr.map(e => {e.code=e.taskname})

    if (codes === "") {
      return <Grid item xs={12} sm={12} md={12} lg={12}></Grid>;
    } else {
      return (
        <Accordion>
          <AccordionSummary
            className={classes.panelSummaryCol}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid container direction={`column`} alignContent="stretch">
              <Typography className={classes.heading}>
                {duties_title}
              </Typography>
              <Typography>{codes}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.nopadding}>
            <POCTable poc={duties_tasks} />
          </AccordionDetails>
        </Accordion>
      );
    }
  }

  function POCCompliance(p) {
    const { duties, mode } = p;
    if (mode === "header") {
      //duties_title = "Notice"
      if (
        Object.keys(duties.required).length > 0 ||
        Object.keys(duties.requiredOnce).length > 0 ||
        Object.keys(duties.notInPoc).length > 0
      ) {
        return (
          <Grid container direction={`column`} alignContent="stretch">
            <Typography className={classes.headingNotice}>
              {"Notice"}
            </Typography>
          </Grid>
        );
      } else {
        return <Grid item xs={12} sm={12} md={12} lg={12}></Grid>;
      }
    }
    //console.log(duties)
    let duties_tasks = {};
    let duties_title = "";
    if (mode === "required") {
      duties_title = "Enter into the system";
      duties_tasks = { ...duties.required, ...duties.requiredOnce };
    }
    if (mode === "notInPoc") {
      duties_title = "Not part of care plan";
      duties_tasks = duties.notInPoc;
    }
    let codes = Object.keys(duties_tasks).join(", ");
    if (codes === "") {
      return <Grid item xs={12} sm={12} md={12} lg={12}></Grid>;
    } else {
      return (
        <Accordion>
          <AccordionSummary
            className={classes.panelSummaryCol}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid container direction={`column`} alignContent="stretch">
              <Typography className={classes.heading}>
                {duties_title}
              </Typography>
              <Typography>{codes}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.nopadding}>
            <POCTable poc={duties_tasks} />
          </AccordionDetails>
        </Accordion>
      );
    }
  }

  function POCCards(p) {
    const { pocs } = p;

    return (
      <div>
        {pocs.map((p) => (
          <Card profile key={p.patient_fullname}>
            <CardHeader color="info">
              <Paper
                style={
                  p.temporary_visit
                    ? common.cardTemporaryVisit
                    : common.cardTitleWhite
                }
              >
                <p
                  style={
                    p.temporary_visit
                      ? common.cardTemporaryVisitBold
                      : common.cardTitleWhiteBold
                  }
                >
                  {p.temporary_visit ? t.temp_visit : t.visit}
                </p>
                <h4
                  style={
                    p.temporary_visit
                      ? common.cardTemporaryVisitBold
                      : common.cardTitleWhiteBold
                  }
                >
                  {t.schedule_and_time}
                </h4>
                {p.schedule_time.map((value) => {
                  return (
                    <h4
                      style={
                        p.temporary_visit
                          ? common.cardTemporaryVisit
                          : common.cardTitleWhite
                      }
                    >
                      {value}
                    </h4>
                  );
                })}
              </Paper>

              <h4 style={common.cardTitleWhite}>{p.patient_fullname}</h4>

              <a target="_blank" href={"tel:" + p.homephone}>
                <h4 style={common.cardTitleWhite}>
                  {t.phone_number} : {p.homephone}
                </h4>
              </a>
              <a
                target="_blank"
                href={
                  "https://www.google.com/maps/search/" +
                  p.address.split(" ").join("+") +
                  "/"
                }
              >
                <h4 style={common.cardTitleWhite}>{p.address}</h4>
              </a>
            </CardHeader>
            <CardBody profile>
              <POCDuties duties={p.tasks} mode={"week"} />
              <POCDuties duties={p.tasks} mode={"once"} />
              <POCDuties duties={p.tasks} mode={"as_needed"} />
            </CardBody>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <div>
      <PinFormDialog />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <POCCards pocs={patient_info} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { patient_info, isPatientInfoLoaded } = state.patient_info;
  const { language } = state.user;
  return { patient_info, language, isPatientInfoLoaded };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPatientInfoDispatch: (props) => dispatch(onGetPatientInfo(props)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInfo);
