import React from 'react';
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import GridContainer from "../../../components/Grid/GridContainer";
import {onSendTimesheet} from "../../../modules/timesheet";
import {connect} from "react-redux";

function ConfirmSend(props) {
	const {
		t,
		sendTimesheet
	} = props
	return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<h4><b>{t.preSend}</b></h4>
					<Button
							onClick={() => sendTimesheet(t.successMessage, t.errMessage)}
							color={`success`}>
						{t.btnSend}
					</Button>
				</GridItem>
			</GridContainer>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		sendTimesheet: (sucMes, errMes) => dispatch(onSendTimesheet(sucMes, errMes)),
	}
};
export default connect(null, mapDispatchToProps)(ConfirmSend);
