const routesRus = {
	campaign: "Уведомления",
	timesheet: "Таймшит",
	info: "Информация о пациенте",
	schedule: "График",
	vacation: "Отпуск",
	changeAddress: "Смена Адреса",
	availability: "Доступность",
	nevvon: "Тренинг",
	poc: "POC",
};


export default routesRus;
