import React from 'react';
import {CSSTransition} from "react-transition-group";
import 'animate.css/animate.css'

function LogoAnimated(props) {
	return (

			<svg xmlns="http://www.w3.org/2000/svg"
					 width={"100%"}
					 height={"100%"}
					 viewBox={"0 0 1669 754"}
					 style={{maxWidth: "500px"}}
			>
				<CSSTransition
						in={true}
						timeout={3000}
						classNames={{
							appear: 'animate__animated',
							appearActive: 'animate__animated animate__fadeIn animate__slower',
						}}
						appear
				>
					<g
							transform="translate(0.000000,754.000000) scale(0.100000,-0.100000)"
							fill="#ff0000" stroke="none"
					>
						<path style={{margin: '50px'}} d="M1920 7533 c-79 -9 -271 -57 -360 -90 -203 -75 -425 -204 -620 -361
-132 -106 -363 -339 -443 -447 -259 -350 -432 -845 -487 -1394 -15 -154 -12
-538 5 -707 71 -671 325 -1298 739 -1823 162 -206 437 -482 901 -906 564 -514
1175 -949 1868 -1329 296 -162 947 -481 968 -474 5 2 -47 32 -114 68 -679 361
-1449 877 -2017 1351 -1048 874 -1699 1768 -1920 2639 -78 307 -99 551 -82
967 18 425 46 623 122 855 68 206 183 421 335 628 100 135 341 374 465 462
186 131 414 232 634 280 86 19 132 22 311 22 180 1 226 -3 324 -22 540 -109
1106 -468 1624 -1030 77 -83 142 -150 145 -147 8 7 -151 221 -269 363 -512
613 -1109 1002 -1669 1087 -88 14 -368 18 -460 8z"/>

						<path d="M5810 6634 c-239 -27 -335 -46 -503 -100 -125 -40 -334 -137 -460
-213 -405 -244 -807 -692 -1053 -1174 -31 -60 -53 -111 -51 -114 3 -3 23 19
44 49 249 349 498 612 785 829 530 400 1144 554 1733 434 200 -40 310 -86 473
-195 384 -257 645 -761 736 -1425 22 -162 39 -444 34 -572 l-4 -113 152 0 152
0 6 123 c8 137 -7 439 -29 614 -49 373 -162 715 -330 999 -150 253 -390 501
-630 651 -159 100 -308 149 -565 188 -102 15 -419 27 -490 19z"/>
					</g>
				</CSSTransition>

				<CSSTransition
						in={true}
						timeout={6000}
						classNames={{
							appear: 'animate__animated',
							appearActive: 'animate__animated animate__fadeIn animate__delay-2s animate__slower',
						}}
						appear
				>
					<g transform="translate(0.000000,754.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
						<path d="M3595 3568 c-264 -315 -499 -587 -523 -605 -24 -18 -56 -36 -70 -40
-28 -6 -42 -20 -42 -40 0 -10 45 -13 190 -13 l190 0 0 25 c0 21 -5 25 -28 25
-37 0 -72 26 -72 53 1 34 28 76 123 191 l87 106 185 0 c170 0 185 -1 185 -17
-1 -82 -22 -247 -34 -272 -22 -41 -53 -61 -97 -61 -35 0 -49 -12 -49 -41 0 -6
108 -8 273 -7 l272 3 3 23 c3 17 -1 22 -20 22 -35 0 -66 26 -77 66 -8 31 12
688 33 1067 l5 87 -28 0 c-23 0 -88 -73 -506 -572z m235 -35 c-6 -98 -13 -179
-14 -181 -2 -1 -69 -1 -149 0 l-147 3 157 183 c86 100 158 181 160 178 3 -2
-1 -84 -7 -183z"/>
						<path d="M4740 4125 c-36 -7 -97 -18 -136 -25 -64 -10 -72 -14 -77 -36 -4 -21
-1 -24 25 -24 41 0 68 -17 68 -42 0 -12 -61 -229 -135 -482 -74 -254 -139
-483 -145 -510 -14 -64 1 -111 44 -137 42 -26 134 -26 184 0 41 22 122 102
171 171 29 41 30 46 14 57 -24 18 -27 17 -57 -25 -67 -97 -125 -121 -111 -45
6 31 288 1005 315 1086 l9 27 -52 -1 c-29 0 -81 -7 -117 -14z"/>
						<path d="M5190 4113 c-74 -13 -135 -25 -136 -26 -1 -1 -5 -12 -8 -24 -7 -21
-4 -23 27 -23 21 0 41 -7 51 -19 21 -23 28 5 -146 -586 -139 -472 -145 -507
-90 -556 28 -25 40 -29 94 -29 42 0 75 7 105 21 46 22 149 129 180 186 16 30
16 35 3 43 -21 14 -24 12 -78 -55 -54 -68 -92 -84 -92 -39 0 14 72 272 160
571 88 300 160 549 160 554 0 15 -88 8 -230 -18z"/>
						<path d="M6077 4103 c-18 -17 -3 -31 39 -38 65 -9 98 -44 90 -94 -10 -66 -265
-929 -286 -971 -23 -46 -71 -80 -112 -80 -39 0 -58 -10 -58 -31 0 -18 12 -19
299 -19 264 0 300 2 305 16 10 25 -2 34 -45 34 -46 0 -89 35 -88 72 0 13 28
122 63 243 l63 220 216 3 c133 1 217 -1 217 -7 0 -24 -114 -401 -134 -441 -25
-53 -76 -90 -122 -90 -31 0 -64 -21 -64 -41 0 -5 126 -9 310 -9 l310 0 0 25
c0 22 -4 25 -35 25 -42 0 -90 22 -99 45 -3 9 1 44 8 78 31 129 256 889 275
926 29 55 73 89 129 97 43 6 61 17 62 37 0 4 -141 6 -312 5 -273 -3 -313 -5
-316 -18 -2 -12 13 -19 58 -28 100 -21 100 -34 21 -307 l-58 -200 -216 -3
c-150 -2 -217 1 -217 8 0 26 111 378 132 420 25 49 89 90 139 90 22 0 39 15
39 36 0 7 -606 4 -613 -3z"/>
						<path d="M10181 3900 c-59 -70 -163 -152 -248 -194 -39 -20 -76 -36 -82 -36
-11 0 -19 -16 -35 -67 -7 -22 -5 -23 53 -23 34 0 61 -3 61 -7 0 -5 -34 -123
-75 -263 -43 -143 -78 -284 -82 -322 -6 -67 -6 -67 30 -103 32 -32 39 -35 96
-35 105 0 186 47 258 147 43 61 45 65 27 78 -26 20 -26 19 -53 -20 -13 -20
-40 -49 -59 -66 -31 -28 -36 -29 -50 -15 -15 14 -9 42 68 311 l84 294 60 3 60
3 17 55 17 55 -59 3 c-33 2 -59 4 -59 5 0 1 16 54 35 117 19 63 35 118 35 123
0 4 -13 7 -28 7 -22 0 -39 -12 -71 -50z"/>
						<path d="M7781 3704 c-163 -44 -330 -178 -408 -329 -56 -110 -73 -173 -73
-274 0 -101 18 -150 73 -198 50 -44 103 -56 223 -51 93 3 107 6 179 42 99 49
235 176 215 201 -7 8 -16 15 -21 15 -4 0 -42 -24 -83 -54 -78 -56 -118 -74
-171 -77 -68 -3 -86 2 -123 35 -36 33 -37 37 -40 114 l-4 80 48 7 c169 21 313
83 391 169 62 68 86 116 91 187 8 107 -40 149 -170 148 -40 0 -97 -7 -127 -15z
m81 -78 c8 -18 8 -37 -1 -72 -28 -112 -107 -217 -189 -252 -63 -26 -102 -35
-102 -22 0 19 62 166 93 220 46 81 118 150 157 150 23 0 33 -6 42 -24z"/>
						<path d="M8515 3708 c-113 -40 -235 -167 -311 -323 -68 -141 -87 -215 -82
-325 6 -156 58 -217 176 -208 51 3 68 10 107 40 42 33 112 113 157 178 16 24
16 23 2 -24 -22 -78 -19 -142 11 -171 35 -36 130 -35 203 1 56 28 123 96 160
162 l23 41 -22 11 c-18 10 -23 8 -37 -12 -29 -42 -80 -88 -97 -88 -27 0 -18
43 85 396 51 173 89 318 86 321 -3 3 -54 2 -113 -3 l-108 -9 -9 -37 c-11 -44
-21 -48 -30 -12 -4 14 -19 37 -33 50 -21 20 -36 24 -82 23 -31 0 -69 -5 -86
-11z m125 -58 c43 -23 52 -132 21 -245 -48 -171 -164 -357 -237 -379 -24 -7
-48 16 -58 56 -16 67 38 267 111 413 46 92 69 123 107 146 36 22 33 22 56 9z"/>
						<path d="M9247 3692 c-134 -31 -162 -50 -94 -67 70 -17 70 -20 -22 -391 -45
-181 -84 -337 -87 -347 -5 -16 3 -17 111 -15 l117 3 33 120 c36 129 82 245
146 365 40 75 93 140 113 140 6 -1 23 -19 38 -40 21 -31 34 -40 56 -40 53 0
100 66 109 155 10 99 -18 145 -87 145 -87 0 -140 -57 -251 -272 -38 -73 -69
-128 -69 -123 0 6 23 93 50 195 27 102 50 188 50 193 0 14 -97 5 -213 -21z"/>
						<path d="M12313 1592 c-216 -258 -415 -490 -442 -516 -28 -25 -64 -49 -81 -52
-21 -5 -30 -12 -30 -25 0 -18 10 -19 160 -19 153 0 160 1 160 20 0 15 -7 20
-25 20 -14 0 -34 9 -45 20 -18 18 -19 24 -8 50 7 17 49 76 93 130 l80 100 157
0 157 0 -5 -87 c-10 -158 -38 -213 -107 -213 -26 0 -36 -5 -40 -20 -5 -20 0
-20 228 -20 224 0 233 1 238 20 4 15 0 20 -16 20 -29 1 -54 21 -66 52 -6 17
-3 177 9 474 24 569 25 514 -2 514 -18 0 -112 -106 -415 -468z m184 -42 c-5
-85 -10 -156 -12 -158 -1 -1 -57 -1 -124 0 l-121 3 132 156 c73 86 133 156
134 155 0 0 -4 -71 -9 -156z"/>
						<path d="M4907 2033 c-18 -17 -4 -33 35 -39 45 -8 78 -34 78 -63 0 -25 -227
-806 -244 -839 -22 -44 -52 -64 -101 -69 -35 -5 -45 -10 -45 -24 0 -18 13 -19
254 -19 222 0 255 2 260 16 9 23 8 24 -23 24 -46 0 -80 17 -87 44 -5 21 87
376 106 406 4 7 71 9 187 8 l181 -3 -50 -170 c-72 -250 -86 -272 -173 -284
-27 -4 -41 -12 -43 -23 -3 -17 14 -18 257 -18 248 0 261 1 261 19 0 14 -10 20
-40 24 -53 7 -70 22 -70 61 0 31 206 752 233 818 20 48 69 86 122 94 35 5 45
10 45 25 0 18 -12 19 -264 19 -231 0 -265 -2 -270 -16 -9 -23 -9 -23 32 -30
92 -16 99 -43 51 -205 -16 -57 -39 -134 -50 -171 l-20 -68 -185 0 c-145 0
-185 3 -182 13 3 6 24 77 48 157 67 226 83 253 167 274 30 8 49 18 51 29 3 16
-18 17 -256 17 -142 0 -262 -3 -265 -7z"/>
						<path d="M6290 1677 c-125 -52 -230 -153 -293 -285 -34 -72 -40 -94 -45 -172
-6 -107 11 -163 63 -208 48 -43 95 -55 189 -50 113 6 196 47 292 142 115 115
170 251 162 397 -4 83 -39 140 -107 176 -62 32 -182 32 -261 0z m154 -49 c73
-104 -112 -618 -222 -618 -44 0 -72 36 -72 92 0 143 105 442 183 519 36 37 88
40 111 7z"/>
						<path d="M6920 1679 c-94 -18 -119 -29 -120 -51 0 -5 13 -8 29 -8 39 0 55 -22
48 -65 -3 -20 -40 -152 -81 -294 -42 -143 -76 -264 -76 -270 0 -8 31 -11 102
-9 l102 3 32 110 c35 125 54 166 130 282 86 133 164 207 164 157 0 -9 -34
-133 -75 -275 -41 -142 -75 -263 -75 -269 0 -7 33 -10 102 -8 l102 3 33 109
c42 137 67 193 134 292 85 126 159 197 159 151 0 -6 -27 -107 -60 -222 -33
-115 -60 -229 -60 -252 0 -83 91 -118 186 -73 46 23 123 99 148 147 14 28 14
32 0 44 -13 10 -22 4 -61 -40 -28 -32 -49 -49 -55 -43 -6 6 14 89 52 218 34
114 65 233 67 264 5 50 3 59 -22 88 -24 28 -33 32 -78 32 -34 0 -63 -7 -85
-20 -46 -28 -114 -101 -169 -179 -26 -36 -50 -70 -54 -75 -4 -4 1 25 12 65 40
151 17 209 -81 209 -90 0 -154 -49 -265 -205 -32 -44 -55 -71 -52 -60 4 11 22
73 42 137 19 64 35 119 35 122 0 12 -107 4 -210 -15z"/>
						<path d="M8354 1680 c-153 -49 -291 -182 -355 -342 -26 -66 -37 -200 -19 -252
31 -95 100 -131 243 -124 119 5 182 34 281 129 67 64 70 68 52 82 -17 13 -25
9 -82 -34 -89 -67 -171 -86 -232 -55 -44 23 -68 83 -57 144 l6 38 82 12 c197
29 349 153 364 296 6 53 4 57 -30 91 -34 34 -37 35 -113 34 -49 0 -102 -8
-140 -19z m94 -88 c7 -92 -79 -216 -174 -252 -30 -11 -59 -20 -64 -20 -5 0 0
25 10 55 49 144 143 269 197 263 25 -3 28 -7 31 -46z"/>
						<path d="M9050 1691 c-116 -36 -240 -139 -303 -251 -51 -93 -70 -167 -65 -259
4 -93 28 -144 87 -186 41 -29 48 -30 144 -30 93 0 108 3 167 31 68 32 180 126
180 150 0 29 -29 25 -74 -10 -45 -34 -127 -66 -170 -66 -32 0 -84 36 -106 72
-44 73 -13 263 61 380 58 91 146 148 205 134 32 -8 30 -32 -6 -66 -37 -35 -42
-98 -10 -130 31 -31 100 -27 134 6 67 68 49 176 -36 220 -29 15 -164 18 -208
5z"/>
						<path d="M9681 1678 c-170 -84 -324 -360 -309 -553 10 -115 51 -164 138 -165
76 0 124 33 208 145 25 33 42 51 37 40 -4 -11 -10 -48 -13 -81 -6 -83 13 -104
96 -104 81 0 146 39 207 125 26 35 44 69 40 75 -7 12 -45 14 -45 2 0 -16 -68
-82 -85 -82 -23 0 -20 13 74 336 l78 269 -56 3 c-31 2 -75 1 -97 -3 -36 -5
-43 -10 -51 -38 l-10 -32 -11 26 c-6 15 -21 34 -34 43 -33 23 -114 21 -167 -6z
m145 -49 c25 -28 26 -142 1 -219 -45 -137 -154 -300 -200 -300 -9 0 -24 12
-34 26 -15 23 -15 35 -5 106 20 140 126 368 184 396 32 15 33 15 54 -9z"/>
						<path d="M10325 1676 c-75 -16 -101 -26 -103 -39 -3 -12 3 -17 22 -17 27 0 56
-23 56 -45 0 -7 -31 -140 -70 -294 -38 -155 -70 -286 -70 -291 0 -6 42 -10 98
-10 l99 0 13 58 c40 182 182 472 230 472 5 0 18 -14 30 -31 19 -25 28 -30 58
-27 46 4 77 47 87 118 12 89 -14 130 -84 130 -41 0 -49 -4 -91 -49 -27 -29
-71 -97 -106 -165 -32 -63 -60 -113 -62 -112 -1 2 17 76 41 165 l44 161 -46
-1 c-25 0 -91 -11 -146 -23z"/>
						<path d="M11184 1686 c-137 -44 -281 -165 -341 -286 -95 -190 -76 -369 44
-422 67 -30 217 -23 293 13 60 28 131 84 175 137 26 31 27 35 12 49 -15 15
-19 13 -58 -21 -57 -52 -133 -86 -192 -86 -42 0 -54 5 -83 34 -32 32 -34 38
-34 100 0 65 0 66 28 66 53 1 168 30 227 59 70 35 146 107 175 166 29 60 27
136 -4 172 -23 26 -30 28 -113 30 -52 2 -105 -3 -129 -11z m76 -61 c27 -33
-15 -148 -80 -218 -41 -45 -80 -69 -134 -81 -27 -7 -28 -6 -22 21 4 15 26 69
51 118 66 137 147 206 185 160z"/>
						<path d="M13205 1687 c-56 -19 -81 -33 -127 -75 -57 -51 -81 -105 -81 -177 -1
-81 31 -138 97 -172 l29 -15 -54 -29 c-73 -40 -109 -83 -109 -132 0 -43 13
-73 44 -98 17 -14 18 -18 6 -22 -78 -24 -121 -46 -152 -77 -31 -31 -38 -45
-38 -76 0 -121 125 -179 369 -172 120 3 143 7 191 29 101 47 153 118 153 209
0 64 -19 106 -65 145 -44 37 -78 51 -205 81 -151 37 -171 53 -113 96 27 19 40
21 133 19 120 -3 187 17 254 74 62 53 86 110 81 195 l-4 70 47 0 47 0 11 44
c6 25 11 47 11 50 0 3 -47 6 -105 6 -84 0 -111 4 -132 17 -35 23 -228 29 -288
10z m189 -48 c22 -17 26 -28 26 -73 0 -91 -51 -225 -103 -275 -32 -29 -72 -30
-100 -3 -53 54 15 303 96 352 40 25 48 25 81 -1z m-168 -734 c97 -30 134 -58
134 -104 0 -73 -87 -118 -210 -108 -79 7 -117 23 -154 67 -45 54 -27 115 48
162 36 22 60 20 182 -17z"/>
						<path d="M14110 1677 c-289 -102 -474 -465 -334 -654 37 -49 95 -66 206 -61
123 5 181 32 280 127 69 67 72 72 54 86 -17 13 -22 11 -59 -20 -62 -53 -133
-85 -190 -85 -42 0 -54 5 -83 34 -32 32 -34 38 -34 100 0 65 0 66 28 66 50 0
159 28 216 55 180 85 272 300 155 361 -44 22 -162 18 -239 -9z m99 -55 c12
-22 -2 -88 -29 -143 -42 -82 -109 -137 -188 -154 l-32 -7 25 67 c36 96 74 162
122 212 46 47 85 56 102 25z"/>
						<path d="M14715 1695 c-5 -2 -53 -11 -107 -20 -78 -13 -97 -20 -101 -36 -4
-15 0 -19 22 -19 33 0 51 -17 51 -47 0 -13 -33 -139 -74 -281 -41 -141 -78
-269 -81 -284 l-7 -28 104 0 104 0 28 108 c34 124 58 176 131 287 79 119 169
198 189 166 3 -5 -22 -103 -55 -217 -34 -115 -62 -231 -63 -257 -1 -42 3 -52
28 -74 26 -22 37 -25 87 -21 40 3 69 12 98 31 46 31 131 133 131 158 0 25 -32
33 -43 13 -22 -39 -76 -89 -85 -79 -6 6 13 87 54 224 74 248 80 301 38 350
-22 27 -32 31 -75 31 -89 0 -174 -61 -271 -194 -62 -86 -71 -96 -64 -76 3 8
19 65 36 125 17 61 33 118 36 128 5 15 -2 17 -48 16 -29 0 -57 -2 -63 -4z"/>
						<path d="M15657 1676 c-125 -52 -216 -136 -277 -257 -97 -196 -75 -371 55
-434 50 -25 63 -27 143 -23 110 6 178 36 268 121 59 55 64 62 49 76 -11 11
-19 13 -28 6 -61 -46 -118 -76 -162 -86 -47 -10 -57 -9 -91 7 -64 32 -79 62
-79 162 0 106 28 195 89 285 66 99 162 150 211 113 20 -14 19 -16 -17 -53 -29
-30 -38 -47 -38 -74 0 -48 33 -79 86 -79 48 0 76 18 98 64 32 68 11 138 -53
174 -56 32 -174 30 -254 -2z"/>
						<path d="M16128 1667 c-95 -27 -108 -33 -108 -52 0 -18 5 -21 23 -18 32 6 75
-31 86 -75 12 -44 31 -395 31 -568 l0 -124 -42 -30 c-22 -16 -45 -30 -51 -30
-5 0 -29 18 -53 40 -34 31 -52 40 -79 40 -47 0 -81 -24 -95 -66 -25 -76 25
-144 106 -144 128 0 302 173 552 550 174 261 219 378 178 458 -21 40 -44 52
-99 52 -38 0 -49 -5 -71 -31 -42 -50 -36 -92 20 -144 44 -39 47 -45 42 -81 -5
-39 -79 -169 -162 -288 l-41 -59 -12 189 c-14 226 -27 315 -54 372 -25 51 -27
51 -171 9z"/>
					</g>
				</CSSTransition>
			</svg>
	);
}

export default LogoAnimated;
