import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
// redux
import { connect } from "react-redux";
import LoginCard from "../components/LoginCard";
import { uploadData } from "../modules/appModule";
// UI
import PhoneForm from "../components/PhoneForm";
import CodeForm from "../components/CodeForm";
import PinForm from "../components/PinForm";
//icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LocalPhone from "@material-ui/icons/LocalPhone";
// layout
import LogoLayout from "./LogoLayout";

import { setupPhone, STORED_PHONE_KEY, STORED_PIN_KEY } from "../modules/auth";
import { Storage } from "@capacitor/storage";

const Login = (props) => {
  const {
    isPhoneSent,
    isCodeSent,
    isPinSent,
    isAuthenticated,
    uploadDone,
    uploading,
    setupPhoneDispatch,
  } = props;

  useEffect(() => {
    if (isAuthenticated) {
      uploading();
    }
  }, [isAuthenticated, uploadDone, uploading]);

  const phoneLabel = "Please enter your phone number";
  const codeLabel = "Please enter code received";
  const pinLabel = "Please enter pin";
  const [login_by_pin, set_login_by_pin] = React.useState(false);
  const [phone, set_phone] = React.useState("");
  const [pin, set_pin] = React.useState("");
  useEffect(() => {
    async function setupPhoneAndPin() {
      try {
        const _phone = await Storage.get({ key: STORED_PHONE_KEY });
        if (_phone) {
          if (_phone.value) {
            if (_phone.value.length === 10) {
              setupPhoneDispatch(_phone.value);
              set_phone(_phone.value);
            }
          }
        }
        const _pin = await Storage.get({ key: STORED_PIN_KEY });
        if (_pin) {
          if (_pin.value) {
            if (_pin.value.length === 4) {
              set_pin(_pin.value);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    setupPhoneAndPin();
  }, [setupPhoneDispatch]);

  useEffect(() => {
    try {
      if (phone && pin) {
        if (phone.length === 10 && pin.length === 4) {
          //we have stored PIN and phone. we need to automate login
          set_login_by_pin(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [phone, pin]);

  return (
    <>
      {login_by_pin && !isPinSent ? (
        <LoginCard
          cardTitle={`PIN`}
          IconLogin={LockOutlinedIcon}
          cardLabel={pinLabel}
        >
          <PinForm />
        </LoginCard>
      ) : !isPhoneSent && !login_by_pin ? (
        <LoginCard
          cardTitle={`Phone`}
          IconLogin={LocalPhone}
          cardLabel={phoneLabel}
        >
          <PhoneForm />
        </LoginCard>
      ) : !isCodeSent && !login_by_pin ? (
        <LoginCard
          cardTitle={`Code`}
          IconLogin={LockOutlinedIcon}
          cardLabel={codeLabel}
        >
          <CodeForm />
        </LoginCard>
      ) : !uploadDone ? (
        <LogoLayout />
      ) : (
        <Redirect to={"/main"} />
      )}
    </>
  );
};

const mapStoreToProps = (state) => {
  return {
    isPinSent: state.auth.isPinSent,
    isCodeSent: state.auth.isCodeSent,
    isPhoneSent: state.auth.isPhoneSent,
    isAuthenticated: state.auth.isAuthenticated,
    uploadDone: state.app.uploadDone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploading: () => dispatch(uploadData()),
    setupPhoneDispatch: (phone) => dispatch(setupPhone(phone)),
  };
};

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(Login);
