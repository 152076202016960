import { getSaveQTN } from "../api";

export function onSaveQTN(qtn_answers) {
    return async (dispatch, getState) => {
      //const phone = getState().auth.phone;
  
      const data = {
        qtn_answers: qtn_answers,
      };
      try {
        const token = getState().auth.token;
        const payload = await getSaveQTN(token, data);
        if (payload.ok) {
          payload.json().then((data) => {
            
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    };
  }