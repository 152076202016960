const routesEsp = {
	campaign: "Notificaciones",
	timesheet: "Hoja de Trabajo",
	info: "Información del paciente",
	schedule: "Calendario",
	vacation: "Solicitar Vacaciones",
	changeAddress: "Cambiar Dirección",
	availability: "Disponibilidad",
	nevvon: "Capacitación",
	poc: "POC",
};

export default routesEsp;
